import React, {useState} from "react"
import {Form} from 'semantic-ui-react'
import {toast} from "react-toastify"
import { observer, inject } from 'mobx-react'
import { useSettings } from "../../../../core/hooks"

const BackToQueueBtn = ({ store, actions }) => {
  const [loading, setLoading] = useState(false)
  const [disabledButton, setDisabledButton] = useState(true)
  useSettings(store, disabledButton, setDisabledButton)

  const handleSoftDetachConversation = async(e) => {
    e.preventDefault()
    const conversationUid = store.conversation.conversationUid
    setLoading(true)
    await actions.agent.softDetachConversation(conversationUid)
      .then(([response, error]) => {
        if (error) {
          toast.error('There was an error soft detaching this conversation, please try again.')
          return
        }
        if (response) {
          store.conversation.detachConversation()
          toast.info('Successfully sent conversation back to the queue.')
        }
      })
    setLoading(false)
  }

  const Button = ({ onClick, text }) => (
    <Form.Button
      size="small"
      color="orange"
      style={{ fontWeight: 'normal' }}
      disabled={disabledButton}
      onClick={onClick}
      loading={loading}
    >
      {text}
    </Form.Button>
  )

  return (<Button
    onClick={handleSoftDetachConversation}
    text={'Back To Queue'}
    disabled={disabledButton}
    loading={loading}
  />)
}

export default inject('store', 'actions')(observer(BackToQueueBtn))
