import React from 'react'
import storage from 'core/storage'
import {isEmpty} from 'lodash'
import {Button, Form, Grid, Label } from 'semantic-ui-react'
import { getExternalUserInfo, getAuthToken, fetchWhiteLabels, getUserData, getWhiteLabelUser } from './helpers'
import {FormSelect} from '../elements/forms'
import ModalDetach from './ModalDetach'
import ModalDelete from './ModalDelete'
import ModalReset from './ModalReset'

@connect()
class PlaygroundView extends React.Component {

  state = {
    userUid: '',
    whiteLabelUid: '',
    whiteLabels: [],
    userInfo: '',
    error: null,
    widgetHostname: window.localStorage.getItem('CCP_TEST_HOSTNAME') || 'default',
  }

  async componentDidMount() {
    const whiteLabels = await fetchWhiteLabels()
    this.setState({
      whiteLabels,
    })
    if (typeof CCP_CONF !== 'undefined') {
      this.setState({
        userUid: storage.get('debug:userUid', CCP_CONF.userUid),
        whiteLabelUid: storage.get('debug:whiteLabelUid', CCP_CONF.identifier),
      })
    }
  }

  onSelectChange = ({ name, value }) => {
    storage.set(`debug:${name}`, value)
    this.setState({
      [name]: value
    })
  }

  onWidgetHostnameChange = ({ value }) => {
    this.setState({ widgetHostname: value })
    window.localStorage.setItem('CCP_TEST_HOSTNAME', value)
    window.location.reload()
  }

  onInputChange = ({ target: { name, value }}) => {
    storage.set(`debug:${name}`, value)
    this.setState({
      [name]: value
    })
  }

  handleInfoRequest = async() => {
    const {userUid, whiteLabelUid} = this.state
    const { result } = await getExternalUserInfo(userUid, null, whiteLabelUid || CCP_CONF.identifier, false, false)
    storage.set('debug:userUid', userUid)
    this.setState({
      textAreaContent: JSON.stringify(result, null, 2),
      error: null,
    })
  }

  handleUserDataRequest = async() => {
    const {userUid } = this.state
    const  result = await getUserData(userUid)
    storage.set('debug:userUid', userUid)
    this.setState({
      textAreaContent: JSON.stringify(result, null, 2),
      error: null,
    })
  }

  handleAuthToken = async() => {
    const {userUid} = this.state
    try {
      const { result } = await getAuthToken(userUid)
      this.setState({
        textAreaContent: result.token,
        error: null,
      })
    } catch(err) {
      console.warn(err)
      this.setState({
        error: {
          userUid: 'Error fetching user token',
        }
      })
    }
  }

  handleWhiteLabelUser = async() => {
    const {userUid} = this.state
    try {
      const { result } = await getWhiteLabelUser(userUid)
      this.setState({
        textAreaContent: JSON.stringify(result, null, 2),
        error: null,
      })
    } catch(err) {
      console.warn(err)
      this.setState({
        textAreaContent: JSON.stringify(err, null, 2),
      })
    }
  }

  fetchUserData = async() => {
    const { userUid } = this.state
    if(userUid) {
      const { result: userData } = await getUserData(userUid)
      if(userData) {
        return { receiverUids: userData.repliedconversations }
      }else {
        return { error: 'no replied conversations found'}
      }
    }

  }

  render() {
    const {userUid, whiteLabelUid, whiteLabels, textAreaContent, error} = this.state
    return (
      <Grid columns={2} stackable>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Form>
              {error && error.userUid && (
                <Label basic color='red' pointing='below'>{error.userUid}</Label>
              )}
              <Form.Field>
                <label>userUid</label>
                <input
                  name="userUid"
                  value={userUid}
                  placeholder="Enter user0Uid or user1Uid"
                  onChange={this.onInputChange}
                />
              </Form.Field>
              <Form.Field>
                <FormSelect
                  name="whiteLabelUid"
                  options={whiteLabels.map(w => {
                    return [w.whiteLabelUid, w.displayName || w.siteName]
                  })}
                  value={whiteLabelUid}
                  onChange={this.onSelectChange}
                />
              </Form.Field>
            </Form>
          </Grid.Column>
          <Grid.Column>
            <Button.Group basic vertical style={{ marginTop: '1.8rem' }}>
              <Button
                onClick={this.handleInfoRequest}
                disabled={isEmpty(this.state.userUid)}>
                Request information
              </Button>
              <Button
                onClick={this.handleUserDataRequest}
                disabled={isEmpty(this.state.userUid)}>
                Request user data
              </Button>
              <Button
                onClick={this.handleAuthToken}
                disabled={isEmpty(this.state.userUid)}>
                Get Auth Token
              </Button>
              <Button
                onClick={this.handleWhiteLabelUser}
                disabled={isEmpty(this.state.userUid)}>
                Get whiteLabel
              </Button>
            </Button.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Form>
              <textarea
                value={textAreaContent ? textAreaContent : ''}
                style={{ fontFamily: 'monospace, consolas, Courier New', padding: '0.4rem', height: '20rem' }}
                readOnly
              />
            </Form>
          </Grid.Column>
          <Grid.Column>
            <Button.Group basic vertical>
              <ModalReset
                userUid={this.state.userUid}
                disabled={isEmpty(this.state.userUid)}
                fetchUserData={ () => this.fetchUserData}
              />
              <ModalDelete
                userUid={this.state.userUid}
                disabled={isEmpty(this.state.userUid)}
              />
              <ModalDetach
                userUid={this.state.userUid}
                disabled={isEmpty(this.state.userUid)}
              />
            </Button.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default PlaygroundView


