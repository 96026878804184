import { get } from 'lodash'

export const CONFIG = {
  api: get(window.GLOBALS_INTERFACE, 'api', null),
  wsUrl: get(window.GLOBALS_INTERFACE, 'wsUrl', null),
  identifier: get(window.CCP_CONF, 'identifier', null),
  userUid: get(window.CCP_CONF, 'userUid', null),
  userToken: get(window.CCP_CONF, 'userToken', null),
  safeMode: get(window.CCP_CONF, 'safeMode', null) === "1",
  featureFlags: get(window.GLOBALS_INTERFACE, 'featureFlags', { consultConversations: true, consultStats: true, agentStats: true, extraConversations: true, agentSessions: true, adminSettings: true, featureSwitches: true }),
  idleTimeout: 300000, //ms => 5m
  theme: get(window.CCP_CONF, 'theme', 'theme_1'),
  stylesURL: get(window.CCP_CONF, 'stylesURL', null), //url to custom site stiles
}

