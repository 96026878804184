import React, {useState} from "react"
import {toast} from "react-toastify"
import {Button, Icon} from "semantic-ui-react"
import {inject, observer} from "mobx-react"

const ContactInfoButton = ({store, conversation, actions}) => {
  const [loading, setLoading] = useState(false)
  const [disabledButton, setDisabledButton] = useState(true)

  const handleContactInfoWarning = async() => {
    setLoading(true)
    actions.chat.sendContactInfoNotification(conversation.id)
      .then(([, error]) => {
        setLoading(false)
        setDisabledButton(true)
        if (error) {
          toast.error('Failed to send contact info warning')
        } else {
          toast.success('Contact info sent successfully')
        }
      })
  }


  React.useEffect(async() => {
    const lastSentMessage = await store.conversation.fetchLastMessage()
    if (lastSentMessage?.message === 'contactInfoDetected') {
      setDisabledButton(true)
      return
    }

    if (store.conversation.lastMessageSentAt) {

      setDisabledButton(true)

      // in an edge case lastMessageSentAt gets out of sync with otherUserLastSentAt by 1 second,
      // checking if it is in the range of +- 1 second or equal
      if (store.conversation.otherUserLastSentAt - 1 === store.conversation.lastMessageSentAt) {
        setDisabledButton(false)
        return
      }

      if (store.conversation.otherUserLastSentAt + 1 === store.conversation.lastMessageSentAt) {
        setDisabledButton(false)
        return
      }

      if (store.conversation.otherUserLastSentAt === store.conversation.lastMessageSentAt) {
        setDisabledButton(false)
      }

    }
  }, [store.conversation.lastMessageSentAt])

  return (
    <Button basic color='red' icon labelPosition='left' onClick={handleContactInfoWarning} disabled={disabledButton}
            loading={loading}>
      <Icon name='warning sign'/>Contact Info
    </Button>
  )
}

export default inject('store', 'actions')(observer(ContactInfoButton))
