import React from 'react'

export default function Heart() {
  return (
    <svg id="user_ap_like" xmlns="http://www.w3.org/2000/svg" width="25.123" height="22" viewBox="0 0 25.123 22">
      <path id="Path_3" data-name="Path 3" d="M14,8l2.6-5.1A2.035,2.035,0,0,0,14.8,0h0a5.447,5.447,0,0,0-4.4,2.2L6,8V22H17.7a4.16,4.16,0,0,0,3.5-2l3.4-6a4.015,4.015,0,0,0-3.5-6Z" fill="#358fd3"/>
      <path id="Path_4" data-name="Path 4" d="M4,22H4a4.012,4.012,0,0,1-4-4V12A4.012,4.012,0,0,1,4,8H4Z" fill="#358fd3"/>
    </svg>
  )
}
