import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ccpProvider } from 'core/CcpProvider'
import Button from './Button'
import { Dropdown, Icon, Loader } from 'semantic-ui-react'
import { toast } from 'react-toastify'

const AddContainer = styled.div`
  display: flex;
  min-width: 15em;
  .ui.selection.dropdown {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ui.button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-right: 0;
  }
`

const AddMapping = ({ agentId, mapping = [], mappingAdded = () => {} }) => {
  const [niches, setNiches] = useState([])
  const [filteredNiches, setFilteredNiches] = useState([])
  const [selectedNiche, setSelectedNiche] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    ccpProvider.api.admin.getNiches()
      .then(([result]) => {
        setLoading(false)
        setNiches(result)
      })
  }, [])

  useEffect(() => {
    const nicheIds = mapping.reduce((x, { nicheId }) => [...x, nicheId], [])
    setFilteredNiches(niches
      .filter(x => !nicheIds.includes(x.nicheId))
      .map(n => ({ key: n.nicheId, text: n.name, value: n.nicheId }))
    )
  }, [niches, mapping, loading])

  const add = e => {
    e.preventDefault()
    if(!selectedNiche) {
      return
    }
    setLoading(true)
    ccpProvider.api.admin.assignNiche(agentId, selectedNiche)
      .then(([, error]) => {
        setLoading(false)
        if(error) {
          if(error.code === 'duplicateNicheMapping') {
            toast.error('This niche is already assigned to this agent')
            return
          }
          toast.error('There was an issue assigning this niche to the agent, please try again.')
          return
        }
        const addedNiche = niches.find(x => x.nicheId === selectedNiche)
        mappingAdded(addedNiche)
        toast.success(`${addedNiche.name} niche assigned to agent.`)
      })
  }

  return (
    <AddContainer>
      <Dropdown
        placeholder='Select Niche'
        fluid
        selection
        options={filteredNiches}
        disabled={loading}
        onChange={(_, { value }) => setSelectedNiche(value)}
      >
      </Dropdown>
      <Button color="blue" disabled={!selectedNiche} loading={loading} onClick={add}>Add</Button>
    </AddContainer>
  )
}

const RemoveMapping = ({ niche, agentId, nicheRemoved = () => {} }) => {
  const [loading, setLoading] = useState(false)

  const remove = e => {
    e.preventDefault()
    setLoading(true)
    ccpProvider.api.admin.removeNicheMapping(agentId, niche.nicheId)
      .then(([, error]) => {
        setLoading(false)
        if(error) {
          toast.error('There was an issue deleting the assigned niche, please try again.')
          return
        }
        nicheRemoved(niche.nicheId)
        toast.success(`${niche.name} niche removed from agent.`)

      })
  }

  return (
    <Button icon onClick={remove} loading={loading}><Icon className="red" name="remove"/></Button>
  )
}

const NicheMappingContainer = styled.div`
  position: relative;
  margin: 2em 0;
`
const NicheList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 1em 0;
  max-width: 15em;
  li {
    border: solid lightgrey;
    border-width: 1px 1px 0 1px;
    padding: 0.4em 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &:first-child {
      border-radius: 5px 5px 0 0;
    }
    &:last-child {
      border-radius: 0 0 5px 5px;
      border-width: 1px 1px 1px 1px;
    }
    &:first-child:last-child {
      border-radius: 5px;
    }
  }
`
const Title = () => <h3>Assigned niches</h3>
const NicheMapping = ({ agentId }) => {
  const [loading, setLoading] = useState(true)
  const [mapping, setMapping] = useState([])

  useEffect(() => {
    setLoading(true)
    ccpProvider.api.admin.getAssignedNiches(agentId)
      .then(([result, error]) => {
        setLoading(false)
        if(error && error.statusCode !== 404) {
          toast.error('There was an error fetching the assigned niches, please refresh the page to re-fetch them.')
          return
        }
        setMapping(result || [])
      })
  }, [])

  const mappingAdded = (niche) => {
    setMapping([...mapping, niche])
  }

  const nicheRemoved = (nicheId) => {
    setMapping(mapping.filter(m => m.nicheId !== nicheId))
  }

  if(loading) {
    return (
      <NicheMappingContainer>
        <Title/>
        <Loader active={true}/>
      </NicheMappingContainer>
    )
  }

  return (
    <NicheMappingContainer>
      <Title/>
      <NicheList>
        {!loading && mapping.length === 0 && <p>No niches are assigned to this agent</p>}
        {mapping.map((niche, index) => <li key={index}>{niche.name}<RemoveMapping niche={niche} nicheRemoved={nicheRemoved} agentId={agentId}/></li>) }
      </NicheList>
      <AddMapping mappingAdded={mappingAdded} mapping={mapping} agentId={agentId}/>
    </NicheMappingContainer>
  )
}

export default NicheMapping
