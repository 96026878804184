// Router Dependencies
import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { UT } from 'core/constants'

// Import Container / Components
import App from './pages/Index'
import Playground from './pages/Playground'
import Authorize from './pages/Authorize'
import NotAllowed from './pages/NotAllowed'
import Admin from './pages/Admin'
import Chat from './pages/Chat'
import Break from './components/Agent/Break'
import Settings from './pages/Settings'
import Login from './components/Auth/LoginPage'
import IdleTimerContainer from './components/elements/IdleTimerContainer'
import WidgetThemeGenerator from './components/Playground/WidgetThemeGenerator'

import 'react-toastify/dist/ReactToastify.css'
import { Dimmer, Loader } from 'semantic-ui-react'

const ALLOW_AGENT = UT.AGENT
const ALLOW_ADMIN = UT.ADMIN

const setRootRedirect = (state) => {
  if (state.account.type === UT.ADMIN) {
    return () => <Redirect to="/admin/agents"/>
  }
  return () => <Redirect to="/chat"/>
}

const Authorization = (allowedRoles = [], RequestedComp, store) => {
  if (allowedRoles.includes(store.account.type)) {
    return RequestedComp
  }
  return NotAllowed
}

const routes = (store) => {

  if (process.env.NODE_ENV !== 'production') {
    if (['/debug', '/themeGenerator'].some(p => global.location.href.includes(p))) {
      return global.location.href.includes('/debug') ?
        <Route path='/debug' component={Playground} exact/> :
        <Route path='/themeGenerator' component={WidgetThemeGenerator} exact/>
    }
  }

  if (!store.isLoggedIn) {
    return (
      <Authorize>
        <Switch>
          <Route path='/login' component={Login} exact/>
          <Route render={() => <Redirect to="/login"/>}/>
        </Switch>
      </Authorize>
    )
  }
  if(!store.token) {
    return (
      <Dimmer active inverted>
        <Loader size='mini'>Loading</Loader>
      </Dimmer>
    )
  }

  return (
    <App>
      <Switch>
        <Route path='/' render={setRootRedirect(store)} exact/>
        <Route path='/admin/agents' component={Authorization([ALLOW_ADMIN], Admin, store)}/>
        <Route path='/admin/conversations' component={Authorization([ALLOW_ADMIN], Admin, store)}/>
        <Route path='/admin/conversations/:conversationUid' component={Authorization([ALLOW_ADMIN], Admin, store)}/>
        <Route path='/admin/agentMessages' component={Authorization([ALLOW_ADMIN], Admin, store)}/>
        <Route path='/admin/stats' component={Authorization([ALLOW_ADMIN], Admin, store)}/>
        <Route path='/admin/agentStats' component={Authorization([ALLOW_ADMIN], Admin, store)}/>
        <Route path='/admin/agentSessions' component={Authorization([ALLOW_ADMIN], Admin, store)}/>
        <Route path='/admin/niches' component={Authorization([ALLOW_ADMIN], Admin, store)}/>
        <Route path='/chat/:conversationUid' component={Authorization([ALLOW_AGENT], Chat, store)}/>
        <Route path='/chat' component={Authorization([ALLOW_AGENT], Chat, store)}/>
        <Route path='/settings' component={Settings}/>
      </Switch>
      <Break/>
      <IdleTimerContainer type={store.account.type}/>
      <ToastContainer autoClose={5000} position='bottom-left' hideProgressBar={true}/>
    </App>
  )
}

export default routes
