import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { PENDING_MESSAGE_ID } from 'core/constants'
import { isNotSentId, pictureLink } from 'core/helpers'
import {MessageBasic} from './MessageDefault'

const MessageImage = ({ message, isCorrespondent, users, belongsToOtherUser, conversation }) => {
  const [open, setOpen] = useState(false)

  const props = {
    time: message.sentAt,
    user: isCorrespondent ? users.me : users.other,
    sent: !belongsToOtherUser, 
    pending: message.id === PENDING_MESSAGE_ID,
    failed: isNotSentId(message.id),
    belongsToOtherUser: belongsToOtherUser,
    message: message,
    conversation: conversation
  }

  useEffect(() => {
    if (open) {
      setOpen(false)
    }
  }, [])

  return (
    <MessageBasic {...props} type="image">
      <div className={classnames('chat-image-container')}>
        <div
          className={classnames('chat-image')}
        >
          <svg>
            <image preserveAspectRatio="xMidYMin meet" xlinkHref={`${pictureLink(message.message, conversation.conversationUid, conversation.identifier)}`} width="100%" height="100%"/>
          </svg>
        </div>

      </div>
    </MessageBasic>
  )
}

export default MessageImage
