import React, { useState, useEffect } from 'react'
import * as xlsx from 'xlsx'
import DateTimePicker from 'react-datetime'
import { omit, pick } from 'lodash'
import { Table, Grid, Form, Button, Loader, Modal, Radio, Message } from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
import moment from 'moment'
import Section from '../UI/Section'
import AgentsListDropdown from '../elements/AgentsListDropdown'
import { inject } from 'mobx-react'
import { Time } from '../misc/Times'
import { toQueryString } from 'core/request'
import { InfoBubble } from './misc/InfoBubble'

import './css/datepicker.css'
import './css/agentStats.scss'

const DAY = 24*60*60
const WEEK = 7*24*60*60
const MONTH = 30*24*60*60
const START_OF_TODAY = moment().startOf('day').unix()

const SearchForm = ({ actions, onSubmit = () => {} }) => {
  const units = {
    daily: 'daily',
    weekly: 'weekly',
    monthly: 'monthly',
    custom: 'custom',
  }
  const [formData, setFormData] = useState({
    unit: units.daily,
    day: START_OF_TODAY,
    agentIds: [],
  })
  const [formErrors, setFormErrors] = useState([])

  useEffect(() => {
    setFormData(omit(formData, ['before', 'after']))
  }, [formData.unit])

  useEffect(() => {
    validate()
  }, [])

  const onAgentIdChange = (e, { value }) => {
    setFormData({ ...formData, agentIds: value })
  }

  const onRadioChange = (e, { value }) => {
    setFormData({ ...formData, unit: value })
  }

  const onTimeChange = name => e => {
    setFormData({ ...formData, [name]: Math.round(e._d.valueOf() / 1000) })
  }

  const validate = () => {
    const { unit, before, after, day } = formData
    setFormErrors([])
    const errors = []
    if(unit === units.custom) {
      if(after >= before) {
        errors.push('"Stats from" cannot be higher then "Stats until"')
      }
    }
    if(unit === units.daily && !day) {
      errors.push('please select a valid day')
    }
    setFormErrors(errors)
    if(!errors.length) {
      const times = {
        [units.daily]: { after: formData.day, before: day + DAY },
        [units.weekly]: { after: START_OF_TODAY - WEEK, before: START_OF_TODAY + DAY },
        [units.monthly]: { after: START_OF_TODAY - MONTH, before: START_OF_TODAY + DAY },
      }
      const queryParams = {
        ...pick({ ...formData, ...times?.[formData.unit] }, ['before', 'after', 'unit']),
        ...(!formData.agentIds?.length ? {} : { agentIds: formData.agentIds.join(',') }),
      }
      onSubmit(queryParams)
    }
  }

  const isValidDate = current => !current.isAfter(moment())

  return (
    <div>
      {formErrors.length > 0 && <Message negative list={formErrors}/> }
      <Form onSubmit={validate}>
        <Form.Group widths="equal">
          <AgentsListDropdown name="agentIds" actions={actions} onChange={onAgentIdChange} value={formData.agentIds} multiple={true}/>
          {Object.keys(units).map((unit, key) => (
            <Form.Field key={key}>
              <h3>{unit} stats</h3>
              <div>
                <Radio
                  label={unit}
                  name='statsState'
                  value={unit}
                  checked={formData.unit === unit}
                  onChange={onRadioChange}
                />
              </div>
            </Form.Field>
          ))}
          {formData.unit === units.daily &&
          <Form.Field>
            <label>Select day</label>
            <DateTimePicker timeFormat={false} onChange={onTimeChange('day')} closeOnSelect={true} isValidDate={isValidDate} value={formData.day * 1000}/>
          </Form.Field>
          }
          {formData.unit === units.custom && (
            <>
              <Form.Field>
                <label>Stats from</label>
                <DateTimePicker timeFormat={true} onChange={onTimeChange('after')} closeOnSelect={true} isValidDate={isValidDate} value={formData.after * 1000}/>
              </Form.Field>
              <Form.Field>
                <label>Stats until</label>
                <DateTimePicker timeFormat={true} onChange={onTimeChange('before')}  closeOnSelect={true} isValidDate={isValidDate} value={formData.before * 1000}/>
              </Form.Field>
            </>
          )}
          <Form.Field>
            <Button type='submit' primary className='fluid'>
              Find stats
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    </div>
  )
}

const AgentStats = ({ actions, store }) => {
  const [agentStats, setAgentStats] = useState({})
  const [searchParams, setSearchParams] = useState({})
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [saleModal, setSaleModal] = useState(null)

  const search = async formData => {
    setLoading(true)
    setError(false)
    setSearchParams(formData)
    const [response, err] = await actions.admin.getAgentStats(pick(formData, ['before', 'after', 'agentIds']))
      .finally(() => setLoading(false))
    if(err) {
      setError('Error loading stats, please try again')
      return
    }
    setAgentStats(response)
  }

  const goTo = ({ path, type, data, agentIds }) => {
    const { before, after } = searchParams
    const paths = {
      chats: '/admin/agentMessages',
      default: '/admin/conversations'
    }
    const types = {
      totalConversations: { before, after, agentIds },
      conversions: { before, after, agentIds, upgradedAfterAgentReply: true },
      chats: { before, after, agentIds },
      recurring: { before, after, agentIds, ...data }
    }
    return `${paths[path || 'default']}${toQueryString(types[type])}`
  }

  const getAgent = agentId => store.agents.find(x => `${x.id}` === agentId)

  const openSalePopup = (agentId, saleType, conversations) => {
    setSaleModal({ agent: getAgent(agentId), saleType, before: searchParams.before, after: searchParams.after, conversations })
  }

  const { total = {}, stats = []} = agentStats

  const downloadSpreadSheet = () => {
    let xlStats = []
    for (const [agentId, stat] of Object.entries(stats)) {
        let temp = {
            statsDate: new Date(stat.after).toLocaleString() + ' - ' + new Date(stat.before).toLocaleString(),
            agentName: getAgent(agentId)?.name,
            ...stat
        }

        delete temp.responseTime
        delete temp.responses
        delete temp.charsSent
        delete temp.firstSalesConversations
        delete temp.firstSalesFromSmallestPackageConversations
        delete temp.totalRepurchasesConversations
        delete temp.before
        delete temp.after

        xlStats.push(temp)
    }

    let temp = {
        statsDate: null,
        agentName: 'Totals',
        ...total
    }

    delete temp.responseTime
    delete temp.responses
    delete temp.charsSent
    delete temp.firstSalesConversations
    delete temp.firstSalesFromSmallestPackageConversations
    delete temp.totalRepurchasesConversations
    delete temp.before
    delete temp.after

    xlStats.push(temp)

    const worksheet = xlsx.utils.json_to_sheet(xlStats)
    const workbook = xlsx.utils.book_new()

    xlsx.utils.book_append_sheet(workbook, worksheet, "AgentStats")
    xlsx.utils.sheet_add_aoa(
        worksheet,
        [[
            "Date",
            "Agent Name",
            "Conversations received",
            "Conversations interacted",
            "Messages sent",
            "Messages received",
            "Average response time (in seconds)",
            "Average messages per conversation",
            "Average chars per message",
            "First sales",
            "First sales / smallest package",
            "Smallest package sale / the user upgraded",
            "Smallest package sale / not upgraded",
            "Total repurchases completed",
        ]],
        { origin: "A1" }
    )

    worksheet["!cols"] = [
        { wch: 40 },
        { wch: 10 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 20 },
        { wch: 30 },
        { wch: 30 },
        { wch: 25 },
        { wch: 10 },
        { wch: 25 },
        { wch: 25 },
        { wch: 25 },
        { wch: 25 },
    ]

    xlsx.writeFile(workbook, "agent-stats.xlsx", { compression: true })
  }

  return (
    <div>
      <h1>Agent stats</h1>
      <div className='content' id='stats'>
        <div className='content-body'>
          <Button floated='right' onClick={() => downloadSpreadSheet()}>
          Download stats
          </Button>
          <Section>

            <SearchForm actions={actions} onSubmit={search}/>

            <Loader active={loading}/>

            <Table celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Agent Name</Table.HeaderCell>
                  <Table.HeaderCell>
                    Conversations received
                    <InfoBubble id={'conversations_received'} content={'Number of conversations attached to the agent'}/>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Conversations interacted
                    <InfoBubble id={'conversations_interacted'} content={'Conversations that the agent sent a message'}/>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Messages sent
                    <InfoBubble id={'messages_sent'} content={'Amount of total messages sent during the selected period'}/>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Messages received
                    <InfoBubble id={'messages_received'} content={'Amount of total messages received from the user during the selected period'}/>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Average response time (in seconds)
                    <InfoBubble id={'average_response_time'} content={'Average time to respond to a user'}/>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Average messages per conversation
                    <InfoBubble id={'average_messages_per_conversation'} content={'How many messages on average have in total been exchanged between the user and the agent'}/>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Average chars per message
                    <InfoBubble id={'average_chars_per_message'} content={'How many chars on average a message from an agent has'}/>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    First sales
                    <InfoBubble id={'first_sales'} content={'Number of initial sales during the selected period'}/>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    First sales / smallest package
                    <InfoBubble id={'first_sales_smallest_package'} content={'How many of the initial sales are from the smallest package from every site'}/>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Smallest package sale / the user upgraded
                    <InfoBubble id={'smallest_package_sale_user_upgraded'} content={'The number of users with the smallest package who have upgraded'}/>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Smallest package sale / not upgraded
                    <InfoBubble id={'smallest_package_sale_not_upgraded'} content={'The number of users with the smallest package but not upgraded yet (pending rebill,standard, still credits active)'}/>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Total repurchases completed
                    <InfoBubble id={'total_repurchases_completed'} content={'How many manual repurchases occurred during the selected period'}/>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {Object.entries(stats).map(([agentId, agentStat], key) => (
                    <Table.Row key={key}>
                      <Table.Cell><Time time={agentStat.after} format="MMM Do YY HH:mm"/> - <Time time={agentStat.before} format="MMM Do YY HH:mm"/></Table.Cell>
                      <Table.Cell>{getAgent(agentId)?.name}</Table.Cell>
                      <Table.Cell>{agentStat.conversationsReceived}</Table.Cell>
                      <Table.Cell>{agentStat.conversationsInteracted}</Table.Cell>
                      <Table.Cell>{agentStat.messagesSent}</Table.Cell>
                      <Table.Cell>{agentStat.messagesReceived}</Table.Cell>
                      <Table.Cell>{agentStat.averageResponseTime + 's'}</Table.Cell>
                      <Table.Cell>{agentStat.averageMessagesPerConversation}</Table.Cell>
                      <Table.Cell>{agentStat.averageCharsPerMessage}</Table.Cell>
                      <Table.Cell onClick={() => openSalePopup(agentId, 1, agentStat.firstSalesConversations)}>
                        {agentStat.firstSales > 0 ? <a href="#">{agentStat.firstSales}</a> : agentStat.firstSales}
                      </Table.Cell>
                      <Table.Cell onClick={() => openSalePopup(agentId, 2, agentStat.firstSalesFromSmallestPackageConversations)}>
                        {agentStat.firstSalesFromSmallestPackage > 0 ? <a href="#">{agentStat.firstSalesFromSmallestPackage}</a> : agentStat.firstSalesFromSmallestPackage}
                      </Table.Cell>
                      <Table.Cell onClick={() => openSalePopup(agentId, 3, agentStat.smallestPackageSaleUserUpgradedConversations)}>
                        {agentStat.smallestPackageSaleUserUpgraded > 0 ? <a href="#">{agentStat.smallestPackageSaleUserUpgraded}</a> : agentStat.smallestPackageSaleUserUpgraded}
                      </Table.Cell>
                      <Table.Cell onClick={() => openSalePopup(agentId, 4, agentStat.smallestPackageSaleUserHasNotUpgradedConversations)}>
                        {agentStat.smallestPackageSaleUserHasNotUpgraded > 0 ? <a href="#">{agentStat.smallestPackageSaleUserHasNotUpgraded}</a> : agentStat.smallestPackageSaleUserHasNotUpgraded}
                      </Table.Cell>
                      <Table.Cell onClick={() => openSalePopup(agentId, 5, agentStat.totalRepurchasesConversations)}>
                        {agentStat.totalRepurchases > 0 ? <a href="#">{agentStat.totalRepurchases}</a> : agentStat.totalRepurchases}
                      </Table.Cell>
                    </Table.Row>
                  )
                )}
                <Table.Row key={'totals'}>
                  <Table.Cell/>
                  <Table.Cell>Totals</Table.Cell>
                  <Table.Cell>{total.conversationsReceived}</Table.Cell>
                  <Table.Cell>{total.conversationsInteracted}</Table.Cell>
                  <Table.Cell>{total.messagesSent}</Table.Cell>
                  <Table.Cell>{total.messagesReceived}</Table.Cell>
                  <Table.Cell>{total.averageResponseTime + 's'}</Table.Cell>
                  <Table.Cell>{total.averageMessagesPerConversation}</Table.Cell>
                  <Table.Cell>{total.averageCharsPerMessage}</Table.Cell>
                  <Table.Cell>{total.firstSales}</Table.Cell>
                  <Table.Cell>{total.firstSalesFromSmallestPackage}</Table.Cell>
                  <Table.Cell>{total.smallestPackageSaleUserUpgraded}</Table.Cell>
                  <Table.Cell>{total.smallestPackageSaleUserHasNotUpgraded}</Table.Cell>
                  <Table.Cell>{total.totalRepurchases}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Grid centered={true}>
              <Grid.Column style={{display: 'flex', justifyContent: 'center'}}>
                { error && <h4>{error}</h4>}
              </Grid.Column>
            </Grid>
          </Section>
        </div>
      </div>
      { saleModal && <SaleModal actions={actions} {...saleModal} onClose={() => setSaleModal(null)}/>}
    </div>
  )
}

const SaleModal = ({ actions, agent, before, after, saleType, conversations, onClose = () => {} }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const saleText = {
    1: 'made first sales',
    2: 'made first sales that were of the smallest package',
    3: 'made smallest package sale and upgraded',
    4: 'made smallest package sale and have not upgraded',
    5: 'made manual repurchases',
  }

  return (
    <Modal open={conversations.length > 0}>
      <Modal.Header>Conversations of users that {saleText[`${saleType}`]} when {agent?.name} was attached</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {loading && <Loader active={loading} inverted/>}
          {error && (
            <Grid centered={true}>
              <Grid.Column style={{ display: 'flex', justifyContent: 'center'}}>
                { error && <h4>{error}</h4>}
              </Grid.Column>
            </Grid>
          )}
          {conversations.length > 0 && <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Impersonator uuid</Table.HeaderCell>
                <Table.HeaderCell>Correspondent uuid</Table.HeaderCell>
                <Table.HeaderCell>actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {conversations.map((conversationUid, idx) => {
                const [user0Uid, user1Uid] = conversationUid.split(':')
                return (
                  <Table.Row key={idx}>
                    <Table.Cell>{user0Uid}</Table.Cell>
                    <Table.Cell>{user1Uid}</Table.Cell>
                    <Table.Cell><Button color="green" as="a" target="_blank" href={`/admin/conversations/${conversationUid}`}>View</Button></Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={onClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default inject('store', 'actions')(withRouter(AgentStats))
