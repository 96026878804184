import React from 'react'
import {Message, Container} from 'semantic-ui-react'

const NotAllowed = () => (
	<Container text>
		<Message negative
			icon='exclamation triangle'
			header='Not authorized'
			content='You are not allowed to see this page !'
		/>
	</Container>
	
)

export default NotAllowed