import React from 'react'
import {Button, Form, Segment, Message} from 'semantic-ui-react'

@connect()
class LoginForm extends React.Component {

  state = {
    error: null,
    loading: false,
  }

  onSubmit = async(e) => {
    e.preventDefault()
    const { actions, store, successRedirect = () => {} } = this.props

    this.setState({loading: true})
    try {
      await actions.auth.login(this.state)
      if(!store.socket) {
        actions.chat.initWebSocket(false)
      }
      successRedirect()
    } catch(error) {
      this.setState({ error: 'Invalid email or password', loading: false })
    }
  }

  onChange = (field) => ({ target }) => {
    this.setState({
      [field]: target.value
    })
  }

  render() {
    const { loading } = this.state
    return (
      <Segment>
        {
          this.state.error ?
            <Message negative
              icon='exclamation triangle'
              header='Login failed'
              content='Invalid email or password !'
            />
            : ""
        }
        <Form onSubmit={this.onSubmit}>
          <Form.Field>
            <label htmlFor='email'>Email</label>
            <input id='email'
                   name='email'
                   placeholder='Your email'
                   autoComplete='email'
                   autoFocus={true}
                   tabIndex={1}
                   type="email"
                   defaultValue={this.state.email}
                   onChange={this.onChange('email')}
                   required/>
          </Form.Field>
          <Form.Field>
            <input id='password'
                   name='password'
                   type='password'
                   tabIndex={2}
                   placeholder='Minimum 6 characters'
                   autoComplete='current-password'
                   onChange={this.onChange('password')}
                   required/>
          </Form.Field>
          <Button tabIndex={3} type='submit' loading={loading} disabled={loading}>Submit</Button>
        </Form>
      </Segment>
    )
  }
}

export default LoginForm
