import React from 'react'
import { Container, Form, Grid } from 'semantic-ui-react'
import EnvSelector from '../components/Playground/EnvSelector'
import PlaygroundView from '../components/Playground/PlaygroundView'
// import ChatJsSelector from '../components/Playground/ChatJsSelector'

class Playground extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      cssToggle: true,
      ccpConfLoaded: false,
      serverEnv: null,
      errors: []
    }
  }

  toggleCss() {
    const cssToggle = this.state.cssToggle
    void(document.styleSheets.item(0).disabled=cssToggle)
    this.setState({ cssToggle: !cssToggle })
  }

  onError = (error) => {
    this.setState({ errors: this.state.errors.concat([error])})
  }

  errors = () => {
    const { errors } = this.state
    return(
      errors.length > 0 && <div>
        <ul>
          { errors.map( (e, i) => <li key={i}>{e}</li>)}
        </ul>
      </div>
    )
  }

  ccpConfigLoaded = (serverEnv) => {
    this.setState({ ccpConfLoaded: true, serverEnv })
  }

  renderSelectors = () => {
    return(
      <Grid columns={2} stackable>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Form>
              <Grid columns={2} stackable>
                <Grid.Column>
                  <EnvSelector ccpConfigLoaded={this.ccpConfigLoaded}/>
                </Grid.Column>
                { this.state.ccpConfLoaded && <Grid.Column>
                  {/*<ChatJsSelector onError={this.onError} serverEnv={this.state.serverEnv}/>*/}
                </Grid.Column> }
              </Grid>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }

  render() {
    return (
      <Container>
        {this.state.errors.length > 0 && this.errors()}
        {this.renderSelectors()}
        <div className='ui stackable two column grid'>
          <a onClick={() => this.toggleCss()} className='hover-pointer'>Css toggle(either widget or form has good css)</a>
        </div>
        <PlaygroundView/>
        <div id="chat-widget"/>
      </Container>
    )
  }
}

export default Playground
