import React, { useEffect, useState } from 'react'
import { Select, Form } from 'semantic-ui-react'

const AgentsListDropdown = ({ name, value, onChange, multiple, actions, transfer = null }) => {
  const [agents, setAgents] = useState([])

  if(!transfer && !Array.isArray(value) && value && value.length) {
    value = [value]
  }

  useEffect(() => {
    listAgents()
  }, [])

  const listAgents = async() => {
    const includeOff = !transfer
    let filteredAgents = await actions.agent.listAgents({ includeDeleted: includeOff, includeDeactivated: includeOff })
    if(transfer)
    {
      filteredAgents = filteredAgents.filter(elem => elem.status == 'online' && elem.id != transfer.currentAgentId)
    }
    setAgents(filteredAgents)
  }

  return(
    <Form.Field
      control={Select}
      style={transfer?.style ?? {}}
      placeholder={transfer?.placeholder ?? 'Agents'}
      label={transfer?.label ?? 'Agents'}
      fluid
      search
      { ...{multiple} }
      name={name}
      value={value}
      onChange={onChange}
      options={agents.reduce( (arr, agent, i) => {
        arr.push({ key: i, text: `${agent.name.charAt(0).toUpperCase()}${agent.name.slice(1)} ${agent.deleted ? '(deleted)' : ''} ${!agent.deleted && !agent.isActive ? '(inactive)' : ''}`, value: `${agent.id}` })
        return arr
      }, [])}/>
  )
}

export default AgentsListDropdown
