import React from "react"
import emoji from "react-easy-emoji"
import PropTypes from "prop-types"
import styled from "styled-components"
import {
  SMILEYS_AND_PEOPLE,
  ANIMALS_AND_NATURE,
  FOOD_AND_DRINK,
  TRAVEL_AND_PLACES,
  ACTIVITIES,
  OBJECTS,
  SYMBOLS,
  FLAGS,
} from "./Emojis"

const Emoji = styled.span`
  cursor: pointer;
  font-size: 20px;
  padding: 3px;
  transition: transform 0.2s;
  z-index: 50;
  img {
    user-select: none;
  }
  img:hover {
    transform: scale(1.2);
  }
`

export const Picker = ({
  onEmojiSelected = () => {},
  hidePicker = () => {},
  emojiType,
}) => {
  const onEmojiSelect = (e) => {
    if (e.target.alt === undefined) {
      return
    }
    onEmojiSelected({
      image: e.target,
      unicode: e.target.alt,
      shortname: e.target.title,
    })
    hidePicker()
  }
  let emojiArray = SMILEYS_AND_PEOPLE
  switch (emojiType) {
    case "Smileys & People":
      emojiArray = SMILEYS_AND_PEOPLE
      break
    case "Animals & Nature":
      emojiArray = ANIMALS_AND_NATURE
      break
    case "Food & Drink":
      emojiArray = FOOD_AND_DRINK
      break
    case "Travel & Places":
      emojiArray = TRAVEL_AND_PLACES
      break
    case "Activities":
      emojiArray = ACTIVITIES
      break
    case "Objects":
      emojiArray = OBJECTS
      break
    case "Symbols":
      emojiArray = SYMBOLS
      break
    case "Flags":
      emojiArray = FLAGS
      break
    default:
      emojiArray = SMILEYS_AND_PEOPLE
      break
  }

  return (
    <div>
      {emojiArray.map((emojiChar, index) => (
        <Emoji key={index} role="presentation" onClick={onEmojiSelect}>
          {emoji(emojiChar)}
        </Emoji>
      ))}
    </div>
  )
}

Picker.propTypes = {
  onEmojiSelected: PropTypes.func.isRequired,
  hidePicker: PropTypes.func.isRequired,
}
