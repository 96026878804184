import request from 'core/request'
import {omitBy, isEmpty} from 'lodash'

const DETACH_CONVERSATIONS = '/v2/ccp/debug/detachConversations'
const DELETE_CONVERSATIONS = '/v2/message/debug/deleteConversations'
const RESET_CONVERSATIONS = '/v2/ccp/debug/resetRepliedConversations'
const GET_WHITELABELS = '/v2/ccp/debug/getWhiteLabels'
const GET_EXTERNAL_USER_INFO = '/v2/ccp/debug/userInfo'
const GET_USER_DATA = '/v2/ccp/debug/getUserData'
const GET_USER_CONVERSATIONS = '/v2/message/debug/userConversations'
const GET_AUTH_TOKEN = '/v2/ccp/debug/generateToken'
const GET_WHITE_LABEL_USER = '/v2/ccp/debug/getWhiteLabelUser'

const opts = {
  xAuthToken: 'CvmtNX^k&&SH*M?tD7J6!746eHJTF-pz!XW$3xT3'
}

export async function fetchWhiteLabels() {
  return request.get(GET_WHITELABELS, null, opts).then(({ result }) => {
    return result.whiteLabels
  })
}

export async function detachConversations(userUid, conversationUids = []) {
  return request.post(DETACH_CONVERSATIONS, { userUid, conversationUids }, opts)
}

export async function resetConversations(userUid = [], receiverUids = []) {
  return request.post(RESET_CONVERSATIONS, { userUid, receiverUids }, opts)
}

export async function deleteConversations(params) {
  return request.post(DELETE_CONVERSATIONS, params, opts)
}

export async function getExternalUserInfo(userUid, viewerUid, whiteLabelUid, ap = false, includeHistory = false) {
  return request.get(GET_EXTERNAL_USER_INFO, omitBy({ userUid, viewerUid, whiteLabelUid, ap, includeHistory }, isEmpty), opts)
}

export async function getUserData(userUid) {
  return request.get(GET_USER_DATA, { userUid }, opts).then(({ result }) => result)
}

export async function getDebugConversations(userUid) {
  return request.get(GET_USER_CONVERSATIONS, { userUid }, opts).then(({ result }) => result.conversations)
}

export async function getAuthToken(userUid) {
  return request.get(GET_AUTH_TOKEN, { userUid }, opts)
}

export async function getWhiteLabelUser(userUid) {
  return request.get(GET_WHITE_LABEL_USER, { userUid }, opts)
}
