import React from 'react'
import { Button, List } from 'semantic-ui-react'
import ModalBaseClass from './ModalBaseClass'
import { deleteConversations } from './helpers'

class ModalDelete extends ModalBaseClass {

  handleDelete = async(conversationUids) => {
    const index = conversationUids[0].split(':').findIndex(u => u === this.props.userUid)
    const data = {
      [`user${index}Uid`]: this.props.userUid,
      conversationUids
    }
    await deleteConversations(data)
    await this.fetchConversations()
  }

  onConfirm = async() => {
    const conversationUids = this.state.conversations.map(conv => conv.conversationUid)
    await this.handleDelete(conversationUids)
    console.warn('Deleted', conversationUids)
  }

  render() {
    const {conversations} = this.state
    const ModalBody = this.renderBody

    return (
      <ModalBody
        headerText='Delete conversations'
        triggerButtonText='Delete'
        confirmButtonText='Delete All'>
        <List divided verticalAlign='middle'>
          {conversations.map(conv => {
            return (
              <List.Item key={conv.conversationUid}>
                <List.Content floated='right'>
                  <Button onClick={() => this.handleDelete([conv.conversationUid])}>
                    Delete
                  </Button>
                </List.Content>
                <List.Content>
                  {conv.conversationUid}
                </List.Content>
              </List.Item>
            )
          })}
        </List>
      </ModalBody>
    )
  }
}

export default ModalDelete
