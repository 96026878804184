import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React, { useState } from 'react'
import { Transition } from 'semantic-ui-react'
import styled from 'styled-components'
import Messages from '../../Chat/Icons/Messages'
import Refresh from '../Icons/Refresh'
import { useGetUsersCredits } from '../hooks/useGetUsersCredits'

const COLOR_GREY = '#808080'
const COLOR_RED = '#e52e66'
const COLOR_GREEN = '#008000'
const COLOR_DARK_BLUE = '#3B5998'

const UsersBalanceWrapper = styled.div`
  position: relative;
  display: flex;
  width: fit-content;
  cursor: pointer;
  .messages-icon {
      fill: ${COLOR_GREY};
  }
  .refresh-icon {
    fill: ${COLOR_DARK_BLUE};
  }
`
const BoldText = styled.div`
  font-size: 18px;
  line-height: 22px;
  margin-left: 4px;
  color: ${COLOR_GREEN};
  color: ${
    props => props.rebillEnabled 
      ? COLOR_GREEN
      : props.rebillEnabled === undefined
        ? COLOR_GREEN : COLOR_RED
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  .amount {
    color: ${COLOR_GREEN}
  }
`
const TooltipBody = styled.div`
  display: flex;
  padding: 10px;  
  flex-direction: column;
  gap: 4px;
  border: 1px solid #ADD8E6;
  position: absolute;
  margin-bottom: 8px;
  background-color: white;
  color: black;
  font-size: 14px;
  z-index: 1000;
  width: 195px;
  left: 0;
  top: 26px;
`

const parseBoolean = x => x == 'true' || x == '1'

const UsersBalance = ({ store }) => {
  const { isCreditSite = false, otherUserProfile = {} } = store.conversation || {}
  const { credits = {}, expiryDate = null, rebillEnabled = false, isPremium = false } = otherUserProfile
  const { getCredits } = useGetUsersCredits(store)

  const [visible, setVisible] = useState(false)

  const premium = parseBoolean(isPremium)
  const rebill = parseBoolean(rebillEnabled)
  const doesUserHaveCredits = !!(credits?.expiryDate)

  if(!premium) {
    return null
  }

  return (
    <UsersBalanceWrapper
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {(isCreditSite && doesUserHaveCredits) && (
        <>
          <div onClick={() => getCredits()}>
            <Refresh className='refresh-icon'/>
          </div>
          <Messages className='messages-icon'/>
          <BoldText>{credits?.remainingMessages || 0}</BoldText>
          <Transition visible={visible} animation='slide down' duration={100}>
            <TooltipBody>
              <TooltipRow
                title="Remaining messages:"
                ammount={credits?.remainingMessages || 0}
              />
              <TooltipRow
                title="Remaining credits:"
                ammount={credits?.remainingCredits || 0}
              />
              <TooltipRow
                title="Expiry date:"
                ammount={moment(credits.expiryDate * 1000).format('yyyy-MM-DD')}
              />
            </TooltipBody>
          </Transition>
        </>
      )}
      {(!isCreditSite || (isCreditSite && !doesUserHaveCredits))&& (
        <BoldText rebillEnabled={rebill}>{moment(expiryDate).format('MM/DD')}</BoldText>
      )}
    </UsersBalanceWrapper>
  )
}

export default inject('store')(observer(UsersBalance))

const TooltipRow = ({ title, ammount }) => {
  return (
    <Row>
      <span className='title'>{ title }</span>
      <span className='amount'>{ ammount }</span>
    </Row>
  )
}