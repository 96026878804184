import React from "react"

const Flag = ({ isClicked }) => (
  <svg
    viewBox="0 0 20 20"
    width="20"
    height="20"
    className={isClicked ? "emoji-highlighted" : ""}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 3.69v8.365l.014-.006A8.4 8.4 0 0 1 7 11.5c1.316 0 2.591.581 3.656 1.068 1.148.525 2.043.932 2.844.932a5.1 5.1 0 0 0 2.07-.435 4 4 0 0 0 .43-.219V4.512A6.6 6.6 0 0 1 13.5 5c-1.14 0-2.306-.535-3.31-.996l-.158-.072C8.909 3.42 7.934 3 7 3c-1.014 0-1.86.226-2.452.45A6 6 0 0 0 4 3.69m12.282-1.026h.001l.001-.002h.001zl-.027.02q-.04.03-.132.088a4 4 0 0 1-.553.293 5.1 5.1 0 0 1-2.07.435c-.8 0-1.696-.407-2.844-.932C9.59 2.08 8.316 1.5 7 1.5a8.4 8.4 0 0 0-2.986.55 7 7 0 0 0-.847.382 5 5 0 0 0-.302.174l-.02.013-.007.004-.002.002h-.001s-.001.001.415.625l-.416-.624a.75.75 0 0 0-.334.624v14.529a.75.75 0 0 0 1.5 0v-4.09a6 6 0 0 1 .548-.238A6.9 6.9 0 0 1 7 13c.934 0 1.909.419 3.032.932l.158.072c1.004.461 2.17.996 3.31.996a6.6 6.6 0 0 0 2.68-.565 6 6 0 0 0 .95-.533l.06-.043.018-.015.006-.005.003-.002.001-.001-.468-.586.468.586a.75.75 0 0 0 .282-.586v-10a.75.75 0 0 0-1.218-.586M3.664 13.876"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Flag
