import React from 'react'
import { NavLink } from 'react-router-dom'
import { Icon, Loader } from 'semantic-ui-react'
import { UT } from 'core/constants'
import { EnabledFeature } from 'core/featureSwitch'
import Logout from '../Auth/Logout'
import { inject } from 'mobx-react'
import styled from 'styled-components'
import { action } from 'mobx'

const HeaderContainer = styled.div`
  display: flex;
  width: 100vw;
  background-color: #0a82fe;
  color: white;
`
const PageTitle = styled.h3`
  user-select: none;

  &&& {
    margin: 1em auto 1em 2em;
  }
`
const Item = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  border: #73b8ff solid;
  border-width: 0 0 0 2px;
  cursor: pointer;

  .selected {
    background-color: #2d5ab7;
  }

  &:hover {
    background-color: #2c70fe;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0;
    padding: 0 1em;
    color: white;
    user-select: none;
  }
`

const NavLinkContainer = styled.div`
  height: 100%;

  a {
     cursor: ${props => props.$loading === true ? 'not-allowed' : ''};

    .ui.inline.loader {
      margin: 0 0.3em 0 0.2em;
      visibility: ${props => props.$loading === true ? 'initial' : 'hidden'}
    }

    .ui.loader {
      &:after {
        border-color: #ffffff transparent transparent;
      }
    }
  }
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;

  .icon {
    margin: 0;
    width: auto;

    &:after {
      border-color: #ffffff;
    }
  }
`

export const HeaderLink = ({ to, icon, onClick, loading = false, children }) => (
  <NavLinkContainer $loading={loading}>
    <NavLink to={to} activeClassName="selected" onClick={onClick}>
      <IconContainer>
        {loading ? <Loader active={loading} inline size="small"/> : <Icon name={icon} size="large"/>}
      </IconContainer>
      {children}
    </NavLink>
  </NavLinkContainer>
)

const Header = inject('store')(({ store }) => {
  const { account } = store

  const breakAction = action(() => (store.account.leave = true))

  return (
    <HeaderContainer>
      <PageTitle>CCP</PageTitle>
      {account.type === UT.AGENT ?
        <Item>
          <a onClick={breakAction} id="leave">
            <Icon name="sign out" size="large"/>
            Break
          </a>
        </Item> : ''
      }
      {account.type === UT.AGENT ?
        <Item>
          <HeaderLink to="/chat" icon="commenting">Chat</HeaderLink>
        </Item> : null
      }
      {account.type === UT.ADMIN ?
        <Item>
          <HeaderLink to="/admin/agents" icon="users">Agents</HeaderLink>
        </Item> : null
      }
      {account.type === UT.ADMIN ?
        <EnabledFeature featureName="consultConversations"><Item>
          <HeaderLink to="/admin/conversations" icon="comment">Conversations</HeaderLink>
        </Item></EnabledFeature> : null
      }

      {account.type === UT.ADMIN ?
        <Item>
          <HeaderLink to="/settings" icon="settings">Settings</HeaderLink>
        </Item> : null
      }

      {process.env.NODE_ENV !== 'production' &&
        <Item>
          <HeaderLink to="/debug" icon="bug">Debug</HeaderLink>
        </Item>
      }

      <Item><Logout/></Item>
    </HeaderContainer>
  )
})

export default Header


