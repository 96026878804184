import React from 'react'

const Gift = () => (
<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24.001 24">
  <g id="gifts" transform="translate(-1.831)">
    <rect id="Rectangle_304" data-name="Rectangle 304" width="24" height="24" transform="translate(1.832)" fill="none"/>
    <g id="card-gift" transform="translate(1.831 -1.3)">
      <path id="Path_2164" data-name="Path 2164" d="M98.184,38.21h3.166c-1.328-1.975-3.758-4.71-6.08-4.71-1.155,0-1.67.455-1.67,1.478C93.6,37.061,96.006,38.21,98.184,38.21Z" transform="translate(-89.495 -29.333)" fill="none"/>
      <path id="Path_2165" data-name="Path 2165" d="M286.46,35.108c0-1.023-.515-1.508-1.67-1.508-2.328,0-4.662,2.735-5.99,4.71h3.166C284.144,38.3,286.46,37.185,286.46,35.108Z" transform="translate(-266.554 -29.427)" fill="none"/>
      <rect id="Rectangle_305" data-name="Rectangle 305" width="12.154" height="3.728" transform="translate(9.973 8.822)" fill="none"/>
      <rect id="Rectangle_306" data-name="Rectangle 306" width="10.269" height="12.172" transform="translate(3.758 10.788)" fill="none"/>
      <rect id="Rectangle_307" data-name="Rectangle 307" width="12.154" height="3.728" transform="translate(1.867 8.822)" fill="none"/>
      <rect id="Rectangle_308" data-name="Rectangle 308" width="10.293" height="12.172" transform="translate(9.973 10.788)" fill="none"/>
      <path id="Path_2166" data-name="Path 2166" d="M24,9.078a1.5,1.5,0,0,0-1.5-1.5H19.944a3.518,3.518,0,0,0,1.085-2.568A2.581,2.581,0,0,0,18.187,2.3c-2.755,0-5.059,2.933-6.139,4.579C10.963,5.233,8.592,2.3,5.837,2.3A2.584,2.584,0,0,0,3,5.012,3.5,3.5,0,0,0,4.109,7.58H1.5A1.493,1.493,0,0,0,0,9.078v4.488H1.512v9.763a1.5,1.5,0,0,0,1.5,1.5H21a1.5,1.5,0,0,0,1.5-1.5V13.566H24ZM18.187,3.8c.926,0,1.339.389,1.339,1.21,0,1.666-1.858,2.563-3.61,2.563H13.378C14.443,6,16.32,3.8,18.187,3.8ZM5.832,3.8C7.7,3.8,9.643,6,10.709,7.58H8.17c-1.747,0-3.677-.922-3.677-2.587C4.493,4.167,4.906,3.8,5.832,3.8ZM22.5,12.068H12.749V9.078H22.5l0,2.99Zm-21-2.99h9.749v2.99H1.5Zm1.517,4.488h8.237v9.763H3.014ZM21,23.329H12.749V13.566H21Z" transform="translate(0)" fill="#9c9c9f"/>
    </g>
  </g>
</svg>
)

export default Gift