import React from 'react'
import { Button, List } from 'semantic-ui-react'
import ModalBaseClass from './ModalBaseClass'
import { resetConversations } from './helpers'

class ModalReset extends ModalBaseClass {

  handleReset = async(userUid, receiverUids) => {
    await resetConversations(userUid, receiverUids)
    await this.fetchUserData()
  }

  onConfirm = async() => {
    const { userUid } = this.props
    const { userData } = this.state
    if(userData && userData.repliedconversations && userData.repliedconversations.length > 0) {
      await this.handleReset(userUid, userData.repliedconversations)
      // eslint-disable-next-line no-console
      console.log('Reset', userUid, userData.repliedconversations)
    }else {
      // eslint-disable-next-line no-console
      console.log('Reset: no replied conversations found for user: ', userUid)
    }
  }

  render() {
    const { userUid } = this.props
    const { userData } = this.state

    const receiverUids = userData.repliedconversations ? userData.repliedconversations : []

    const ModalBody = this.renderBody

    return (
      <ModalBody
        headerText='Reset replied conversations'
        triggerButtonText='Reset replies'
        confirmButtonText='Reset All'>
        <h5>Current user is {userUid}</h5>
        <List divided verticalAlign='middle'>
          { receiverUids.length === 0 && <p>This user does not have any replied conversations</p> }
          { receiverUids.map(receiverUid => {
            return (
              <List.Item key={receiverUid}>
                <List.Content floated='right'>
                  <Button onClick={() => this.handleReset(userUid, [receiverUid])}>
                    Reset
                  </Button>
                </List.Content>
                <List.Content>
                  {receiverUid}
                </List.Content>
              </List.Item>
            )
          })}
        </List>
      </ModalBody>

    )
  }
}

export default ModalReset
