import { useHistory, useLocation } from 'react-router'
import { useQueryParams } from 'core/hooks/useQueryParams'
import { toQueryString } from 'core/request'

export const usePushToHistory = () => {
  const { pathname } = useLocation()
  const history = useHistory()
  const queryParams = useQueryParams()

  return [
    queryParams,
    newParams => {
      history.push(pathname + toQueryString(newParams))
    }
  ]
}
