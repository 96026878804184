import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { GlobalFeatureSwitches } from './GlobalFeatureSwitches'
import { SiteFeatureSwitches } from './SiteFeatureSwitches'
import { Loader } from 'semantic-ui-react'
import { CCP_GET_FEATURE_SWITCH_DESCRIPTIONS, CCP_GET_FEATURE_SWITCHES_GLOBAL, CCP_WHITELABELS_LIST } from '../../../../core/endpoints'
import request from 'core/request'

export const FeatureSwitchName = ({ name }) => <span>{name.charAt(0).toUpperCase() + name.slice(1).split(/(?=[A-Z])/).join(' ')}</span>

const Container = styled.div`
  position: relative;
  height: 100%;
  & > div {
    padding: 1em 0;
  }
`

export const FeatureSwitches = () => {
  const [featureSwitches, setFeatureSwitches] = useState([])
  const [globalFeatureSwitches, setGlobalFeatureSwitches] = useState([])
  const [fsDescriptions, setFsDescriptions] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchFeatureSwitches()
  }, [])

  const fetchFeatureSwitches = async() => {
    request.get(CCP_GET_FEATURE_SWITCH_DESCRIPTIONS)
      .then((resultDescriptions) => {
        setFsDescriptions(resultDescriptions.result)
      })
    request.get(CCP_GET_FEATURE_SWITCHES_GLOBAL)
      .then((resultGlobal) => {
        setGlobalFeatureSwitches(resultGlobal.result)
      })
    request.get(CCP_WHITELABELS_LIST)
      .then((resultWhiteLabels) => {
        setFeatureSwitches(resultWhiteLabels.result)
      })
    setLoading(false)
  }

  if(loading) {
    return (
      <Container>
        <Loader active={loading} size="large">Loading...</Loader>
      </Container>
    )
  }

  return (
    <Container>
      <GlobalFeatureSwitches globalFeatureSwitches={globalFeatureSwitches} descriptions={fsDescriptions}/>
      <SiteFeatureSwitches siteFeatureSwitches={featureSwitches} descriptions={fsDescriptions}/>
    </Container>
  )
}
