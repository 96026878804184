import { get as _get } from 'lodash'

let configuration = null

function set(newConfiguration) {
  configuration = newConfiguration
}

function get(key, fallback = null) {
  if(!configuration) {
    console.warn('Ccp configuration not set')
    return null
  }

  if(!key) {
    return configuration
  }

  return _get(configuration, key, fallback)
}

export { set, get }
