import { action, makeObservable } from 'mobx'
import Download from 'downloadjs'
import {values, get, omitBy, isNil } from 'lodash'
import { ccpProvider } from 'core/CcpProvider'
import store from '../store'
import actions from '../actions'
import Promise from "lodash/_Promise"

export default class Admin {
  constructor() {
    makeObservable(this, {
      getFeatureSwitches: action,
      setFeatureSwitch: action,
      getSettings: action,
      updateSettings: action,
      clear: action,
      downloadFile: action,
      clearCache: action,
    })
  }

  getFeatureSwitches() {
    return ccpProvider.api.admin.getFeatureSwitches()
  }


  setFeatureSwitch({ whiteLabelUid, featureSwitch, value, type }) {
    return ccpProvider.api.admin.setFeatureSwitch(omitBy({ whiteLabelUid, featureSwitch, value, type }, isNil))
  }

  async getFilteredConversations(params) {
    for (const key in params) {
      if (typeof params[key] === 'undefined' || params[key] === null || params[key] === '') {
        delete params[key]
      }
    }
    return ccpProvider.api.admin.getFilteredConversations(params)
  }

  async getConversationsStats(params) {
    const agentMapping = await actions.agent.mapAgents()
    const [result, error] = await ccpProvider.api.admin.getConversationsStats(params)
    if(error) {
      return [null, error]
    }

    const { messageCounters = [], membershipCounters = [] } = result.stats
    const combinedCounters = {}
    const DEFAULT_COUNTER = {
        detachedAt: null,
        agentMessages: 0,
        userMessages: 0,
        agentInitialResponseTime: 0,
        initialSale: 0,
        rebill: 0,
        rebillStatus: 0,
        partialDowngrade: 0,
        refund: 0,
        downgrade: 0,
        chargeback: 0,
    }

    messageCounters.forEach(c => {
      combinedCounters[`${c.conversationUid}:${c.attachedAt}`] = { ...DEFAULT_COUNTER, ...c, name: get(agentMapping, [c.agentId, 'name']) }
    })

    membershipCounters.forEach(c => {
      const id = `${c.conversationUid}:${c.attachedAt}`
      //extend existing counter or create new
      combinedCounters[id] = { ...(combinedCounters[id] || DEFAULT_COUNTER), ...c, name: get(agentMapping, [c.agentId, 'name']) }
    })

    return [{
      total: Object.keys(combinedCounters).length,
      conversations: values(combinedCounters),
    }]
  }

  async getAgentMessages(params) {
    for (const key in params) {
      if (typeof params[key] === 'undefined' || params[key] === null || params[key] === '') {
        delete params[key]
      }
    }

    if(!params.agentIds?.length) {
      delete params.agentIds
    } else {
      params.agentIds = params.agentIds.join(',')
    }

    const [result, error]  = await ccpProvider.api.admin.getAgentMessages(params)
    if(error) {
     return { error: error.message }
    }

    if(result && result.messages) {
      const agentMapping = await actions.agent.mapAgents()
      result.messages.forEach(m => {
        m.name = get(agentMapping, [m.agentId, 'name'])
      })
    }

    return result
  }

  async getAgentStats(params) {
    for (const key in params) {
      if (typeof params[key] === 'undefined' || params[key] === null || params[key] === '') {
        delete params[key]
      }
    }
    return ccpProvider.api.admin.getAgentStats(params)
  }

  async getRecurringConversations(params) {
    return ccpProvider.api.admin.getRecurringConversations(params)
  }

  async getAgentSessions(params) {
    for (const key in params) {
      if (typeof params[key] === 'undefined' || params[key] === null || params[key] === '') {
        delete params[key]
      }
    }
    return ccpProvider.api.admin.getAgentSessions(params)
  }

  async getConversationLogs(params) {
    for (const key in params) {
      if (typeof params[key] === 'undefined' || params[key] === null || params[key] === '') {
        delete params[key]
      }
    }
    return ccpProvider.api.admin.getConversationLogs(params)
  }

  async getConversationDetails(conversationUid) {
    return ccpProvider.api.admin.getConversationDetails(conversationUid)
  }

  async getSettings(groupName) {
    return ccpProvider.api.admin.getSettings(groupName)
  }

  async updateSettings({ key, id, value }) {
    return ccpProvider.api.admin.updateSettings({ key, id, value })
  }

  async clearCache() {
    return ccpProvider.api.admin.clearCache()
  }

  clear() {
    store.profiles = []
  }

  downloadFile(url) {
    Download(url)
  }
}
