import React from 'react'
import { Button as SButton } from 'semantic-ui-react'

const Button = ({ loading, children, ...props }) => {
  return (
    <SButton loading={loading} {...props}>
      {children}
    </SButton>
  )
}

export default Button
