import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { size } from 'lodash'
import { Loader } from 'semantic-ui-react'
import Button from '../../elements/Button'

const TitleContainer = styled.h3`
  margin: 0 0 1em 0;
`

const Classifier = styled.li`
  span {
    display: inline-block;
    font-weight: bold;
    &:first-letter {
      text-transform: capitalize;
    }
  }
`

const ClassifiersContainer = styled.div`
  position: relative;
  border: solid 1px lightgray;
`

const ClassifierList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const ClassifiersErrorContainer = styled.div`
  position: absolute;
  top: calc( 50% - 2em );
  left: 0;
  text-align: center;
  margin: 1em;
`

export const Classifiers = observer(({ conversation, userIndex, history }) => {
  const [profileClassifiers, setProfileClassifiers] = useState({classifiers: [], error: false, loading: false})

  useEffect(() => {
    getClassifiers().then()
  }, [userIndex])


  const getClassifiers = async() => {
    setProfileClassifiers({
      ...profileClassifiers,
      loading: true
    })

    let response = []
    if (history) {
      response = await conversation.getClassifiers(history.userUid)
    } else {
      response = await conversation.getClassifiers(userIndex ? conversation.otherUserUid : conversation.meUid)
    }

    setProfileClassifiers({
      ...profileClassifiers,
      classifiers: response,
      loading: false
    })
  }

  const Title = () => <TitleContainer>Classifiers</TitleContainer>

  if(profileClassifiers.loading) {
    return (
      <ClassifiersContainer>
        <Title/>
        <Loader active={true}/>
      </ClassifiersContainer>
    )
  }

  if(profileClassifiers.error) {
    return (
      <ClassifiersContainer>
        <Title/>
        <ClassifiersErrorContainer>
          <p>There was an error fetching the classifiers of this user, please try again.</p>
          <Button loading={profileClassifiers.loading} onClick={() => conversation.getClassifiers(userIndex)} color="blue">Retry</Button>
        </ClassifiersErrorContainer>
      </ClassifiersContainer>
    )
  }

  if(!size(profileClassifiers.classifiers)) {
    return (
      <ClassifiersContainer>
        <Title/>
        <p>This user doesn't have any classifiers set.</p>
      </ClassifiersContainer>
    )
  }

  const formatClassifierName = name => name.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase()

  return (
    <ClassifiersContainer>
      <Title/>
      <ClassifierList>
        {Object.entries(profileClassifiers.classifiers).map(([name, value], index) => <Classifier key={index}><span>{formatClassifierName(name)}</span>: {value}</Classifier>)}
      </ClassifierList>
    </ClassifiersContainer>
  )
})
