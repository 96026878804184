import moment from 'moment'
import React from 'react'

export const Time = ({ time, format = 'MMM DD YYYY HH:mm' }) => <span>{moment(time).format(format)}</span>
export const Duration = ({ start, end, unit = 'minutes', digits = 2 }) => {
  if(start >= end) {
    return <span>invalid start and end time</span>
  }

  if(!['seconds', 'minutes', 'hours', 'days'].includes(unit)) {
    throw 'invalid unit'
  }

  return <span>{moment.duration(moment(end).diff(moment(start))).as(unit).toFixed(digits)}</span>
}
