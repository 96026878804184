import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  height: fit-content;
  padding: 10px 10px;
  border: solid 1px lightgray;
  margin: 5px 10px;
  border-radius: 4px;
`

export default function GoalView(props) {
  const { objectives = [], isPremium, rebillEnabled } = props

  const objectiveNames = objectives.map(o => o.name)

  let goal
  if(isPremium && parseInt(rebillEnabled) && objectiveNames.includes('Keep rebill ON')) {
    goal = 'Keep rebill ON'
  }

  if(isPremium && !parseInt(rebillEnabled) && objectiveNames.includes('Reactivate rebill')) {
    goal = 'Reactivate rebill'
  }

  if(!goal) {
    return null
  }

  return (
    <Container className="goals">
      <h4>Goal</h4>
      <p>{goal}</p>
    </Container>
  )
}
