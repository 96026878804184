import styled from 'styled-components'
import React, { useEffect, useState } from 'react'

const InputContainer = styled.div`
  position: relative;
  display: inline-block;
`
const Clear = styled.span`
  content: 'X';
  position: absolute;
  right: 0.3em;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
`
const Input = styled.input`
  border: solid 1px gray;
  border-radius: 0.3em;
  padding: 0.5em;
`
export const SearchInput = ({ onSearch = () => {}, clearable = false, placeholder = '' }) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    onSearch(value)
  }, [value])

  const clear = () => setValue('')
  const onChange = e => setValue(e.target.value)

  return (
    <InputContainer>
      <Input type="text" onChange={onChange} value={value} placeholder={placeholder}/>
      { clearable && <Clear onClick={clear}>X</Clear> }
    </InputContainer>
  )
}
