import React from 'react'
import { Tab } from 'semantic-ui-react'
import * as Config from 'core/config'
import AgentStats from './AgentStats'
import ConversationsContainer from './Conversations/ConversationsContainer'
import AgentMessages from './AgentMessages'
import AgentSessions from './AgentSessions'

let panes = []

@connect()
@boundary()
class TabSwitcher extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      activeIndex: 0,
    }
  }

  renderContent() {
    const currentLocation = this.props.match.path
    switch (currentLocation) {
      case '/admin/conversations':
        this.setState({ activeIndex: panes.findIndex( p => p.name === 'conversations')})
        break
      case '/admin/conversations/:conversationUid':
        this.setState({ activeIndex: panes.findIndex( p => p.name === 'conversations')})
        break
      case '/admin/agentMessages':
        this.setState({ activeIndex: panes.findIndex( p => p.name === 'messages')})
        break
      case '/admin/agentStats':
        this.setState({ activeIndex: panes.findIndex( p => p.name === 'agentStats')})
        break
      case '/admin/agentSessions':
        this.setState({ activeIndex: panes.findIndex( p => p.name === 'agentSessions')})
        break
      default:
        this.setState({ activeIndex: 0 })
        break
    }
  }


  componentDidMount() {
    panes = []
    if(Config.get('featureFlags', {}).consultConversations) {
      panes.push({ name: 'conversations', menuItem: 'Conversations', render: () => <Tab.Pane><ConversationsContainer/></Tab.Pane> })
    }

    if(Config.get('featureFlags', {}).consultConversations) { //TODO refine flag
      panes.push({ name: 'messages', menuItem: 'Chat logs', render: () => <Tab.Pane><AgentMessages/></Tab.Pane> })
    }

    if(Config.get('featureFlags', {}).agentStats) {
      panes.push({ name: 'agentStats', menuItem: 'Agent stats', render: () => <Tab.Pane><AgentStats/></Tab.Pane> })
    }

    if(Config.get('featureFlags', {}).agentSessions) {
      panes.push({ name: 'agentSessions', menuItem: 'Agent sessions', render: () => <Tab.Pane><AgentSessions/></Tab.Pane> })
    }

    this.renderContent()
  }

  componentDidUpdate(nextProps) {
    if(this.props.location.pathname !== nextProps.location.pathname) {
      this.renderContent()
    }
  }


  onTabChange = (e, data) => {
    this.setState({ activeIndex: data.activeIndex, showConversationDetail: false })
    const activePane = panes[data.activeIndex]
    switch (activePane.name) {
      case 'conversations':
        this.props.history.push('/admin/conversations')
        break
      case 'messages':
        this.props.history.push('/admin/agentMessages')
        break
      case 'agentStats':
        this.props.history.push('/admin/agentStats')
        break
      case 'agentSessions':
        this.props.history.push('/admin/agentSessions')
        break
    }
  }

  render() {
    return(
      <div>
        <Tab menu={{ pointing: true, attached: false, tabular: false, color: 'blue' }} activeIndex={this.state.activeIndex} panes={panes} onTabChange={this.onTabChange} styles={{ borderRadius: 0 }}/>
      </div>
    )
  }
}

export default TabSwitcher
