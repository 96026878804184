import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'semantic-ui-react'

class FormInput extends React.Component {
  render() {
    const { label, name, type, placeholder, defaultValue, onChange, required } = this.props
    const id = label.hashCode()
    return (
        <Form.Field>
          <label>{label}</label>
          <input
            className=""
            id={id}
            type={type}
            name={name}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onInput={(e) => onChange(e.target)}
            required={required}
          />
        </Form.Field>
    )
   }
}

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
}

FormInput.defaultProps = {
  type: 'text',
  placeholder: '',
  required: false,
}

export default FormInput
