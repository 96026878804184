import { observable, computed, makeObservable} from 'mobx'
import { orderBy, pick, size } from 'lodash'
import { LOG_SORTERS } from 'core/helpers'
import * as Config from 'core/config'
import { OBJECTIVE_UPGRADE } from '../core/constants'

class Store {
  env = 'MS-production'
  // authenticated user
  token = null
  account = {
    id: null,
    name: null,
    email: null,
    isActive: false,
    objectives: observable.array([], { deep: false }),
    online: true,
    leave: false,
    break: false,
    autoBreak: false,
  }

  // chat view
  conversationUid = null
  conversations = []
  users = []
  chat = {
    isOpened: true,
    showImages: true,
  }
  conversationPreview = {
    conversationUid: null,
    identifier: null,
  }

  // admin view
  agents = []
  objectives = []
  whiteLabels = []
  stats = {
    expiresIn: 0,
    url: null,
    inProgress: {
      '1day': false,
      '7day': false,
      '14day': false,
      'custom': false,
    },
  }
  agentStats = {
    stats: observable.object({}),
  }
  admin = {
    logs: {
      conversations: [],
      conversationsForm: {
        agentId: -1,
        from: Date.now() - (1000 * 60 * 60 * 24 * 7), //one week from now
        to: null,
      },
    }
  }

  constructor() {
    makeObservable(this, {
      token: observable,
      account: observable.shallow,
      conversationUid: observable,
      conversations: observable.shallow,
      users: observable.shallow,
      chat: observable,
      conversationPreview: observable,
      agents: observable,
      objectives: observable,
      whiteLabels: observable,
      stats: observable,
      agentStats: observable,
      admin: observable,
      user: observable,
      agent: observable,
      config: observable,
      isLoggedIn: computed,
      conversation: computed,
      agentHasMaxConversations: computed,
      conversationsSorted: computed,
      unreadMessagesCount: computed,
      widgetState: computed,
      isWidget: computed,
      isDev: computed
    })
  }

  sortLogs({ sortBy }) {
    const { logs } = this.admin
    logs.conversations = logs.conversations.slice().sort(LOG_SORTERS[sortBy])
  }

  user = {
    secondBlock: false,
    block: false,
    premium: false,
    blockMessage: '',
  }

  agent = {
    extraConversations: 0,
    extraConversationsActive: false,
    extraConversationsTimer: null,
  }

  config = {
    maxConversations: 0,
    extraConversations: 0,
    extraConversationsIntervalSeconds: 0,
    featureFlags: [],
  }

  get isLoggedIn() {
    return size(this.token)
  }

  // Get current conversation
  get conversation() {
    return this.conversations.find(c => c.conversationUid === this.conversationUid)
  }

  get agentHasMaxConversations() {
    return this.conversations.length >= this.config.maxConversations
  }

  get conversationsSorted() {
    const convs = this.conversations.map(conversation => {
      return {
        ...pick(conversation, ['conversationUid', 'otherUserLastSentAt', 'lastMessageSentAt']),
      }
    })

    let orderedConversations = []
    orderedConversations = convs.sort((a,b) => {
      if (a.lastMessageSentAt < b.lastMessageSentAt) {
        return -1
      }
      if (a.lastMessageSentAt > b.lastMessageSentAt) {
        return 1
      }
      return 0
    })

    const indexedConversations = this.conversations.reduce((conversations, conversation) => ({ ...conversations, [conversation.conversationUid]: conversation }), {})
    const mappedConversations = orderedConversations.map(({ conversationUid, lastMessageSentAt, otherUserLastSentAt }) => {
      const mappedConversation = indexedConversations[conversationUid]
      mappedConversation.unansweredByMe = otherUserLastSentAt === lastMessageSentAt
      return indexedConversations[conversationUid]
   })

    if (this.account.objectives?.some(e => e.name === OBJECTIVE_UPGRADE)) {
      return orderBy(mappedConversations, [{ conversationUid: this.conversationUid }, 'unansweredByMe', 'otherUserActive', 'isNewAttached', 'lastMessageSentAt', 'unreadByMeCount'], ['desc', 'desc', 'desc', 'desc', 'desc', 'desc'])
    }

    return orderBy(mappedConversations, [{ conversationUid: this.conversationUid }, 'isNewAttached', 'unansweredByMe', (item) => item.unansweredByMe ? item.otherUserLastSentAt : null, 'meLastSentAt', 'unreadByMeCount', 'lastMessageSentAt'], ['desc', 'desc', 'desc', 'desc', 'desc', 'desc', 'desc'])
  }

  get unreadMessagesCount() {
    return this.conversations.reduce((c, con) => {
      c += (con.unreadByMeCount || 0)
      return c
    }, 0)
  }

  get widgetState() {
    try {
      const widgetState = JSON.parse(localStorage.getItem(`widgetStateFor:${Config.get('userUid')}`))
      if (widgetState && typeof widgetState ==='object' && (Date.now() - widgetState.set) < 10800000) {
        this.chat.isOpened = typeof widgetState.isOpened === 'undefined' ? true : widgetState.isOpened
        this.chat.showImages = typeof widgetState.showImages === 'undefined' ? true : widgetState.showImages
      }
    }
    catch(e) {
      //widgetState was not a JSON stringified object
    }
    return this.chat
  }

  //currently assuming that presence of userUid === chat widget
  get isWidget() {
    return Config.get('userUid')
  }

  get isDev() {
    return (Config.get('isDev') !== null && Config.get('isDev')) || (this.env !== 'MS-' + 'production' && this.env !== 'MS-production') || process.env.DEVELOPMENT === true
  }
}

export default new Store()
