import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import Section from '../UI/Section'
import { Icon, Table, Checkbox, Grid, Button, Loader, Modal } from 'semantic-ui-react'
import {observer, inject} from 'mobx-react'
import ObjectivesIndicator from '../elements/ObjectivesIndicator'
import { useHistory, useLocation } from 'react-router'
import { toast } from 'react-toastify'

const Confirm = ({ title, children, onCancel = () => {}, onSuccess = () => {} }) => {
  return (
    <Modal open={true}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        {children}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel} negative>No</Button>
        <Button onClick={onSuccess} positive>Yes</Button>
      </Modal.Actions>
    </Modal>
  )
}

const AgentList = inject('actions')(observer(({ actions }) => {
  const history = useHistory()
  const location = useLocation()

  const [agents, setAgents] = useState([])
  const [loading, setLoading] = useState(false)
  const [confirm, setConfirm] = useState({})

  const [options, setOptions] = useState({
    includeDeleted: false,
    includeDeactivated: false,
  })

  useEffect(() => {
    const qp = new URLSearchParams(location.search)
    const opts = {
      includeDeleted: qp.get('includeDeleted') === 'true',
      includeDeactivated: qp.get('includeDeactivated') === 'true' ,
    }
    if(qp.has('updated') || qp.has('created')) {
      toast.info(`Agent ${qp.has('created') ? 'created' : 'updated'}`)
      qp.delete('updated')
      qp.delete('created')
      history.replace(`/admin/agents${qp.toString()}`)
    }
    setOptions(opts)
    listAgents(opts)
  }, [])

  const listAgents = async(opts = options) => {
    setLoading(true)
    actions.agent.listAgents(opts)
      .then((agentsResponse) => setAgents(agentsResponse))
      .finally(() => setLoading(false))
  }

  const toggleCheckboxFilter = (e, { name, checked }) => {
    const opts = { ...options, [name]: checked }
    setOptions(opts)
    const params = new URLSearchParams(opts)
    history.replace({ pathname: location.pathname, search: params.toString() })
    listAgents(opts)
  }

  const toggleAgentActiveState = (agentId, newStatus) => {
    const index = agents.findIndex(a => a.id === agentId)
    setConfirm({
      title: 'Toggle agent status',
      onCancel: () => setConfirm({}),
      onSuccess: () => actions.agent.setActiveStatus(agentId, newStatus).then(([, e]) => {
        setConfirm({})
        if(e) {
          toast.error(`There was an error toggling the ${agents[index].name}' status`)
          return
        }
        toast.success(`${agents[index].name} ${newStatus ? 'activated': 'deactivated'} successfully`)
        const agentsCopy = [...agents]
        agentsCopy[index].isActive = newStatus
        setAgents(agentsCopy)
      }),
      children: <p>Are you sure you want to {newStatus ? 'activate': 'deactivate' } {agents[index].name}?</p>,
    })
  }

  const detachAll = async(agentId)  => {
    const index = agents.findIndex(a => a.id === agentId)
    setConfirm({
      title: 'Detach all conversations',
      onCancel: () => setConfirm({}),
      onSuccess: () => actions.agent.detachAll(agentId).then(([, e]) => {
        setConfirm({})
        if(e) {
          toast.error(`There was an error detaching all conversations of ${agents[index].name}`)
          return
        }
        toast.success(`Detached all conversations of ${agents[index].name}`)
        const agentsCopy = [...agents]
        agentsCopy[index].conversationCount = 0
        setAgents(agentsCopy)
      }),
      children: <p>Are you sure you want to detach all conversations of {agents[index].name}?</p>,
    })
  }

  const forcefullyLogoutAgent = async(agentId) => {
    const index = agents.findIndex(a => a.id === agentId)
    setConfirm({
      title: 'Logout agent',
      onCancel: () => setConfirm({}),
      onSuccess: () => actions.agent.forceFullyLogout(agentId).then(([, e]) => {
        setConfirm({})
        if(e) {
          toast.error(`There was an error logging out ${agents[index].name}`)
          return
        }
        toast.success(`Logged out ${agents[index].name}`)
        const agentsCopy = [...agents]
        agentsCopy[index].status = 'offline'
        setAgents(agentsCopy)
      }),
      children: <p>Are you sure you want to logout {agents[index].name}?</p>,
    })
  }

  const deleteAgent = async(agentId) => {
    const index = agents.findIndex(a => a.id === agentId)
    setConfirm({
      title: 'Delete agent',
      onCancel: () => setConfirm({}),
      onSuccess: () => actions.agent.delete(agentId).then(([, e]) => {
        setConfirm({})
        if (e) {
          toast.error(`There was an error deleting ${agents[index].name}`)
          return
        }
        toast.success(`${agents[index].name} deleted`)
        const agentsCopy = [...agents]
        agentsCopy[index].deleted = true
        setAgents(agentsCopy)
      }),
      children: <p>Are you sure you want to delete {agents[index].name}?</p>,
    })
  }

  const restoreAgent = async(agentId) => {
    const index = agents.findIndex(a => a.id === agentId)
    setConfirm({
      title: 'Delete agent',
      onCancel: () => setConfirm({}),
      onSuccess: () => actions.agent.restore(agentId).then(([, e]) => {
        setConfirm({})
        if (e) {
          toast.error(`There was an error restoring ${agents[index].name}`)
          return
        }
        toast.success(`${agents[index].name} restored`)
        const agentsCopy = [...agents]
        agentsCopy[index].deleted = false
        setAgents(agentsCopy)
      }),
      children: <p>Are you sure you want to restore {agents[index].name}?</p>,
    })
  }

  if(loading) {
    return (
      <Loader active={true}/>
    )
  }

  return (
    <div className='content'>
      <div className='content-header'>
        <div className='content-header-inner'>
          <div className='content-header__actions'>
            <NavLink to='/admin/agents/create' className='button primary'>
              <Icon name="user plus"/>
              New Agent
            </NavLink>
          </div>
        </div>
      </div>

      <div className='content-body'>
        <Section>
          <Grid columns={2}>
              <Grid.Column>
                <h2>Agents</h2>
              </Grid.Column>
              <Grid.Column className="right aligned">
                <Checkbox
                  label="Show inactive agents"
                  name="includeDeactivated"
                  onChange={toggleCheckboxFilter}
                  checked={options.includeDeactivated}/>
                <Checkbox
                  label="Show deleted agents"
                  name="includeDeleted"
                  onChange={toggleCheckboxFilter}
                  checked={options.includeDeleted}/>
              </Grid.Column>
          </Grid>

          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Live</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Objectives</Table.HeaderCell>
                <Table.HeaderCell># Conversations</Table.HeaderCell>
                <Table.HeaderCell>Activate</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>

              {agents
                .map((agent, i) => {
                  return (
                    <Table.Row key={i}>
                      <Table.Cell>{agent.name}</Table.Cell>
                      <Table.Cell><div className={`active-state bg-${agent.status}`}></div></Table.Cell>
                      <Table.Cell>{agent.email}</Table.Cell>
                      <Table.Cell>
                        <ObjectivesIndicator
                          agentObjectives={agent.objectives.map((o) => o.id)}
                        />
                      </Table.Cell>
                      <Table.Cell>{agent.conversationCount}</Table.Cell>
                      <Table.Cell><Checkbox toggle checked={agent.isActive && !agent.deleted} disabled={agent.deleted} onClick={() => toggleAgentActiveState(agent.id, !agent.isActive)}/></Table.Cell>
                      <Table.Cell className='table-actions'>
                        <NavLink style={{color: "#008AD5"}} to={`/admin/agents/edit/${agent.id}?retUrl=${location.pathname + location.search}`}><Icon name="pencil square" size='big'/></NavLink>
                        {!!agent.conversationCount && <button title="Detach all conversations" onClick={() => detachAll(agent.id)}>
                          <i className="big icons">
                            <Icon className="blue" name="comment" size="small"/>
                            <Icon className="red" name="ban" size="small"/>
                          </i>
                        </button>}
                        { agent.status !== 'offline' && <button title="Forcefully logout the agent" onClick={() => forcefullyLogoutAgent(agent.id)}>
                          <i className="big icons">
                            <Icon className="blue" name="sign out" size="small"/>
                          </i>
                        </button>}
                        { agent.deleted ?
                          <Button icon compact color="green" onClick={() => restoreAgent(agent.id)}><Icon name="undo"/></Button> :
                          <Button icon compact color="red" onClick={() => deleteAgent(agent.id)}><Icon name="delete"/></Button> }
                      </Table.Cell>
                    </Table.Row>
                  )
                })
              }
            </Table.Body>
          </Table>
        </Section>
      </div>
      {Object.keys(confirm).length > 0 && <Confirm {...confirm}/>}
    </div>
  )
}))

export default boundary()(inject('store', 'actions')(observer(AgentList)))
