import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormInput, FormRadioGroup, FormDate } from '../elements/forms'
import ObjectivesSelector from '../elements/ObjectivesSelector'
import { Form, Button, Checkbox, Confirm, Loader } from 'semantic-ui-react'
import { inject, observer } from 'mobx-react'
import { useHistory } from 'react-router'
import CampaignMapping from '../elements/CampaignMapping'
import NicheMapping from '../elements/NicheMapping'
import WhiteLabelMapping from '../elements/WhiteLabelMapping'
import { toast } from 'react-toastify'

const Title = styled.div`
  font-size: 2rem;
  width: 300px;
  margin: 0 auto;
  text-align: center;
`

const AssignedContainer = styled.div`
  justify-content: space-around;
`

const AgentEdit = inject('actions')(observer(({ actions, match }) => {

  const [loading, setLoading] = useState(false)
  const [agent, setAgent] = useState({})
  const [error, setError] = useState(null)
  const [showPasswordChanceModal, setShowPasswordChanceModal] = useState(0)

  const history = useHistory()

  const { userId } = match.params

  useEffect(() => {
    if(showPasswordChanceModal === 1) {
      submit()
    }
  }, [showPasswordChanceModal])

  useEffect(() => {
    if (userId) {
      setLoading(true)
      actions.agent.find(userId)
        .then(([a]) => {
          setAgent(a)
        })
        .finally(() => setLoading(false))
      }
  }, [])

  const submit = async() => {
    // Convert to numeric array
    agent.objectives = (agent.objectives || []).map(objective => typeof objective === 'object' ? objective.id : objective)

    if(agent.id && agent.password && showPasswordChanceModal !== 1) {
      setShowPasswordChanceModal(-1)
      return
    }

    const [, errorResponse] = agent.id ? await actions.agent.update(agent) : await actions.agent.create(agent)
    if(!errorResponse) {
      setError(null)
      history.push(`/admin/agents?${userId ? 'updated' : 'created'}`)
    } else {
      console.error(error)
      setError(errorResponse)
      toast.error('\'Email already exists or there was an error with your operation! The agent was NOT created or updated.')
    }
  }

  const onChange = ({ name, value }) => setAgent({...agent, [name]: value })

  if (loading) {
    return (
      <div>
        <Loader active={true}/>
      </div>
    )
  }

  return (
    <div className='content'>
      <div className='content-body'>
        <Title>{agent.id ? 'Edit Agent' : 'New Agent'}</Title>
        <div className='auth-form'>
          {error && <div className='form-errors'>{error}</div>}
          <Form onSubmit={submit}>

            <FormInput
              name='name'
              label='Agent name:'
              placeholder='name'
              defaultValue={agent.name || ''}
              onChange={onChange}
              type='text'
              required
            />

            <FormRadioGroup
              label='Agent type:'
              name='type'
              data={[
                { label: 'Agent', inputProps: { name: 'type', value: 'agent'}},
                { label: 'TIRO bot', inputProps: { name: 'type', value: 'tiroBot'}},
              ]}
              onChange={onChange}
              defaultValue={agent.type || 'agent'}
              required
            />

            <FormRadioGroup
              label='Agent gender:'
              name='gender'
              data={[
                { label: 'Female', inputProps: { name: 'gender', value: 'female'}},
                { label: 'Male', inputProps: { name: 'gender', value: 'male'}}
              ]}
              onChange={onChange}
              defaultValue={agent.gender || ''}
              required
            />

            <FormDate
              label='Agent birthday: '
              name='birthday'
              onChange={onChange}
              defaultValue={agent.birthday || ''}
              required
            />

            <FormInput
              name='email'
              label='Agent email:'
              placeholder='email'
              onChange={onChange}
              defaultValue={agent.email || ''}
              type='email'
              required
            />


            <FormInput
              name='password'
              label={agent.id ? 'Reset password:' : 'Agent password'}
              placeholder={agent.id ? 'Type the new password here' : 'password'}
              onChange={onChange}
              type='password'
              defaultValue={agent.password || ''}
              required={!!agent.id}
            />


            <FormInput
              name='location'
              label='Agent location:'
              placeholder='location'
              onChange={onChange}
              type='text'
              defaultValue={agent.location ? agent.location : ''}
              required
            />
            {agent.id ? (
              <div>
                <br/>
                <Checkbox label="Agent is active" name="isActive" onChange={() => onChange({
                  name: "isActive",
                  value: !agent.isActive
                })} toggle defaultChecked={agent.isActive}/>
                <br/>
                <br/>
              </div>
            ) : null}

            <ObjectivesSelector
              label='Objectives:'
              name='objectives'
              onChange={onChange}
              initialValues={agent.objectives || []}
            />

            <br/>
            <Button type='submit' primary className='fluid'>
              {agent.id ? 'Update Agent' : 'Create'}
            </Button>
          </Form>

          <Confirm
            open={showPasswordChanceModal === -1}
            header='Agent password change'
            content='Are you sure you want to change the password of this agent ? The old password will be replaced.'
            onCancel={() => setShowPasswordChanceModal(0)}
            onConfirm={() => setShowPasswordChanceModal(1)}
          />
          <AssignedContainer>
            {agent.id && <CampaignMapping agentId={agent.id}/>}
            {agent.id && <NicheMapping agentId={agent.id}/>}
            {agent.id && <WhiteLabelMapping agentId={agent.id}/>}
          </AssignedContainer>
        </div>

      </div>
    </div>
  )
}))

export default AgentEdit
