import React from 'react'
import { SettingEntry } from './SettingsEntry'
import styled from 'styled-components'

const Setting = styled.div`
  margin: 10px;
  border: 1px solid #000;
  border-radius: 2px;
  padding: 20px;
  &:first-child {
    margin-left: 0;
  }
  .field > label {
    text-transform: capitalize;
  }
`

const Title = styled.h3`
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
`

export const SettingSection = ({ name, entries, onChange }) => {
  return (
    <Setting>
      <Title>{name === 'standard' ? 'Standard Conversation Settings' : name}</Title>
      {Object.entries(entries).map(([settingName, { value, description }], index) => <SettingEntry key={index} name={settingName} value={value} description={description} onChange={(...args) => onChange(name, ...args)}/>)}
    </Setting>
  )
}
