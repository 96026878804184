import  CcpProvider  from '@starcoders/ccp-provider/dist/agent'
import * as Config from 'core/config'

export let ccpProvider = {}
export const initProvider = () => {
  ccpProvider =  new CcpProvider({
    config: {
      api: Config.get('api'),
    },
    websocketConfig: {
      url: Config.get('wsUrl'),
    },
  }, false)
  return ccpProvider
}
