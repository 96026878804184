import React from 'react'
import {Route, Switch} from 'react-router-dom'
import AgentList from '../components/Agent/AgentList'
import AgentEdit from '../components/Agent/AgentEdit'
import TabSwitcher from '../components/Admin/TabSwitcher'

@connect()
class Admin extends React.Component {
  async componentDidMount() {
    await this.props.actions.agent.listObjectives()
    await this.props.actions.agent.listWhiteLabels()
  }

  render() {
    return (
      <div className="agents-container">
        <Switch >
          <Route path='/admin/agents' component={AgentList} exact/>
          <Route path='/admin/agents/create' component={AgentEdit} exact/>
          <Route path='/admin/agents/edit/:userId' component={AgentEdit}/>
          <Route path='/admin/conversations' component={TabSwitcher} exact/>
          <Route path='/admin/conversations/:conversationUid' component={TabSwitcher}/>
          <Route path='/admin/agentMessages' component={TabSwitcher}/>
          <Route path='/admin/stats' component={TabSwitcher} exact/>
          <Route path='/admin/agentStats' component={TabSwitcher}/>
          <Route path='/admin/agentSessions' component={TabSwitcher}/>
        </Switch>
      </div>
    )
  }
}

export default Admin
