import React from 'react'
import ConversationsTable from '../Conversations/ConversationsTable'
import ConversationDetail from '../Conversations/ConversationDetail'
import { withRouter } from 'react-router-dom'

@connect()
@boundary()
class ConversationsContainer extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      showConversationDetail: false,
    }
  }


  componentWillMount() {
    this.renderSubPage(this.props.match.path)
  }

  componentWillReceiveProps(nextProps) {
    const { match } = this.props
    if(match.path !== nextProps.match.path) {
      this.renderSubPage(nextProps.match.path, nextProps.match.params.conversationUid)
    }
  }

  renderSubPage = (location, conversationUid) => {
    switch (location) {
      case '/admin/conversations':
        this.setState({ ...this.state, showConversationDetail: false })
        break
      case '/admin/conversations/:conversationUid':
        this.setState({ ...this.state, showConversationDetail: true, conversationUid: conversationUid || this.props.match.params.conversationUid })
        break
    }
  }

  render() {
    return(
      <div>
        <h1>Conversation logs</h1>
        { this.state.showConversationDetail ?
          <ConversationDetail conversationUid={this.state.conversationUid}/> : <ConversationsTable/>
        }

      </div>
    )
  }
}

export default withRouter(ConversationsContainer)
