import React, { useState } from 'react'
import PropTypes from 'prop-types'
import UserInfo from './elements/UserInfo'
import InfoModal from './InfoModal'

const Impersonator = ({ conversation }) => {
  const [showInfoModal, setShowInfoModal] = useState(false)

  const toggleInfoModal = () => setShowInfoModal(!showInfoModal)

  const { meProfile, identifier } = conversation

  return (
    <>
      <UserInfo
        conversationUid={conversation.conversationUid}
        user={'me'}
        profile={meProfile}
        action={toggleInfoModal}
        showUsername={true}
      />
      {showInfoModal && <InfoModal user="me" userUid={conversation.otherUserUid} apUid={conversation.meUid} toggleInfoModal={toggleInfoModal} showInfoModal={showInfoModal} identifier={identifier}/>}
    </>
  )
}

Impersonator.propTypes = {
  conversation: PropTypes.object,
}

export default Impersonator

