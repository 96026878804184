import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { MessageWrapper } from 'styles/common'

const TITLES = {
  block: 'Account was blocked',
  blockCredit: 'Account was blocked (out of credits)',
  blockRebill: 'User blocked due to disabling recurring',
  contactInfoDetected: 'User contact info detected message was sent to user.',
  downgrade: 'Premium status of account ended.',
  offline: 'User is now offline.',
  online: 'User is now online.',
  rebillDisabled: 'User disabled rebill',
  rebillEnabled: 'User enabled rebill',
  unblock: 'Account was unblocked',
  unblockRebill: 'User unblocked due to enabling recurring',
  upgrade: 'Account was upgraded.',
  userDeleted: 'User deleted',
  userRestored: 'User restored',
}

const COLORS = {
  block: { backgroundColor: '#f45f42', color: 'white' },
  blockCredit: { backgroundColor: '#f45f42', color: 'white' },
  blockRebill: { backgroundColor: '#f45f42', color: 'white' },
  contactInfoDetected: {backgroundColor: '#f45f42', color: 'white'},
  downgrade: { backgroundColor: '#bcc0c6', color: '#222' },
  offline: { backgroundColor: '#4083ca', color: 'white' },
  online: { backgroundColor: '#4083ca', color: 'white' },
  rebillDisabled: { backgroundColor: '#7a1479', color: 'white' },
  rebillEnabled: { backgroundColor: '#c224c1', color: 'white' },
  upgrade: { backgroundColor: '#ffed77', color: '#222' },
  unblock: { backgroundColor: '#70a34f', color: 'white' },
  unblockRebill: { backgroundColor: '#70a34f', color: 'white' },
  userDeleted: { backgroundColor: '#c20002', color: 'white' },
  userRestored: { backgroundColor: '#007a00', color: 'white' },
}

const Notification = styled.div`
  line-height: 2em !important;
  margin: 10px 0;
  padding: 8px;
  border-radius: 5px;
  width: 50%;

  background-color: ${props => COLORS[props.type].backgroundColor || '#ffffff'};
  color: ${props => COLORS[props.type].color || '#000000'};
`

const MessageTitle = styled.h5`
  margin: 0;
  font-size: 1.1em;
  text-decoration: underline;
`

const MessageTime = styled.p`
  margin: 5px;
`

const SystemNotification = ({ message })  => {

  const timeConverter = (UNIX_timestamp) => {
    const a = new Date(UNIX_timestamp * 1000)
    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    const year = a.getFullYear()
    const month = months[a.getMonth()]
    const date = a.getDate()
    const hour = a.getHours()
    const min = a.getMinutes().toString().padStart(2, '0')
    const sec = a.getSeconds().toString().padStart(2, '0')
    return `${date} ${month} ${year} ${hour}:${min}:${sec}`
  }

  return (
    <MessageWrapper>
      <Notification className={`message-type-notification message-type-${message.message}`} type={message.message}>
        <MessageTitle>{ TITLES[message.message] }</MessageTitle>
        <MessageTime>{ timeConverter(message.sentAt) }</MessageTime>
      </Notification>
    </MessageWrapper>
  )
}

SystemNotification.propTypes = {
  message: PropTypes.shape({
    message: PropTypes.string,
    sentAt: PropTypes.number,
  }).isRequired
}

export default SystemNotification


