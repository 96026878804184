import { get, isNil, omitBy, size } from 'lodash'
import { getSessionToken, removeSessionToken } from '../src/actions/Auth'
import sessionxtd from 'core/sessionxtd'
import { simpleHash } from 'core/helpers'
import * as Config from 'core/config'
import axios from 'axios'

const logOutCodes = [
  'loginRequired',
  'sessionNotFound'
]

const httpErrorsNotToLog = [
  401,
  403,
  404,
]

export default {

  get(url, params, opts) {
    return buildRequest('get', url, params, opts)
  },

  post(url, data, opts) {
    return buildRequest('post', url, data, opts)
  },
}

/**
 * Build and execute remote request
 * @param method
 * @param url
 * @param params
 * @param opts
 */
async function buildRequest(method, endpoint, params, opts = {}) {
  const url = `${Config.get('api')}${endpoint}`
  const headers = {
    'content-type': 'application/json',
    'x-auth-token': getSessionToken().token,
  }
  let cacheKey = ''

  if (opts.hasOwnProperty('safeMode')) {
    headers['x-safemode'] = opts.safeMode
  }
  if (opts.xAuthToken) {
    headers['x-auth-token'] = opts.xAuthToken
  }
  if (opts.identifier) {
    headers['x-identifier'] = opts.identifier
  } else if (!opts.identifier && Config.get('identifier')) {
    headers['x-identifier'] = Config.get('identifier')
  }

  //check if request is in sessionStorage
  if(opts.cache) {
    cacheKey = generateCacheKey(url, params)

    const response = sessionxtd.getItem(cacheKey)
    if(response) {
      return { result: response, cache: true }
    }
  }

  const request = {
    method,
    headers,
    mode: 'cors',
    credentials: 'omit', // omit | include
    cache: 'default',
    ...(method === 'get' ? { params } : {}),
    ...(method === 'post' ? { data: params } : {}),
  }
  if (method === 'POST' && size(params)) {
    request.body = JSON.stringify(params || {})
  }

  let response
  let status

  try {
    response = await axios(url, request)
    status = response.status
    if (status >= 403) {
      if (logOutCodes.includes(response.error.code)) {
        removeSessionToken()
      }
      throw (response.error || response)
    }

    const { result } = response.data || {}
    if (result !== undefined) {
      if(opts.cache) {
        //store response in sessionStorage
        sessionxtd.setItem(cacheKey, response.result, opts.expiry || 60 * 60 * 1000)
        return { result: response.result, cache: false }
      }
      return response.data
    } else if (response.error) {
      throw response.error
    } else {
      throw response
    }

  } catch(error) {
    if(!httpErrorsNotToLog.includes(status)) {
      logServiceError(error, url, status, request.body)
    }
    throw error
  }
}

/**
 * Generates a key consisting of the request url and unique hash based on the userUid and request parameters
 * @param {string} url : request url
 * @param {object} params : request params
 * @returns {string} : Example: 'ccp/userData/getUserPictures:-614443294'
 */
export function generateCacheKey(url, params) {
  return url.split('/').splice(-3).join('/')
    + ':'
    + simpleHash(`${Config.get('userUid', '')}${JSON.stringify(params || {})}`)
}

export function logServiceError(error, endpoint, statusCode, body, headers) {
  let message = omitBy({ error, endpoint, body }, isNil)
  if (error instanceof Error) {
    message = JSON.parse(JSON.stringify(message, Object.getOwnPropertyNames(error)))
  }
  window._LTracker.push({
    correlationId: get(headers, 'x-amzn-requestid'),
    interface: window._LTracker.interface,
    tag: window._LTracker.tag,
    endpoint,
    statusCode,
    response: message,
  })
}

/**
 * Transform an JSON object to a query string
 * @param params
 * @returns {string}
 */
export function toQueryString(params) {
  return '?' + Object.keys(params).map(k => {
    const name = encodeURIComponent(k)
    if (Array.isArray(params[k])) {
      return `${name}=${encodeURIComponent(params[k].join(','))}`
    }
    return `${name}=${encodeURIComponent(params[k])}`
  }).join('&')
}

/**
 * Transforms query string to object
 * @param url
 * @returns {{}}
 */
export function getQueryStringParams(url) {
  let queryString = url.split('?')[1]
  const result = {}

  if (queryString) {
    queryString = queryString.split('#')[0]

    let queryParamsArray = queryString.split('&')

    for(let queryParam of queryParamsArray) {
      let [key, value] = queryParam.split('=')
      const isArray = key.substring(key.length - 2) === '[]'
      const arrayKey = isArray ? key.substring(0, key.length - 2) : null

      if(value === 'true') {
        assign(true)
      }else if(value === 'false') {
        assign(false)
      }else if(!isNaN(isInt(value))) {
        assign(parseInt(value))
      }else {
        assign(decodeURIComponent(value))
      }

      function assign(v) {
        if(!isArray) {
          result[key] = v
        } else if(result[arrayKey]) {
          result[arrayKey].push(v)
        } else {
          result[arrayKey] = [v]
        }
      }
      function isInt(v) {
        if (/^[-+]?(\d+|Infinity)$/.test(v)) {
          return Number(v)
        } else {
          return NaN
        }
      }
    }
  }
  return result
}
