import styled from 'styled-components'

export const MessageWrapper = styled.div`
  line-height: 8px;
  margin-bottom: 0.4rem;
  overflow-wrap: break-word;
  word-break: break-word;
  -ms-word-wrap: break-word;
  white-space: normal;
  position: relative;
  clear: both;
`
