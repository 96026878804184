import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { MessageWrapper } from 'styles/common'

const UpgradeRequest = styled(MessageWrapper)`
  border: 1px solid #E62348;
  text-align: left;
`

const Header = styled.p`
  font-size: 1.5em;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  background-color: #E62348;
  border-radius: 5px;
  padding: 7px;
  text-align: center;
`

const MessageWarningOtherUserShow = ({message}) => {
  return (
    <p>
      An attempt to exchange contact info by the other user was detected and blocked, the original message sent was:<br/><br/>
      <i>{message.message}</i>
    </p>
  )
}

class MessageWarning extends React.Component {
  render() {
    const { message } = this.props
    return (
      <UpgradeRequest>
        <Header><b>Contact info detected!</b></Header>
        <MessageWarningOtherUserShow message={message}/>
      </UpgradeRequest>
    )
  }
}


MessageWarning.propTypes = {
  message: PropTypes.shape({
    message: PropTypes.string,
    sentAt: PropTypes.number,
  }).isRequired,
}

export default MessageWarning


