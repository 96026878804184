import React from 'react'
import { renderToString } from 'react-dom/server'
import { isArray, isObject } from 'lodash'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import emoji from 'react-easy-emoji'
import { isNotSentId } from 'core/helpers'

class MessageDefault extends React.Component {

  getMessage = () => {
    const { message } = this.props
    if (message.type === 'sexicon' || message.type === 'gift') {
      return "<img src='" + message.message + "'/>"
    }

    if(message.message && isArray(emoji(message.message))) {
      return emoji(message.message || '').map( m => {
        if(isObject(m)) {
          return renderToString(m)
        }
        return m
      }).join(' ')
    }

    return message.message || ''
  }

  render() {
    const { message, belongsToOtherUser, history } = this.props
    return (
      <div
        className={classnames('message-wrapper', {
          sent: !belongsToOtherUser,
          received: belongsToOtherUser,
          pending: message.id === 9999999999,
          notsent: isNotSentId(message.id),
          history: history,
        })}
        data-id={message.sentAt}>
        <MessageMeta { ...this.props }/>
        <div className='chat-message emoji-wrapper' dangerouslySetInnerHTML={{ __html: this.getMessage()}}>
        </div>
      </div>
    )
  }
}

export const MessageBasic = ({ children, time, user, sent, pending, failed, type }) => (
  <div className={classnames('message-wrapper', {sent, received: !sent, pending, failed})}>
    <span className="identity-span">{user.username}</span>
    <div className={`chat-message ${type || ''}`}>{children}</div>
    <span className="time-span">{failed ? 'not sent' : time}</span>
  </div>
)

function MessageMeta({message, belongsToOtherUser, otherUserName, history}) {
  if(isNotSentId(message.id)) {
    return <span className='identity-span you-span notsent'>&#9888; not sent</span>
  }
  else if(belongsToOtherUser) {
    return <span className='identity-span they-span'>{otherUserName} <span className='time-span'>{history === true ? timeConverter(message.sentAt) : timeSince(message.sentAt * 1000)}</span> </span>
  }
  else {
    return <span className='identity-span you-span'><span className='time-span'>{history === true ? timeConverter(message.sentAt) : timeSince(message.sentAt * 1000)}</span> You</span>
  }
}

MessageDefault.propTypes = {
  belongsToOtherUser: PropTypes.bool.isRequired,
  otherUserName: PropTypes.string,
  message: PropTypes.shape({
    id: PropTypes.number,
    data: PropTypes.string,
    sentAt: PropTypes.number,
  }).isRequired
}

export default MessageDefault

// -- Helpers

function timeSince(unixTimestamp) {
  const current = parseInt(Date.now() / 1000)
  const timestamp = parseInt(unixTimestamp / 1000)
  const seconds = Math.floor(current - timestamp)

  let interval = Math.floor(seconds / 31536000)
  if (interval > 1) {
    return `${interval} years ago`
  }
  interval = Math.floor(seconds / 2592000)
  if (interval > 1) {
    return `${interval} months ago`
  }
  interval = Math.floor(seconds / 604800)
  if (interval >= 1) {
    return `${interval} weeks ago`
  }
  interval = Math.floor(seconds / 86400)
  if (interval === 1) {
    return `yesterday`
  }
  if (interval >= 2) {
    return `${interval} days ago`
  }
  interval = Math.floor(seconds / 3600)
  if (interval >= 1) {
    return `${interval} hours ago`
  }
  interval = Math.floor(seconds / 60)
  if (interval > 1) {
    return `${interval} minutes ago`
  }
  return 'just now'
}

function timeConverter(UNIX_timestamp) {
  const a = new Date(UNIX_timestamp * 1000)
  const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  const year = a.getFullYear()
  const month = months[a.getMonth()]
  const date = a.getDate()
  const hour = a.getHours()
  const min = a.getMinutes().toString().padStart(2, '0')
  const sec = a.getSeconds().toString().padStart(2, '0')
  return `${date} ${month} ${year} ${hour}:${min}:${sec}`
}
