import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { isEmpty } from 'lodash'
import EmojiPicker from './EmojiPicker/EmojiPicker'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'
import {wait} from "core/helpers"
import {MESSAGE_TYPE_IMAGE, MESSAGE_TYPE_SEXICON, MESSAGE_TYPE_GIFT} from "core/constants"
import actions from "../../actions"
import SexIconPicker from "./EmojiPicker/SexIcons"
import Gifts from "./EmojiPicker/Gifts"
import request from 'core/request'
import { GET_WHITELABEL } from 'core/endpoints'
import { pictureLink } from 'core/helpers'

const Container = styled.div`
  position: relative;
  display: flex;
  margin: 0.2em 0;
  align-items: center;
`
const InputContainer = styled.div`
  display: flex;
  flex: 1;
  input {
    font-size: 1.1rem;
    border: solid 1px #bcbcbc;
    appearance: auto;
    flex: 1;
  }
  .button {
    border-radius: 0;
  }
`
const EmojiPickerContainer = styled.div`
  .emoji-wrapper {
    border: solid 1px lightblue;
    background: #feffff;
    margin: 0.5em;
    padding: 0.2em;
  }
`
const ImagePreviewContainer = styled.div`
  position: relative;
  img {
    max-height: 90px;
  }
  .remove.icon {
    color: red;
    position: absolute;
    right: 3px;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    background: white;
    padding: 3px;
    border-radius: 60px;
    line-height: 15px;
    border: 1px solid black;
  }
`
const ChatInput = ({ conversation = () => {}, pendingImageMessage, setPendingImageMessage }) => {
  const [input, setInput] = useState('')
  const [typing, setTyping] = useState(false)
  const [loading, setLoading] = useState(false)
  const [giftsFeatureSwitch, setGiftsFeatureSwitch] = useState([])
  const inputRef = useRef(null)
  const previewImageContainer = useRef()

  const fetchFeatureSwitch = async() => {
    request.get(GET_WHITELABEL, {whiteLabelUid: conversation.identifier}).then((result) => {
      setGiftsFeatureSwitch(result.result?.featureSwitches?.gifts)
    })
  }

  useEffect(() => {
    if (pendingImageMessage.image) {
      previewImageContainer.current.style.display = 'block'
      setInput('')
      inputRef.current.disabled = true
    } else {
      previewImageContainer.current.style.display = 'none'
      inputRef.current.disabled = false
    }
  },[pendingImageMessage])

  useEffect(() => {
    fetchFeatureSwitch()
  }, [conversation.id])

  const previewImageStyle = {
    display: 'none'
  }

  useEffect(() => {
    setInput('')
    setPendingImageMessage({})
  }, [conversation.id])

  const handleSubmit = async(e) => {
    e.preventDefault()
    if (isEmpty(input)) {
      if (pendingImageMessage.message) {
        setLoading(true)
        stopTypingIndicator()
        return wait(150).then(() => {
          if (pendingImageMessage.message === 'gif-message') {
            conversation.send(pendingImageMessage.image, MESSAGE_TYPE_SEXICON)
          } else if (pendingImageMessage.message === 'gifts-message') {
            conversation.send(pendingImageMessage.image, MESSAGE_TYPE_GIFT)
          } else {
            const imgMessage = pictureLink(pendingImageMessage.message.message, conversation.conversationUid, conversation.identifier)
            conversation.send(imgMessage, MESSAGE_TYPE_IMAGE)
          }
            actions.chat.updateConversationCache(conversation)
            setPendingImageMessage({})
            setLoading(false)
        })
      }

      return
    }
    setLoading(true)
    stopTypingIndicator()
    await conversation.send(input)
    setLoading(false)
    setInput('')
    inputRef.current.focus()
  }

  const handleRemoveImage = () => {
    setPendingImageMessage({})
    previewImageContainer.current.style.display = 'none'
  }

  const handleEmojiSelect = (emoji) => {
    setInput(input
      ? `${input} ${emoji.unicode} `
      : emoji.unicode)
  }

  const handleChange = async(e) => {
    setInput(e.target.value)
    if (input.length === 0 && conversation.lastMessageSentAt > 0 && conversation.meMessageCount > 1) {
      stopTypingIndicator()
    }
    else if (typing && conversation.lastMessageSentAt > 0 && conversation.meMessageCount > 1) {
      conversation.sendTypingIndicator(input.length)
      setTyping(false)
      setTimeout( () => setTyping(true), 1500)
    }
  }

  const stopTypingIndicator = async() => {
    if(conversation.lastMessageSentAt > 0 && conversation.meMessageCount > 1) {
      await conversation.sendTypingIndicator(0)
      setTyping(true)
    }
  }

  const handleSexIconSelect = (item) => {
    setPendingImageMessage({message: 'gif-message', image: item.src})
  }

  const handleGiftSelect = (item) => {
    setPendingImageMessage({message: 'gifts-message', image: item})
  }

  return (
    <form className="" onSubmit={handleSubmit}>
      <Container className="">
        <EmojiPickerContainer className="">
          <EmojiPicker onClickEmoji={handleEmojiSelect} disabled={conversation.sendingMessage || conversation.userDeleted}/>
        </EmojiPickerContainer>
        <SexIconPicker onClickGif={handleSexIconSelect}/>
        { giftsFeatureSwitch && <Gifts onClickGif={handleGiftSelect} conversation={conversation}/> }
        <InputContainer className="">
          <input
            type="text"
            name="message"
            placeholder="Type a message"
            disabled={conversation.sendingMessage || conversation.userDeleted}
            onChange={handleChange}
            value={input}
            ref={inputRef}
          />
          <ImagePreviewContainer ref={previewImageContainer} style={previewImageStyle} className="image-preview">
            <img src={pendingImageMessage?.image}/>
            <span onClick={handleRemoveImage} className="remove icon">X</span>
          </ImagePreviewContainer>
          <Button color="facebook" onClick={handleSubmit} disabled={conversation.sendingMessage || conversation.userDeleted } loading={loading}>Send</Button>
        </InputContainer>
      </Container>
    </form>
  )
}

ChatInput.propTypes = {
  conversation: PropTypes.object,
}

export default observer(ChatInput)
