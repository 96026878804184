import React, { useState, useEffect } from 'react'
import { inject } from 'mobx-react'
import { omit, set } from 'lodash'
import styled from 'styled-components'
import { Button, Form } from 'semantic-ui-react'
import { Notification } from './Notification'
import { SettingSection } from './SettingSection'

const SETTING_KEYS = ['agents', 'moderators', 'standard']
const SettingsContainer = styled.div``
const SettingContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const Settings = inject('actions')(({ actions }) => {
  const [settings, setSettings] = useState({})
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)

  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    setLoading(true)
    Promise.all(SETTING_KEYS.map(k => actions.admin.getSettings(k)))
      .then(response => {
        const orderSettings = x => Object.keys(x).sort().reduce((s, k) => ({ ...s, [k]: x[k] }), {})

        setSettings(response.reduce(( settingObj, [result], index) => ({
          ...settingObj,
          [SETTING_KEYS[index]]: orderSettings(omit(result, ['key'])) })
          , {})
        )
        setLoading(false)
      })
  }, [])

  const onSubmit = async() => {
    setSubmitting(true)
    const successfulUpdate = x => {
      setSuccess(x)
      setError(!x)
    }

    const response = await Promise.all(SETTING_KEYS.map(k =>
      actions.admin.updateSettings({ key: k, value: Object.entries(settings[k]).reduce((o, [key, { value }]) => ({ ...o, [key]: value }), {}) })))
    setSubmitting(false)

    if(!response.every(([, err]) => !err)) {
      successfulUpdate(false)
    }else {
      successfulUpdate(true)
    }

    setTimeout(() => {
      setSuccess(false)
      setError(false)
    }, 3000)
  }

  const onChange = (settingKey, settingName, settingValue) => {
    setSettings(set({ ...settings }, `${settingKey}.${settingName}`, settingValue))
  }

  return (
    <SettingsContainer>
      <h2>Settings</h2>
      <Notification state={(success && 'success') || (error && 'error')}/>
      <Form loading={loading} onSubmit={onSubmit} success={success}>
        <SettingContainer>
          { Object.keys(settings).length > 0 && Object.entries(settings).map(([sectionName, entries], key) =>
            <SettingSection key={key} name={sectionName} entries={entries} onChange={onChange}/>)}
        </SettingContainer>
        <Button type='submit' loading={submitting}>Update</Button>
      </Form>
    </SettingsContainer>
  )
})
