import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { FixedSizeList as List } from 'react-window'
import { useElementSize } from 'core/hooks'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router'
import { MoreConversationsButton } from './MoreConversationsButton'
import { Conversation } from './Conversation'
import { useState } from 'react'
import { HideConversationsBtn } from '../../elements/HideButton'

const ConversationsContainer = styled.div`
  position: relative;
  background: #EAF1F7FF;
  width: 180px;
  border-right: solid 1px lightblue;
`
const ConversationListContainer = styled.div`
  overflow: hidden;
`

export const Conversations = inject('store', 'actions')(observer(({ store, actions }) => {

  const conversations = store.conversationsSorted.filter(showConversation)
  const conversationUid = store.conversationUid

  const [hideChats, setHideChats] = useState(false)
  const [conversationsContainerSize, setConversationsContainer] = useElementSize()
  const [moreConversationsBtnSize, setMoreConversationsBtnSize] = useElementSize()
  const [filteredUsers, setFilteredUsers] = useState([])
  const [itemsCount, setItemsCount] = useState(0)
  const [toggleInput, setToggleInput] = useState(true)
  const history = useHistory()
  const urlParams = useParams()
  const handleHide = () => {
    setHideChats(prevState => !prevState)
  }

  const handleSearchUser = event => {
    const string = event.target.value
    let filtered
    if (string.length > 0) {
      filtered = conversations.filter(user =>
        user.otherUserProfile.username.toLowerCase().includes(string.toLowerCase())
      )
    } else {
      setFilteredUsers([])
      setItemsCount(conversations.length)
    }

    setFilteredUsers(prevFiltered => {
      if (filtered && prevFiltered.length !== filtered.length) {
        setItemsCount(filtered.length)
      }
      return filtered || prevFiltered
    })
  }

  const openConversation = async(conv) => {
    const activeConversation = store.conversations.find(c => c.conversationUid === store.conversationUid)
    if (activeConversation && activeConversation.reclaimed) {
      activeConversation.detachConversation()
    }

    actions.chat.setConversation(conv.conversationUid, store.conversationUid)
    actions.chat.readConversation(conv)
    store.conversation.fetchMessages()
    history.push(`/chat/${conv.conversationUid}`)
  }

  if (conversationUid && !urlParams.conversationUid) {
    history.replace({ pathname: `/chat/${conversationUid}` })
  }

  useEffect(() => {
    if (conversations.length > 0) {
      setItemsCount(conversations.length)
      setToggleInput(false)
    }
  }, [conversations.length])

  return (
    <ConversationsContainer ref={setConversationsContainer} className={`${hideChats ? 'conv-hidden' : ''}`}>
      <MoreConversationsButton ref={setMoreConversationsBtnSize}/>
      <ConversationListContainer>
        <div className='search-wrapper'>
          <div className='icon-search-chats'></div>
          <input className='search-conversations' onChange={handleSearchUser} disabled={toggleInput} placeholder='Search...'></input>
        </div>
        <List
          className='minimal-scrollbar'
          innerElementType="div"
          itemCount={itemsCount}
          itemSize={50}
          height={conversationsContainerSize.height - moreConversationsBtnSize.height}
          width={conversationsContainerSize.width}
        >
          {({ index, style }) => {
            return (
              <Conversation openConversation={openConversation} conversation={filteredUsers.length > 0 ? filteredUsers[index] : conversations[index]} style={style}/>
            )
          }}
        </List>
      </ConversationListContainer>
      <HideConversationsBtn className={`${hideChats ? 'conv-btn-right' : 'conv-btn-left'} hide-menu-btn hide-chats-btn`} onClick={() => handleHide()}/>
    </ConversationsContainer>
  )
}))

function showConversation(conv) {
  if ((conv.lastMessageSentAt === 0 || conv.otherUserLastSentAt === 0) && conv.createdAt) {
    return true
  }
  return conv.lastMessageSentAt != 0
}
