import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'
import { observer } from 'mobx-react'
import { get } from 'lodash'
import React from 'react'
import ConversationTime from './ConversationTime'
import { InteractionIcon } from '../elements/Interaction'

const AvatarContainer = styled.div`
  position: relative;
  margin: 0.3em;
  &:last-child {
    margin-right: 1em;
  }
`
const MessageCounter = styled.div`
  border-radius: 10px;
  font-size: ${props => props.size === 'small' ? '0.6em' : '0.8em' };
  width: 20px;
  height: 20px;
  text-align: center;
  position: absolute;
  ${props => props.position === 'top' ? 'top: 15px' : 'bottom: 0px'};
  right: -4px;
  
  background: ${props => props.color};
  color: #fff;
`
const PremiumStarContainer = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  
  font-size: 1.3em;
  color: #fed74d;
`
const UserImage = styled.img`
  height: 2.5em;
  width: 2.5em;
  border-radius: 50%;
`
const Avatar = ({ profileImage, username, messageCount, isPremium, messageBackgroundColor }) => {
  return (
    <AvatarContainer>
      <UserImage src={profileImage} alt={username}/>
      {messageCount > 0 && <MessageCounter  color={messageBackgroundColor} size={messageCount/100 >= 1 ? 'small' : ''} position={!isPremium && 'top'}>{messageCount}</MessageCounter>}
      {isPremium === 1 && <PremiumStarContainer><Icon name="star"/></PremiumStarContainer>}
    </AvatarContainer>
  )
}

const Interaction = observer(({ conversation }) => {
  const { matched, meLiked, otherUserLiked } = conversation.interactions
  return (<InteractionIcon
    matched={matched}
    meLiked={meLiked}
    otherUserLiked={otherUserLiked}
  />)
})

const ConversationContainer = styled.div`
  padding: 0.4em 0.2em 0.2em 0.4em;
  font-size: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  transition: background-color 200ms ease-out;
  
  background: ${({remainingMessages}) =>
    remainingMessages >= 50 && '#E8EEF5' || 
    remainingMessages === 0 && '#E8EEF5' || // ex-premium user with 0 credits
    remainingMessages >= 20 && '#FFF8DC' ||
    remainingMessages > 0 && '#FCD7D7' ||
    '#fff' // everything else color in white
  };
  
  border: ${({isNewAttached}) => 
    isNewAttached && '2px solid #90EE90'
  };
`
const TimerContainer = styled.div`
  margin-right: auto;
`
export const Conversation = observer(({ conversation, style, openConversation = () => {} }) => {
  if (!conversation) {
    return null
  }

  let remainingMessages
  if (conversation.isCreditSite) {
    remainingMessages = conversation.remainingMessages
  } else {
    // no credit site, meaning user is either from subscription site or free -> color white
    remainingMessages = -1
  }
  const isNewAttached = !!conversation.isNewAttached
  return (
    <ConversationContainer key={conversation.conversationUid} onClick={() => openConversation(conversation)}
                           style={style} remainingMessages={remainingMessages} isNewAttached={isNewAttached}>
      <TimerContainer>
        <ConversationTime conversation={conversation}/>
      </TimerContainer>
      <Interaction conversation={conversation}/>
      <Avatar profileImage={get(conversation, 'meProfile.profileImage')}
              username={get(conversation, 'meProfile.username')} messageCount={conversation.meMessageCount}
              messageBackgroundColor="green"/>
      <Avatar profileImage={get(conversation, 'otherUserProfile.profileImage')}
              username={get(conversation, 'otherUserProfile.username')} messageCount={conversation.unreadByMeCount}
              messageBackgroundColor="red" isPremium={conversation.otherUserProfile.isPremium}/>
    </ConversationContainer>
  )
})
