import React from 'react'
import {Container, Header, Icon} from 'semantic-ui-react'
import LoginForm from './LoginForm'

const LoginPage = (props) => {
  const successRedirect = () => {
    props.history.push('/')
  }

  return (
    <Container style={{ width: '30rem', marginTop: '2rem' }}>
      <Header as='h2'>
        <Icon name='signup'/>
        <Header.Content>
          Login
          <Header.Subheader>
            CCP Interface
          </Header.Subheader>
        </Header.Content>
      </Header>
      <LoginForm successRedirect={successRedirect}/>
    </Container>
  )
}

export default connect()(LoginPage)
