import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { observer } from 'mobx-react'
import { useInterval } from 'core/hooks'

const ConversationTime = (observer(({ conversation }) => {
  const [duration, setDuration] = useState(-1)

  useInterval(() => {
    if(duration >= 0  ) {
      setDuration(duration + 1000)
    }
  }, 1000)

  useEffect(() => {
    if (conversation.otherUserLastSentAt && conversation.otherUserLastSentAt === conversation.lastMessageSentAt) {
      // unanswered by agent conversation
      setDuration((moment().diff(moment(conversation.otherUserLastSentAt * 1000))))
    } else {
      setDuration(-1)
    }
  },[conversation.otherUserLastSentAt, conversation.lastMessageSentAt])

  if(duration < 0) {
    return null
  }

  return <div>{getTimespan(duration)}</div>
}))

export default React.memo(ConversationTime)

function getTimespan(durationMs) {
  const duration = moment.duration(durationMs)
  const approxDays = duration.asDays()
  if (approxDays > 1) {
    const days = parseInt(approxDays)
    return `${days} day${days > 1 ? 's' : ''}`
  }
  const timespan = [
    duration.hours().toString().padStart(2, '0'),
    duration.minutes().toString().padStart(2, '0'),
    duration.seconds().toString().padStart(2, '0'),
  ]
  return timespan.join(':')
}
