import { inject, observer } from 'mobx-react'
import React, { useRef, useState } from 'react'
import { Form } from 'semantic-ui-react'
import styled from 'styled-components'
import AgentsListDropdown from '../elements/AgentsListDropdown'
import ChatControls from './ChatControls'
import ChatWidget from './ChatWidget'
import GoalView from './GoalView'
import IconLegend from './IconLegend'
import TransferAgentModal from './modals/TransferAgentModal'

import * as mediaQueries from 'styles/mediaQueries'

const InfoActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media ${mediaQueries.retina} {
    flex-direction: row;
    align-items: start;
    justify-content: space-around;
  }
`

const ChatInfoContainer = styled.div`
  overflow: auto;
`

const TransferAgentContainer = styled.div`
  padding: 10px 10px;
  border: solid 1px lightgray;
  margin: 20px 10px;
  border-radius: 4px;
`

const ChatWindowAgent = ({ store, actions }) => {
  require('./stylesAgent') //gets loaded into chat-widget page for no reason otherwise

  const { isCreditSite = false } = store.conversation || {}
  const { isPremium = false, rebillEnabled = false, expiryDate = null, credits } = store.conversation ? store.conversation.otherUserProfile : {}

  const infoContainerRef = useRef(null)
  const chatWindowRef = useRef(null)
  const [transfer, setTransfer] = useState({})
  const [selectedOption, setSelectedOption] = useState('')
  const [isTransferredConversation, setIsTransferredConversation] = useState(false)
  const [pendingImageMessage, setPendingImageMessage] = useState({message: {}, image: ''})

  if (!store.conversation) {
    return (
      <div className="chat-window-agent">
        <div className="chat-widget" style={{ padding: '1rem' }}>
          {isTransferredConversation ? `Conversation was transferred to Agent ${transfer.agentName}! `: ''}
          {store.conversations.length === 0 ? ('Loading conversations... If this takes too long, there may be no active conversations.') : ('Choose a conversation from the left panel')}
        </div>
      </div>
    )
  }

  const onDropdownChange = (e, { value }) => {
    setIsTransferredConversation(false)
    const agentName = store.agents.find(agent => agent.id == value).name
    const capitalizedName = agentName.charAt(0).toUpperCase() + agentName.slice(1)
    setTransfer({
      conversationId: store.conversation.id,
      agentId: parseInt(value),
      agentName: capitalizedName,
    })
    setSelectedOption(value)
  }

  const clearSelectedOption = () => {
    setSelectedOption('')
  }

  const successfulTransferredConversation = () => {
    setIsTransferredConversation(true)
  }

  return (
    <div className="chat-window-agent" ref={el => {chatWindowRef.current = el}}>
      <ChatWidget
        conversation={store.conversation}
        pendingImageMessage={pendingImageMessage}
        setPendingImageMessage={setPendingImageMessage}
      />
      {store.conversation.reclaimed? <div className='reclaimed-alert'>This conversation's original agent has returned. Once you open another conversation, this conversation will be removed from your queue.</div> : null}
      <ChatInfoContainer className='minimal-scrollbar'>
        <InfoActionContainer ref={infoContainerRef}>
          <ChatControls pendingImageMessage={pendingImageMessage} setPendingImageMessage={setPendingImageMessage}/>
          <GoalView isPremium={isPremium} rebillEnabled={rebillEnabled} objectives={store.account.objectives}/>
          <TransferAgentContainer>
            <h4>Transfer conversation to another agent</h4>
            <Form>
              <Form.Group widths="equal">
                <AgentsListDropdown
                  name="agentIds"
                  value={selectedOption}
                  onChange={onDropdownChange}
                  actions={actions}
                  transfer={{currentAgentId: store.account.id, placeholder: 'Choose an agent', label: '', style: {width: '20rem'}}}/>
              </Form.Group>
            </Form>
            <TransferAgentModal transfer={transfer} isSelectedOption={!!selectedOption} modalActions={{clearSelectedOption, successfulTransferredConversation}}/>
          </TransferAgentContainer>
        </InfoActionContainer>
        <IconLegend/>
      </ChatInfoContainer>
    </div>
  )
}

export default inject('store', 'actions')(observer(ChatWindowAgent))
