import React from 'react'
import storage from 'core/storage'
import { Dropdown } from 'semantic-ui-react'

const serverEnvironments = [
  { key: 'staging', value: 'staging', text: 'Staging' },
  { key: 'test', value: 'test', text: 'Test' },
  { key: 'dev', value: 'dev', text: 'Dev' },
  { key: 'mocks', value: 'mocks', text: 'Mocks (/api)' },
]

const STORAGE_KEY = 'SERVER_ENV'

class EnvSelector extends React.Component {

  state = {
    serverEnv: storage.get(STORAGE_KEY, 'staging'),
  }

  componentDidMount() {
    // Code inside this check is stripped off when on production
    if (process.env.CI_JOB_NAME !== 'production-deploy' && "$DEPLOY_ENV" !== 'production') {
      switch(this.state.serverEnv) {
        case 'staging':
          window.CCP_CONF = {userUid: "99025b64-327d-5e19-89e0-eb3c81ed66ba", userToken: "pu:99025b64-327d-5e19-89e0-eb3c81ed66ba:d5810d205d1c2f109e625e8be16e2c55", siteDomain: "xmeeting.com", identifier: "ea5a70e6-6d39-ba04-1927-94f8da1d7df8", safeMode: "1"
          }
          break
        case 'test':
          window.CCP_CONF = {
            userUid: '3ab32395-0767-5727-a4b1-f84d3683716c',
            userToken: "pu:3ab32395-0767-5727-a4b1-f84d3683716c:62cc69cf3b02ff6dfed4fe6f927171a9",
            identifier: '3033f445-40d6-a259-88a2-e87255091def',
            safeMode: '1',
          }
          break
        case 'test2_NSFW':
          window.CCP_CONF = {
            userUid: '003fec73-4586-596b-834e-fc76afd6d3ed',
            userToken: "pu:003fec73-4586-596b-834e-fc76afd6d3ed:b525ab82b8c62deb66a423ee67668c5b",
            identifier: '3033f445-40d6-a259-88a2-e87255091def',
            safeMode: '',
          }
      }
      this.props.ccpConfigLoaded(this.state.serverEnv)
    }
  }

  onChange = (event, data) => {
    this.setState({
      serverEnv: data.value
    })
    storage.set(STORAGE_KEY, data.value)

    //clear widget storage
    const localStorageKeysToRemove = []
    for(let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i)
      if(![STORAGE_KEY, 'WIDGET_ENV'].includes(key)) {
        localStorageKeysToRemove.push(key)
      }
    }
    if(localStorageKeysToRemove.length > 0) {
      for(let key of localStorageKeysToRemove) {
        localStorage.removeItem(key)
      }
    }

    const sessionStorageKeysToRemove = []
    for(let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i)
      if(![STORAGE_KEY, 'WIDGET_ENV'].includes(key)) {
        sessionStorageKeysToRemove.push(key)
      }
    }
    if(sessionStorageKeysToRemove.length > 0) {
      for(let key of sessionStorageKeysToRemove) {
        sessionStorage.removeItem(key)
      }
    }

    window.location.reload()
  }

  render() {
    return (
      <div>
        <label htmlFor="serverEnv">Please select a server environment</label>
        <Dropdown
          defaultValue={this.state.serverEnv}
          name="serverEnv"
          selection
          options={serverEnvironments}
          onChange={this.onChange}
        />
      </div>
    )
  }
}

export default EnvSelector
