import React from 'react'
import PropTypes from 'prop-types'
import {inject, observer} from 'mobx-react'

/*
 Usage examples:

 Injects state and store by default.
 Use this for most cases.
 -------------------------------------------
 @connect()

 Same as above but providing arguments manually
 -------------------------------------------
 @connect('store', 'actions')

 Injects only the returned object into props
 -------------------------------------------
 @connect(context => ({
   username: context.store.account.username
 })

*/

/**
 * Connects to MobX store and make component observable
 * @param stores
 * @returns {Function}
 */
global.connect = function connect(...stores) {

  let injectFn = null
  if (typeof stores[0] === 'function') {
    injectFn = inject(stores[0])
  } else if (arguments.length) {
    injectFn = (arguments[0] === null) ? observer : inject(...stores)
  } else {
    injectFn = inject('store', 'actions')
  }

  return function(WrappedComponent) {

    WrappedComponent.contextTypes = {
      params: PropTypes.object,
      history: PropTypes.object,
      router: PropTypes.object,
    }

    const NewComponent = injectFn(observer(WrappedComponent))

    return class extends React.Component {

      static contextTypes = WrappedComponent.contextTypes
      static childContextTypes = WrappedComponent.contextTypes

      getChildContext() {
        const {router = {}} = this.context
        const params = new URLSearchParams(window.location.search)
        return {
          params: getRouterParams(params, router.route),
          history: router.history,
          router,
        }
      }

      render() {
        return React.createElement(
          NewComponent,
          this.props
        )
      }
    }
  }
}

function getRouterParams(params, route) {
  const querystring = mapToObject(params)

  if (route && route.match && route.match.params) {
    return Object.assign({}, querystring, route.match.params)
  }
  return querystring
}

function mapToObject(map) {
  const out = Object.create(null)
  map.forEach((value, key) => {
    if (value instanceof Map) {
      out[key] = mapToObject(value)
    } else {
      out[key] = value
    }
  })
  return out
}
