import React, { Fragment, useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import Match from '../Icons/Match'
import ThumbsUp from '../Icons/ThumbsUp'
import Heart from '../Icons/Heart'
import View from '../Icons/View'
import moment from 'moment'
import Button from '../../elements/Button'
import { toast } from 'react-toastify'
import { Loader } from 'semantic-ui-react'
import PriorityColors from "../Icons/PriorityColors"

const IconContainer = styled.span`
  height: 30px;
  margin: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 18px;
    width: auto;
  }
`

export const INTERACTIONS = {
  matched: { icon: <Match/>, text: 'Matched' },
  meLiked: { icon: <ThumbsUp/>, text: 'AP liked the user' },
  otherUserLiked: { icon: <Heart/>, text: 'User liked the AP' },
  view: { icon: <View/>, text: (d = '') => `last viewed the AP on ${d}`},
  meViewed: { icon: <View/>, text: (d = '') => `AP viewed the user on ${d}`},
  otherUserViewed: { icon: <View/>, text: (d = '') => `last viewed the AP on ${d}`},
  requestedPhoto: { icon: <View/>, text: 'AP requested photo of user'},
  criticalPriority: { icon: <PriorityColors fill={'#FCD7D7'}/>, text: 'Critical (less than 20 messages)'},
  highPriority: { icon: <PriorityColors fill={'#FFF8DC'}/>, text: 'High (less than 50 messages)'},
  lowPriority: { icon: <PriorityColors fill={'#E8EEF5'}/>, text: 'Low (more than 50 messages)'}
}

export const getInteraction = (matched, meLiked, otherUserLiked) => {
  if(matched || (meLiked && otherUserLiked)) {
    return INTERACTIONS.matched
  }else if(meLiked) {
    return INTERACTIONS.meLiked
  } else if(otherUserLiked) {
    return INTERACTIONS.otherUserLiked
  }else {
    return null
  }
}

const UserInteractions = styled.div`
  display: flex;
  align-items: center;
  
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    p {
      display: flex;
      align-items: center;
    }
  }
`

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    margin: 10px;
  }
`

const LoadingContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Interactions = inject('store', 'actions')(observer(({ store, actions }) => {
  const [reFetchLoading, setReFetchLoading] = useState(false)

  const { interactions } = store.conversation

  const reFetch = async() => {
    setReFetchLoading(true)
    actions.chat.fetchInteractions(store.conversation)
    .then(([response, error]) => {
      if((response && response.length && response[0].error) || error) {
        toast.error('Failed to fetch interactions')
      }
      setReFetchLoading(false)
    })
  }

  if(interactions.loading) {
    return (
      <LoadingContainer>
        <Loader active={true} inline='centered'/>
        <p>loading interactions...</p>
      </LoadingContainer>
    )
  }

  if(interactions.error) {
    return (
      <ErrorContainer>
        <p>Interactions couldn't be loaded</p>
        <Button loading={reFetchLoading} onClick={reFetch} color="blue">retry</Button>
      </ErrorContainer>
    )
  }

  const whatToRender = Object.entries({
    matched: i => (i.matched ? INTERACTIONS.matched : false),
    meLiked: i => (i.meLiked ? INTERACTIONS.meLiked : false),
    otherUserLiked: i => (i.otherUserLiked ? INTERACTIONS.otherUserLiked : false),
    meViewed: i => (i.meViewed ? INTERACTIONS.meViewed : false),
    otherUserViewed: i => (i.otherUserViewed ? INTERACTIONS.otherUserViewed : false),
    photoRequested: i => (i.meRequestedPhoto ? INTERACTIONS.requestedPhoto : false),
  })
    .filter(([key]) => !(interactions.matched && (key === 'meLiked' || key === 'otherUserLiked')))
    .map(([key, check]) => [key, check(interactions)])
    .filter(([key, component]) => component && [key, component])

  if(!whatToRender.length) {
    return (
      <UserInteractions>
        <ul><li className='empty'>No interactions found with this user</li></ul>
      </UserInteractions>
    )

  }

  return (
    <UserInteractions>
      <ul>
        {whatToRender.map((([key ,interaction], i) => (
            <li key={i}>
              <p>
                <IconContainer>{interaction.icon}</IconContainer> {typeof interaction.text === 'function' ? interaction.text(moment(interactions[key] * 1000).format('DD-MM-YYYY [at] HH:mm')) : interaction.text}
              </p>
            </li>
          ))
        )}
      </ul>
    </UserInteractions>
  )
}))

export function InteractionIcon(props) {
  const { matched, meLiked, otherUserLiked } = props

  const interaction = getInteraction(matched, meLiked, otherUserLiked)
  if(!interaction) {
    return <Fragment/>
  }

  return (
    <IconContainer>
      {interaction.icon}
    </IconContainer>
  )
}

export function InteractionIconText(props) {
  const { matched, meLiked, otherUserLiked } = props

  const interaction = getInteraction(matched, meLiked, otherUserLiked)

  if(!interaction) {
    return <Fragment/>
  }

  return (
    <IconContainer>
      <p><span>{interaction.icon}</span> {interaction.text}</p>
    </IconContainer>
  )
}
