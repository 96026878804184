import { useLayoutEffect, useRef, useState } from 'react'

export const useElementSize = () => {
  const ref = useRef(null)
  const [size, setSize] = useState({ height: 0, width: 0 })

  const calculateSize = (el) => {
    if(el) {
      const computedStyle = getComputedStyle(el)
      const height = parseInt(computedStyle.marginTop.replace('px', '')) + parseInt(computedStyle.marginBottom.replace('px', '')) + el.offsetHeight
      const width = parseInt(computedStyle.marginLeft.replace('px', '')) + parseInt(computedStyle.marginRight.replace('px', '')) + el.offsetWidth
      setSize({ height, width })
    }
  }

  useLayoutEffect(() => {
    if(ref?.current) {
      calculateSize(ref.current)
    }
  }, [ref])

  return [size, ref]
}
