import { playSound } from 'core/helpers'
import request from 'core/request'
import * as constants from '../../../../core/constants'
import { MESSAGE_TYPE_SYSTEM } from '../../../../core/constants'
import { CCP_GET_CONVERSATION } from '../../../../core/endpoints'
import actions from '../../../actions'
import { removeSessionToken } from '../../../actions/Auth'
import Conversation from '../../../models/Conversation'
import store from '../../../store'

const hooks = {
  onMessage: (event, data) => {
    if(store.isDev) {
      console.warn(`[${event}]`, data)
    }
    switch(event) {
      case constants.TYPING: {
        let conversation = store.conversations.find(c => c.conversationUid === data.conversationUid)
        if (conversation) {
          conversation.typingIndicator = data.showUntil
        }
        break
      }
      case constants.NEW_MESSAGE: {
        data = { ...data }
        // Add message to conversation

        let conversation = store.conversations.find(c => {
          return c.conversationUid === data.conversationUid
        })

        if (!conversation) {
         let conversationsData = {conversationUid: data.conversationUid}
          request.get(CCP_GET_CONVERSATION, conversationsData, []).then(result => {
            if (store.conversations.some(conv => result.result.conversationUid === conv.conversationUid)) {
              return
            }

            let responseConversation = new Conversation(result.result)

            store.conversations.push(responseConversation)
            actions.chat.updateConversationCache(responseConversation)
            if (data.conversationUid === responseConversation.conversationUid) {
              conversation = responseConversation
            }
          })
        }

        if (conversation) {
          // Update stats. Has to happen before adding the message to make sure everything refreshes with correct stats
          conversation.updateStats({ lastMessageSentAt: data.sentAt })
          if (data.type === constants.MESSAGE_TYPE_DEFAULT || data.type === constants.MESSAGE_TYPE_SEXICON || data.type === constants.MESSAGE_TYPE_IMAGE) {
            if (data.senderUid === conversation.otherUserUid) {
              conversation.updateStats({ otherUserLastSentAt: data.sentAt })
            } else {
              conversation.updateStats({ meMessageCount: conversation.meMessageCount + 1 })
            }
          }

          if (data.receiverUid === conversation.meUid) {
            if (data.type === constants.MESSAGE_TYPE_DEFAULT || data.type === constants.MESSAGE_TYPE_SEXICON || data.type === constants.MESSAGE_TYPE_IMAGE) {
              if (store.conversationUid === data.conversationUid) {
                actions.chat.readConversation(conversation)
              }
              else {
                conversation.updateStats({ unreadByMeCount: conversation.unreadByMeCount + 1 })
                conversation.updateStats({ remainingMessages: conversation.remainingMessages - 1 })
              }
            }
            if (data.type === constants.MESSAGE_TYPE_IMAGE) {
              data.message = data.message.substring(data.message.lastIndexOf('/') + 1)
            }
            conversation.addMessage(data)
            playSound('plop')
          }
          else if (data.senderUid === conversation.meUid &&  !data.fromWidget) {
            conversation.addMessage(data)
          }
          else if (data.type === constants.MESSAGE_TYPE_CONTACT_INFO) {
            conversation.updateStats({ otherUserLastSentAt: data.sentAt })
            if (store.conversationUid === data.conversationUid) {
              actions.chat.readConversation(conversation)
            }
            else {
              conversation.updateStats({ unreadByMeCount: conversation.unreadByMeCount + 1 })
              conversation.updateStats({ remainingMessages: conversation.remainingMessages - 1 })
            }
            conversation.addMessage(data)
            playSound('plop')
          }
          else if (data.type === constants.MESSAGE_TYPE_SYSTEM) {
            return
          }
          else if (data.type === constants.MESSAGE_TYPE_DEFAULT && data.senderUid === conversation.meUid) {
            conversation.reorder(data)
          }
        } else {
          console.warn(`Conversation not found: ${data.conversationUid}`)
        }
        break
      }

      case constants.SYSTEM_NOTIFICATION: {
        const { type, receiverUid } = data
        const notificationActions = {
          block: eventData => actions.chat.markConversationAsBlocked(eventData),
          blockCredit: eventData => actions.chat.markConversationAsBlocked(eventData),
          contactInfoDetected: () => actions.chat.contactInfoDetected(data),
          downgrade: eventData => actions.chat.markConversationAsNotPremium(eventData),
          rebillDisabled: eventData => actions.chat.userToggledRebill(eventData),
          rebillEnabled: eventData => actions.chat.userToggledRebill(eventData),
          upgrade: eventData => actions.chat.markConversationAsPremium(eventData),
          unblock: eventData => actions.chat.markConversationAsUnblocked(eventData),
        }
        if(notificationActions[type]) {
          const sentAt = Math.floor(Date.now() / 1000)
          const messageData = { id: -1, message: type, type: MESSAGE_TYPE_SYSTEM, sentAt }
          const conversations = store.conversations.filter(c => c.otherUserUid === receiverUid)
          conversations.forEach(c => {
            c.addMessage(messageData)
            notificationActions[type]({ ...messageData, conversationUid: c.conversationUid })
          })
        }
        break
      }

      case constants.NEW_CONVERSATION_AGENT: {
        actions.chat.addConversation(data)
        break
      }

      case constants.NEW_CONVERSATIONS_AGENT: {
        for( let conversation of data.conversations) {
          actions.chat.addConversation(conversation)
        }
        break
      }
      case constants.USER_LEAVE: {
        let conversation = store.conversations.find(c => c.conversationUid === data.conversationUid)
        if (conversation && conversation.otherUserActive) {
          conversation.otherUserActive = false
        }
        break
      }

      case constants.USER_BACK: {
        let conversation = store.conversations.find(c => c.conversationUid === data.conversationUid)
        if (conversation && !conversation.otherUserActive) {
          conversation.otherUserActive = true
        }
        break
      }

      case constants.DETACH_CONVERSATION: {
        let conversation = store.conversations.find(c => c.conversationUid === data.conversationUid)
        if (conversation) {
          conversation.removeFromQueue()
        }
        break
      }

      case constants.RECLAIM_CONVERSATION: {
        let conversation = store.conversations.find(c => c.conversationUid === data.conversationUid)
        if (conversation) {
          if (store.conversationUid !== conversation.conversationUid) {
            conversation.removeFromQueue()
          }
          else {
            conversation.reclaimed = true
          }
        }
        break
      }

      case constants.AGENT_LEFT: {
        const agent = store.agents.find( e => {
          return e.id === data.agentId
        })
        agent.status = data.status
        break
      }

      case constants.AGENT_LOGGED_IN: {
        const agent = store.agents.find( e => {
          return e.id === data.agentId
        })
        agent.status = data.status
        break
      }

      case constants.AGENT_FORCE_LOGOUT: {
        removeSessionToken()
        location.reload()
        break
      }

      case constants.DOWNLOAD_STATS: {
        const progress = {}
        for( let p in store.stats.inProgress) {
          progress[p] = false
        }
        store.stats = { url: data.url, expiresIn: data.expiresIn, inProgress: progress }
        actions.admin.downloadFile(data.url)
        break
      }

      case constants.AGENT_STATS: {
        const { stats, before, after } = data
        store.agentStats.stats = { ...store.agentStats.stats, [after]: { stats, before, after, inProgress: false }}
        break
      }

      case constants.USER_DELETED: {
        const { conversationUid } = data
        const conversation = store.conversations.find(c => c.conversationUid === conversationUid)
        if (conversation) {
          conversation.userDeleted = true
        }
        actions.chat.removeConversationsDeletedUser()
        break
      }
      case constants.ON_PROFILE_LIKED: {
        const { conversationUid, userUid, likedByUserUid } = data
        actions.chat.setInteractionLike(conversationUid, { userUid, likedByUserUid })
        break
      }
      case constants.ON_PROFILE_VIEW: {
        const { conversationUid, viewedByUserUid, viewedAt } = data
        actions.chat.setInteractionView(conversationUid, { viewedByUserUid }, viewedAt)
        break
      }
      case constants.ON_PHOTO_REQUEST: {
        const { conversationUid, userUid, requestedByUserUid } = data
        actions.chat.setInteractionPhotoRequest(conversationUid, { userUid, requestedByUserUid })
        break
      }
      case constants.ON_PROFILE_MATCH: {
        const { conversationUid } = data
        actions.chat.setInteractionMatch(conversationUid)
        break
      }
      case constants.MARK_AS_READ: {
        const { conversationUid } = data
        const currentConversation = store.conversation
        const conversation = store.conversations.find(c => c.conversationUid === conversationUid)
        if (conversation && currentConversation.conversationUid !== conversationUid) {
          actions.chat.readConversation(conversation)
        }
        break
      }
      default:
        return
    }
  },
}

export default hooks
