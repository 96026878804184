import React from 'react'
import { inject } from 'mobx-react'

function isFeatureEnabled(featureName, featureFlags, overrideFeatureFlags) {
  if(overrideFeatureFlags) {
    return Object.keys(overrideFeatureFlags).indexOf(featureName) !== -1 ? !!overrideFeatureFlags[featureName] : !!featureFlags[featureName]
  }else {
    return featureFlags[featureName]
  }
}

function createFeatureFlaggedContainer({ featureName, enabledComponent, disabledComponent }) {
    const Component = isFeatureEnabled(featureName) ? enabledComponent : disabledComponent
    if (Component) {
      return <Component/>
    }
    return null

}

@inject('featureFlags', 'overrideFeatureFlags')
class EnabledFeature extends React.Component {
  render() {
    const { children, featureFlags, overrideFeatureFlags, featureName } = this.props
    return (
      isFeatureEnabled(featureName, featureFlags, overrideFeatureFlags) ? children : null
    )
  }
}

export {
  isFeatureEnabled,
  createFeatureFlaggedContainer,
  EnabledFeature
}

