import React from 'react'
import PropTypes from 'prop-types'
import { clone } from 'lodash'
import { Form, Checkbox } from 'semantic-ui-react'
import styled from 'styled-components'

const ColorWrapper = styled.div`
  label {
    &:before {
      color:#f00;
      background-color: ${props => props.color || 'white'} !important;
      border: 1px solid ${props => props.color || 'black'} !important;
    }
  }

  input:checked~label:after {
    color: #fff !important
  }
`

@connect()
class ObjectivesSelector extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedValues: props.initialValues.map(val => val.id)
    }
  }

  toggleObjective = (name, id) => {
    const { onChange } = this.props

    this.setState(({ selectedValues }) => {
      const newValues = toggleArray(selectedValues, id)
      onChange({ name, value: newValues })
      return {
        selectedValues: newValues
      }
    })
  }

  render() {
    const { store, label, name } = this.props
    const { selectedValues } = this.state

    return (
      <Form.Field>
        <label>{label} </label>
        {store.objectives.map((role) => {
          return (
            <div key={role.id}>
              <ColorWrapper color={role.frontendData.color}>
                <Checkbox
                  id={role.id}
                  value={role.id}
                  label={role.name}
                  onChange={(e, data) => this.toggleObjective(name, data.value)}
                  required={selectedValues.length === 0}
                  checked={selectedValues.includes(role.id)}
                />
              </ColorWrapper>
            </div>
          )
        })}
      </Form.Field>
    )
  }
}

ObjectivesSelector.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  initialValues: PropTypes.array.isRequired,
}

ObjectivesSelector.defaultProps = {
  initialValues: []
}

export default ObjectivesSelector

function toggleArray(arr, value) {
  const array = clone(arr)
  const index = array.indexOf(value)
  if (index === -1) {
    array.push(value)
  } else {
    array.splice(index, 1)
  }
  return array
}
