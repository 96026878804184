import { Form, Input } from 'semantic-ui-react'
import React from 'react'
import styled from 'styled-components'
import { InfoBubble } from '../misc/InfoBubble'

const Label = styled.label`
  &:first-letter {
    text-transform: capitalize;
  }
`

export const SettingEntry = ({ name, value, description, onChange = () => {} }) => {
  const settingName = name.split(/(?=[A-Z])/).join(" ").toLowerCase()
  const onInputChange = e => {
    onChange(name,
      { value: e.target.type === 'number' ? parseInt(e.target.value) : e.target.value, description })
  }
  return (
    <Form.Field required>
      <Label>{settingName}<InfoBubble id={name} content={description}/></Label>
      <Input type={isNaN(value) ? 'text' : 'number'} name={name} placeholder={settingName} value={value} onChange={onInputChange}/>
    </Form.Field>
  )
}
