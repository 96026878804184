import React from 'react'

const ModalClose = () => (
  <svg className='modal-close' xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 14 14">
    <g transform="translate(-341 -89)">
      <g data-name="close" transform="translate(341 89)">
        <path d="M14,1.4,12.6,0,7,5.6,1.4,0,0,1.4,5.6,7,0,12.6,1.4,14,7,8.4,12.6,14,14,12.6,8.4,7Z" fill="#333" fillRule="evenodd"/>
      </g>
    </g>
  </svg>
)

export default ModalClose
