import React from "react"

export default function ChevronDown({ isActive }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="24"
      viewBox="0 0 24 24"
      fill='none'
      stroke={isActive ? 'currentColor' : 'grey'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={isActive ? 'search-result-arrow-active' : ''}
    >
      <path d="m6 9 6 6 6-6"/>
    </svg>
  )
}