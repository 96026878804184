// Boostrap
import 'nodent-runtime'
import 'core/polyfills'
import 'core/connect'
import 'core/boundary'
import 'core/globalsInterface'

//setup config
const { CONFIG } = require('core/globalsInterface')
//used for localhost
const { api } = require('core/localhost')
Config.set({ ...CONFIG, ...(api ? { api } : null) })

// Styles
import 'assets/styles'

// Libraries
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'mobx-react'
import * as mobx from 'mobx'
import mobxFormatters from 'mobx-formatters'
import { enableLogging } from 'mobx-logger'
import { BrowserRouter, Route } from 'react-router-dom'
import * as Config from 'core/config'
import routes from './routes'
import store from './store'
import actions from './actions/index'
import { when } from 'mobx'
import { UT } from 'core/constants'
import * as CcpProvider from '../core/CcpProvider'
import hooks from './components/CcpProvider/websocket/hooks'
import ApiHooks from './components/CcpProvider/api/ApiHooks'
import ApiHandlers from './components/CcpProvider/api/ApiHandlers'
import { injectStores } from '@mobx-devtools/tools'

const ccpProvider = CcpProvider.initProvider()
ccpProvider.hooks = hooks

ccpProvider.api.hooks = ApiHooks
ccpProvider.api.handlers = ApiHandlers

// Debugging
if(store.env !== 'MS-production') {
  mobxFormatters(mobx)
  enableLogging({
    action: true,
    reaction: false,
    transaction: true,
    compute: false
  })
  
  injectStores({
    store
  })
  // For debugging
  window.ccpProvider = ccpProvider
}

window.__STATE = window.__STATE || store

actions.auth.getSession()

when(() => (store.token), () => {
  ccpProvider.api.token = store.token
  when(() => (store.account.type && store.account.id), () => {
    if(!store.socket && store.account && (store.account.type === UT.ADMIN || store.account.type === UT.AGENT)) {
      actions.chat.initWebSocket()
    }
  })
})

render(<Provider store={store} actions={actions} featureFlags={Config.get('featureFlags')} overrideFeatureFlags={Config.get('overrideFeatureFlags')}>
  <BrowserRouter>
    <Route render={() => (
      routes(store)
    )}/>
  </BrowserRouter>
</Provider>, document.getElementById('app'))
