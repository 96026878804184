import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import { inject } from 'mobx-react'
import { get, size } from 'lodash'
import Section from '../../UI/Section'
import InfoModal from '../../Chat/InfoModal'
import { Time } from '../../misc/Times'
import { Table, Grid, Dimmer, Loader } from 'semantic-ui-react'
import '../css/datepicker.css'
import './styles'
import Conversation from '../../../models/Conversation'
import { pictureLink, isPicture } from '../../../../core/helpers'

const SENT_BY = {
  user: 'user',
  agent: 'agent',
  flow: 'flow',
  init: 'initial message',
}

const FlowListItem = styled.li`
  font-weight: bold;
  text-indent: 10px;
`

const NoFlowsFound = styled.p`
  font-weight: bold;
  display: inline-block;
  width: initial;
  margin-left: 1px;
`

const LogType = ({ type, description = '', user = '', agent = 'agent unknown', targetAgent = 'agent unknown' }) => {
  const text = (description || '').split(/({user}|{agent}|{sourceAgent}|{destinationAgent})/g)
  return (
    <span>
      {text.map((t, i) =>
        t === '{user}' ? <span key={i} className="bold">{user}</span> :
          t === '{agent}' ? <span key={i} className="bold">{agent}</span> :
            t === '{sourceAgent}' ? <span key={i} className="bold">{agent}</span> :
              t === '{destinationAgent}' ? <span key={i} className="bold">{targetAgent}</span> :
                t === '' && type ? <span key={i}>{type}</span> :
                  t === '' && description ? <span key={i}>{description}</span> :
                    t
      )}
    </span>
  )
}

const ConversationDetail = ({ actions, conversationUid }) => {

  const [conversation, setConversation] = useState({ logs: [] })
  const [loading, setLoading] = useState(false)
  const [infoModal, setInfoModal] = useState({})

  useEffect(() => {
    setLoading(true)
    actions.admin.getConversationDetails(conversationUid)
      .then(([conv]) => {
        setLoading(false)
        setConversation(new Conversation(processLogs(conv)))
      })
  }, [])

  const processLogs = conv => {
    const logs = [...conv.logs]
    const agentReplied = logs.find(l => l.type === 8)
    const flowStarted = logs.find(l => l.type === 9)
    for (let [index, log] of logs.entries()) {
      if (log.type === 'message' || log.type === 'image') {
        if (index === 0 && log.sentBy === 0) { //initial automated message
          logs[index].sentByFull = SENT_BY.init
        } else if (agentReplied && log.createdAt > agentReplied.createdAt - 1 && log.sentBy === 0) { //small offset for older first agent reply logs
          logs[index].sentByFull = SENT_BY.agent
        } else if (log.sentBy === 0 && flowStarted) {
          logs[index].sentByFull = SENT_BY.flow
        } else {
          logs[index].sentByFull = SENT_BY.user
        }
      }

      if (log.type === 'gift' || log.type === 'sexicon') {
        if (log.sentBy === 0) {
          logs[index].sentByFull = SENT_BY.agent
        } else {
          logs[index].sentByFull = SENT_BY.user
        }
      }

      if (log.type === 40) { //charge for photo reveal
        logs[index].message = <a href={log.data[0]} target="_blank" rel="noreferrer">{log.data[0]}</a>
      }
    }
    return { ...conv, logs: logs.filter(l => ![8, 11, 12].includes(l.type)) }
  }

  const toggleInfoModal = async(user = 0) => {
    const [apUid, userUid] = conversationUid.split(':')
    if(!size(infoModal)) {
      setInfoModal({ user, apUid, userUid, identifier: conversation.identifier, conversation })
    }else {
      setInfoModal({})
    }
  }

  return (
    <div className="content" id="conversation-detail">
      <div className="content-body">
        <h2>Conversation detail</h2>
        <h4>{conversation.type ? `${conversation.type === 'normal' ? 'User to user' : 'AP to user'} conversation` : ''}</h4>
        <Section>
          <Dimmer active={loading} inline="centered">
            <Loader>Loading</Loader>
          </Dimmer>
          <Grid columns={2}>
            <Grid.Column>
              <Grid.Row>Impersonator: <span className="bold hover-pointer"
                                            onClick={() => toggleInfoModal('me')}>{get(conversation, 'meProfile.username', '')}</span></Grid.Row>
              <Grid.Row>Impersonator uuid: <span className="bold">{conversation.meUid}</span></Grid.Row>
              <Grid.Row>Impersonator gender: <span
                className="bold">{get(conversation, 'meProfile.gender', '')}</span></Grid.Row>
              <Grid.Row>Impersonator location: <span className="bold">{get(conversation, 'meProfile.location', '')}</span></Grid.Row>
              <Grid.Row>Amount of messages sent by agent: <span
                className="bold">{conversation.agentMessages}</span></Grid.Row>
              <Grid.Row>Amount of messages sent by user: <span
                className="bold">{conversation.userMessages}</span></Grid.Row>
              <Grid.Row>Amount of messages sent by flow: <span
                className="bold">{conversation.flowMessages}</span></Grid.Row>
            </Grid.Column>
            <Grid.Column>
              <Grid.Row>Correspondent: <span className="bold hover-pointer"
                                             onClick={() => toggleInfoModal('otherUser')}>{get(conversation, 'otherUserProfile.username', '')}</span></Grid.Row>
              <Grid.Row>Correspondent uuid: <span className="bold">{conversation.otherUserUid}</span></Grid.Row>
              <Grid.Row>Conversation type: <span className="bold">{conversation.conversationType}</span></Grid.Row>
              <Grid.Row>Correspondent gender: <span
                className="bold">{get(conversation, 'otherUserProfile.gender', '')}</span></Grid.Row>
              <Grid.Row>Correspondent location: <span className="bold">{get(conversation, 'otherUserProfile.location', '')}</span></Grid.Row>
              <Grid.Row>Site: <span className="bold">{conversation.siteName}</span></Grid.Row>
              <Grid.Row>Flows:
                {get(conversation, 'flowTypes', []).length === 0 ?
                  <NoFlowsFound>No flows found in this conversation</NoFlowsFound> :
                  <ol>
                    {get(conversation, 'flowTypes', []).map((f, i) => {
                      if (f.flow.description) {
                        return (<FlowListItem key={i}>{f.flow.description} (category: {f.flow.category})</FlowListItem>)
                      } else {
                        return (<FlowListItem key={i}>A flow was started, but no description was found</FlowListItem>)
                      }
                    })}
                  </ol>
                }
              </Grid.Row>
            </Grid.Column>
          </Grid>
        </Section>
        <Section>
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>CreatedAt</Table.HeaderCell>
                <Table.HeaderCell>SentBy</Table.HeaderCell>
                <Table.HeaderCell>Message</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>

              {conversation.logs
                .map((log, i) => {
                  const rowClass = classNames(`log-type-${log.type}`, {
                    'log-message-0': log.type === 'message' && log.sentBy === 0,
                    'log-message-1': log.type === 'message' && log.sentBy === 1,
                    'log-flows': [11, 12, 13].includes(log.type) || log.sentByFull === SENT_BY.flow,
                    'log-flows-boundary': [9, 10].includes(log.type),
                  })
                  let messageElement = log.message
                  if (isPicture(log.type)) {
                    const url = pictureLink(log.message, conversation.conversationUid, conversation.identifier)
                    messageElement = <a href={url} target="_blank" rel="noreferrer">{url}</a>
                  }
                  return (
                    <Table.Row key={i} className={rowClass}>
                      <Table.Cell><LogType type={log.type} description={log.description} targetAgent={get(log, 'targetAgent.name')} user={get(conversation, `user${log.user}.username`)}
                                           agent={get(log, 'agent.name')}/></Table.Cell>
                      <Table.Cell><Time time={log.createdAt * 1000} format="MMM DD YYYY HH:mm:ss"/></Table.Cell>
                      <Table.Cell>{log.sentBy !== undefined ? log.sentByFull : ''}</Table.Cell>
                      <Table.Cell>{messageElement}</Table.Cell>

                    </Table.Row>
                  )
                })
              }
            </Table.Body>
          </Table>
        </Section>
        {size(infoModal) > 0 && <InfoModal {...infoModal} toggleInfoModal={toggleInfoModal}/>}
      </div>
    </div>
  )
}

export default inject('store', 'actions')(ConversationDetail)
