import React, { useEffect, useState } from 'react'
import { pick, omitBy, isNil, pickBy, identity } from 'lodash'
import { Link, withRouter } from 'react-router-dom'
import { inject } from 'mobx-react'
import DateTimePicker from 'react-datetime'
import Section from '../../UI/Section'
import { Icon, Table, Grid, Form, Button, Loader, Select, Popup } from 'semantic-ui-react'
import '../css/datepicker.css'
import { LOGS_SORT_TYPES as SORT } from 'core/constants'
import AgentsListDropdown from '../../elements/AgentsListDropdown'
import { usePushToHistory, useQueryParams }  from 'core/hooks'
import Pagination from '../../misc/Pagination'
import { Duration, Time } from '../../misc/Times'
import { LOG_SORTERS } from 'core/helpers'

const DEFAULT_FORM_VALUES = {
  agentIds: [],
  after: Date.now() - (1000 * 60 * 60 * 24 * 7), //one week from now
  before: Date.now(),
  sortBy: SORT.DATE_DESC,
  recurringState: null,
}

const SearchForm = ({ actions, onSubmit = () => {}}) => {
  const sortOptions = [
    { key: 1, text: 'Recent first', value: SORT.DATE_DESC },
    { key: 2, text: 'Oldest first', value: SORT.DATE_ASC },
    { key: 3, text: 'Agent', value: SORT.AGENT },
    { key: 4, text: 'Conversions first', value: SORT.UPGRADE },
    { key: 5, text: 'Most Messages first', value: SORT.AGENT_MESSAGES },
  ]
  const [formData, setFormData] = useState(DEFAULT_FORM_VALUES)
  const [loading, setLoading] = useState(false)
  const [, saveQueryParams] = usePushToHistory()
  const params = useQueryParams()

  useEffect(() => {
    const newFormData = pickBy({ ...DEFAULT_FORM_VALUES, ...pick(params, Object.keys(DEFAULT_FORM_VALUES)) }, identity)

    new Array('after', 'before').forEach(x => {
      if(`${newFormData[x]}`.length === 10) {
        newFormData[x] = newFormData[x] * 1000
      }
      newFormData[x] = parseInt(newFormData[x])
    })

    setFormData(newFormData)
    setLoading(true)
    submit(newFormData)
  }, [])

  const submit = async(data) => {
    if(!data?.agentIds?.length) {
      delete data.agentIds
    }
    if(typeof data?.agentIds === 'string') {
      data.agentIds = [data.agentIds]
    }
    data = { ...data, agentIds: data.agentIds?.join(',') }

    onSubmit(data).finally(() => setLoading(false))
  }

  useEffect(() => {
    saveQueryParams(omitBy(formData, isNil))
  }, [formData])

  const onAgentChange = (e, { value }) => {
    setFormData({ ...formData, agentIds: value })
  }

  const onTimeChange = name => momentInstance => {
    setFormData({ ...formData, [name]: momentInstance.valueOf() })
  }

  const onSortChange = (e, { value }) => {
    setFormData({ ...formData, sortBy: value })
  }
  const { agentIds = [], after, before, sortBy } = formData
  return (
    <Form onSubmit={() => submit(formData)} loading={loading}>
      <Form.Group widths="equal">
        <AgentsListDropdown name="agentIds" actions={actions} onChange={onAgentChange} value={agentIds} multiple={true}/>
        <Form.Field>
          <label>From</label>
          <DateTimePicker onChange={onTimeChange('after')} value={after ? new Date(after) : null}/>
        </Form.Field>
        <Form.Field>
          <label>To</label>
          <DateTimePicker onChange={onTimeChange('before')} value={before ? new Date(before) : null}/>
        </Form.Field>
        <Form.Field>
          <Form.Field control={Select} label='Sort by' id='sortBy' name='sortBy' value={sortBy} onChange={onSortChange} options={sortOptions}/>
        </Form.Field>
        <Form.Field>
          <Button type='submit' primary fluid>
            Find conversations
          </Button>
        </Form.Field>
      </Form.Group>
    </Form>
  )
}
const ConversationsTable = ({ actions }) => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [conversations, setConversations] = useState([])
  const [pagination, setPagination] = useState({ activePage: 1, pageSizes: [10, 25, 50, 100], pageSize: 25 })

  const search = async(data) => {
    setError(false)
    setLoading(true)
    const [response, err] = await actions.admin.getConversationsStats(data)
    setLoading(false)
    if(err) {
      setError('Error fetching agent stats')
      return
    }

    setConversations(response.conversations.sort(LOG_SORTERS[data.sortBy]))
  }

  const onPageChange = (e, data) => setPagination({ ...pagination, activePage: data.activePage })
  const onPageSizeChange = pageSize => setPagination({ ...pagination, pageSize })

  const Row = ({ conversation }) => {
    const [user0Uid, user1Uid] = conversation.conversationUid.split(':')
    return (
      <>
        <Table.Cell><Time time={Number(conversation.attachedAt)}/></Table.Cell>
        <Table.Cell>{conversation.name}</Table.Cell>
        <Table.Cell>{user0Uid}</Table.Cell>
        <Table.Cell>{user1Uid}</Table.Cell>
        <Table.Cell>{conversation.agentMessages}</Table.Cell>
        <Table.Cell>{conversation.userMessages}</Table.Cell>
        <Table.Cell>{(conversation.agentInitialResponseTime / 1000).toFixed(2)}s</Table.Cell>
        <Table.Cell>{conversation.initialSale > 0 ? 'upgrade' : conversation.rebill > 0 ? `rebill (${conversation.rebill.toFixed(2)})` : 0}</Table.Cell>
        <Table.Cell>{conversation.rebillStatus || 0}</Table.Cell>
        <Table.Cell>{conversation.partialDowngrade || 0}</Table.Cell>
        <Table.Cell>{downgradeReason(conversation)}</Table.Cell>
        <Table.Cell>
          <Link to={`/admin/conversations/${conversation.conversationUid}`}>
            <Icon name="unhide" size="small"/>
          </Link>
        </Table.Cell>
      </>
    )
  }
  return (
    <div className='content' id='stats'>
      <div className='content-body'>
        <Section>
          <SearchForm actions={actions} onSubmit={search}/>
          <Loader active={loading}/>
          <Table celled striped>
            <Table.Header className="admin-conversations-header">
              <Table.Row>
                <Table.HeaderCell>Date(UTC)</Table.HeaderCell>
                <Table.HeaderCell>Agent</Table.HeaderCell>
                <Table.HeaderCell>Impersonator UUID</Table.HeaderCell>
                <Table.HeaderCell>Correspondent UUID</Table.HeaderCell>
                <Table.HeaderCell>Agent<br/>messages</Table.HeaderCell>
                <Table.HeaderCell>User<br/>messages</Table.HeaderCell>
                <Table.HeaderCell>Initial<br/>response</Table.HeaderCell>
                <Table.HeaderCell>Conversion</Table.HeaderCell>
                <Table.HeaderCell>Rebill<br/>status</Table.HeaderCell>
                <Table.HeaderCell>Partial<br/>downgrade</Table.HeaderCell>
                <Table.HeaderCell>Downgrade</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {conversations
                .filter((c, i) => (i >= (pagination.activePage - 1) * pagination.pageSize ) && ( i < (pagination.activePage - 1) * pagination.pageSize + pagination.pageSize))
                .map((conversation, i) => {
                  return (
                    <Popup key={i}
                      on="click"
                      hideOnScroll
                      trigger={<Table.Row style={{cursor: 'pointer'}}><Row conversation={conversation}/></Table.Row>}
                      content={
                       <div>
                         Attached at: <Time time={conversation.attachedAt}/><br/>
                         { conversation.detachedAt ?
                           <span>
                              Detached at: <Time time={conversation.detachedAt}/><br/>
                              Duration: <Duration end={conversation.detachedAt} start={conversation.attachedAt} digits={2} unit={'minutes'}/> minutes
                           </span> :
                           <span>Ongoing</span>}
                       </div>
                      }
                      position="top left"
                      flowing
                    />
                  )
                })
              }
            </Table.Body>
          </Table>
          <Pagination amount={conversations.length} activePage={pagination.activePage} onPageChange={onPageChange} pageSizes={pagination.pageSizes} defaultPageSize={pagination.pageSize} onPageSizeChange={onPageSizeChange}/>
          <Grid centered={true}>
            <Grid.Column style={{ display: 'flex', justifyContent: 'center'}}>
              { error && <h4>{error}</h4>}
            </Grid.Column>
          </Grid>
        </Section>
      </div>
    </div>
  )
}

function downgradeReason(conversation) {
  if(conversation.refund) {
    return 'refund'
  }
  if(conversation.chargeback) {
    return 'chargeback'
  }
  if(conversation.downgrade) {
    return 'downgrade'
  }
  return 0
}

export default inject('store', 'actions')(withRouter(ConversationsTable))
