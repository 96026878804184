import React, { useEffect, useState } from 'react'
import { FeatureSwitchName } from './FeatureSwitches'
import { FeatureSwitchButton } from './FeatureSwitchButton'
import { SearchInput } from '../misc/SearchInput'
import { orderBy } from 'lodash'
import { Table, TableBody, TableHeader, TableHeaderH } from '../misc/table'
import { InfoBubble } from '../misc/InfoBubble'

const sortAlphabetically = arr => orderBy(arr, 'displayName', 'asc')

export const SiteFeatureSwitches = ({siteFeatureSwitches, descriptions = {} }) => {
  const [featureSwitchesStorage, setFeatureSwitches] = useState(sortAlphabetically(siteFeatureSwitches))
  const [searchableFeatureSwitches, setSearchableFeatureSwitches] = useState([])

  useEffect(() => (setSearchableFeatureSwitches(featureSwitchesStorage)), [featureSwitchesStorage.length])
  useEffect(() => (setFeatureSwitches(sortAlphabetically(siteFeatureSwitches))), [siteFeatureSwitches])

  const toggleFeatureSwitch = async({ whiteLabelUid, featureSwitch, value }) => {
    const index = featureSwitchesStorage.findIndex(x => x.whiteLabelUid === whiteLabelUid)
    const updatedFeatureSwitches = [...featureSwitchesStorage]
    updatedFeatureSwitches[index].featureSwitches[featureSwitch] = value
    setFeatureSwitches(updatedFeatureSwitches)
  }

  const onSearch = value => {
    setSearchableFeatureSwitches(featureSwitchesStorage.filter(x => x.displayName.toLowerCase().includes(value)))
  }

  return (
    <div>
      <h2>Feature switches</h2>
      <SearchInput onSearch={onSearch} clearable placeholder="filter sites"/>
      {featureSwitchesStorage.length > 0 &&
        <Table sticky fullWidth>
          <TableHeader sticky>
            <tr>
              <TableHeaderH>Site name</TableHeaderH>
              {Object.keys(featureSwitchesStorage[0].featureSwitches).map((buttonName, key) => (
                <TableHeaderH key={key}>
                  <FeatureSwitchName name={buttonName}/>
                  <InfoBubble content={descriptions[buttonName]} id={buttonName}/>
                </TableHeaderH>
              ))}
            </tr>
          </TableHeader>
          <TableBody>
            {
              searchableFeatureSwitches.map(({ displayName, whiteLabelUid, featureSwitches }) => (
                <tr key={whiteLabelUid}>
                  <td>
                    {displayName}
                  </td>
                  {Object.entries(featureSwitches).map(([name, value], key) => (
                    <td key={key}>
                      <FeatureSwitchButton
                        whiteLabelUid={whiteLabelUid}
                        featureSwitch={name}
                        on={value}
                        onClick={toggleFeatureSwitch}
                        type="whiteLabel"
                      />
                    </td>
                  ))}
                </tr>
              ))
            }
          </TableBody>
        </Table>
      }
    </div>
  )
}
