import React from 'react'
import { Button, List } from 'semantic-ui-react'
import ModalBaseClass from './ModalBaseClass'
import { detachConversations } from './helpers'

class ModalDetach extends ModalBaseClass {

  handleDetach = async(conversationUids) => {
    const {userUid} = this.props
    await detachConversations(userUid, conversationUids)
    await this.fetchConversations()
  }

  onConfirm = async() => {
    const conversationUids = this.state.conversations.map(conv => conv.conversationUid)
    await this.handleDetach(conversationUids)
    console.warn('Detached', conversationUids)
  }

  render() {
    const {conversations} = this.state
    const ModalBody = this.renderBody

    return (
      <ModalBody
        headerText='Detach conversations'
        triggerButtonText='Detach'
        confirmButtonText='Detach All'>
        <List divided verticalAlign='middle'>
          {conversations.map(conv => {
            return (
              <List.Item key={conv.conversationUid}>
                <List.Content floated='right'>
                  <Button onClick={() => this.handleDetach([conv.conversationUid])}>
                    Detach
                  </Button>
                </List.Content>
                <List.Content>
                  {conv.conversationUid}
                </List.Content>
              </List.Item>
            )
          })}
        </List>
      </ModalBody>
    )
  }
}

export default ModalDetach
