import { action, makeObservable } from 'mobx'
import { ccpProvider } from 'core/CcpProvider'
import storage from 'core/storage'
import * as constants from 'core/constants'
import store from '../store'
import { LOCALSTORAGE_CHATBOT_KEY } from '../../core/constants'

export default class Auth {
  constructor() {
    makeObservable(this, {
      getSession: action,
      setSession: action,
      login: action,
      logout: action,
      fetchCurrentUser: action
    })
  }

  getSession() {
    const session = getSessionToken()
    if (session.token) {
      if(!ccpProvider.api.token) {
        ccpProvider.api.token = session.token
      }
      this.setSession(session)
      this.fetchCurrentUser()
    }
  }

  setSession(params) {
    setSessionToken(params)
    store.token = params.token
    store.account = {type: params.accountType}
  }

  async login({ email, password }) {
    try {
      const [response] = await ccpProvider.api.auth.login({ email, password })
      store.token = response.token
      store.account = { ...response.session.ccp, online: true }
      this.setSession({
        token: response.token,
        accountType: response.session.ccp.type
      })
      this.fetchCurrentUser()
    } catch(err) {
      console.error(err)
      throw err
    }
  }

  async logout() {
    if(store.socket) {
      store.socket = null
    }
    if(store.account.type === constants.UT.AGENT) {
      store.conversations.replace([])
      await ccpProvider.api.agent.leave()
    }
    await ccpProvider.api.auth.logout()

    this.setSession({
      token: null,
      accountType: null
    })

    removeSessionToken(LOCALSTORAGE_CHATBOT_KEY)
  }

  fetchCurrentUser() {
    ccpProvider.api.auth.fetchCurrentUser().then(([response, err]) => {
      if(err) {
        console.error('fetchCurrentUser:', err)
        store.authError = err
        return err
      }
      store.account = { ...response, online: true, leave: false, break: response.status === 'break', autoBreak: false }
    })
  }
}

// ---- Helpers
// Maybe they need their own directory?

export function getSessionToken(sessionKey = constants.LOCALSTORAGE_SESSION_KEY) {
  try {
    return storage.get(sessionKey, {})
  } catch(error) {
    return {}
  }
}

export function setSessionToken(params, sessionKey = constants.LOCALSTORAGE_SESSION_KEY) {
  try {
    storage.set(sessionKey, params)
  } catch(e) {
    console.error('Could not store auth data.', e)
  }
}

export function removeSessionToken(sessionKey = constants.LOCALSTORAGE_SESSION_KEY) {
  try {
    storage.remove(sessionKey)
    if(sessionKey == constants.LOCALSTORAGE_SESSION_KEY) {
      store.token = null
      store.account = {
        token: null,
        accountType: null
      }
    }
  } catch (e) {
    return {}
  }
}
