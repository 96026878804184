import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Image } from 'semantic-ui-react'
import { CurrentTime } from '../../elements/CurrentTime'
import styled from 'styled-components'
import Birthday from './Birthday'
import ButtonGroup from "./ButtonGroup"
import GoogleMapsBtn from "./GoogleMapsBtn"
import WikipediaBtn from "./WikipediaBtn"
import GoogleWeatherBtn from "./GoogleWeatherBtn"
import { inject, observer } from "mobx-react"

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: .5rem;
  align-items: center;

  &:before {
    content: "";
    border: 1px solid #8cc9d9;
    align-self: stretch;
  }

  .userInfo {
    width: 100%;
    img {
      max-width: 98px;
      border-radius: 3px;
    }
    p:not(:last-child) {
      margin-bottom: 5px;
    }
    @media (max-width:1024px) {
      flex-direction: column;
    }
  }

  .userImages {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  .userGallery {
    display: flex;
    align-items: flex-end;
    width: 100%;
    img {
      flex: 1;
      width: 16%;
      max-width: 56px;
      height: auto;
      margin: 2px;
      border-radius: 3px;
      object-fit: contain;
      &:first-child {
        width: 29%;
        max-width: 98px;
      }
    }
  }

  .userDetails {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    margin-top: 10px;
    section {
      flex: 1;
      a {
        display: inline-block;
        cursor: pointer;
        font-weight: bold;
        color: #3b5998;
        position: relative;
        padding-right: 10px;
        margin-top: 10px;
        text-align: center;
        &:after {
          content: '';
          position: absolute;
          top: 60%;
          right: 0;
          transform: translateY(-50%);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 0 5px 5px;
          border-color: transparent transparent transparent #3b5998;
        }
      }
    }
  }

  .userDescription {
    max-height: 84px;
    overflow: hidden;
  }
`

const Username = styled.h2`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const BirthdayContainer = styled.p`
  display: flex;
  align-items: center;
  font-size: larger;
  font-weight: bold;
  span {
    margin: 0 0.4em 0 0;
  }
`

function UserInfo({ store, ...props }) {
  const {
    conversationUid = null,
    user,
    userOnline,
    profile = {},
    action = () => {},
    showInfoButtons = false,
    showProfileDescription = false,
    showUsername = false,
    showImageGallery = false,
  } = props
  const birthday = moment(profile.birthday)
  const isBirthday = birthday.format('DM') === moment().format('DM')
  const age = profile.age || moment().diff(birthday, 'years')
  const distance = (profile.distance !== '') ? Math.round(profile.distance * 1.609) + ' miles' : 'Unknown'
  const { conversation } = store

  const [profilePictures, setProfilePictures] = useState({pictures: []})

  useEffect(() => {
    getPictures().then()
  }, [profile])

  const getPictures =  async() => {
    let response = await conversation.fetchPictures(conversation[`${user}Uid`])

    setProfilePictures({
      ...profilePictures,
      pictures: response,
    })
  }

  if(!conversationUid || Object.keys(profile).length === 0) {
    return <Fragment/>
  }

  return (
    <UserInfoContainer>
      <div className="userInfo">
        <div className="userImages">
            { showImageGallery && profilePictures.pictures.length > 0 && <div className="userGallery">
              {profilePictures.pictures.slice(0, 5).map((picture, i) => (
                <img key={i} src={picture.thumbnail}/>
              ))}
            </div> }
            { (!showImageGallery || profilePictures.pictures.length === 0) && <div>
              <Image
                size="small"
                src={profile.profileImage}
              />
            </div>}
          { showInfoButtons && <div>
            <ButtonGroup align="center" direction="column" style={{margin: '0'}}>
              <GoogleMapsBtn location={profile.location}/>
              <WikipediaBtn city={profile.city} state={profile.state}/>
              <GoogleWeatherBtn city={profile.city}/>
            </ButtonGroup>
          </div> }
        </div>
        <div className="userDetails">
          <section>
            { showUsername && <Username onClick={action}>{profile.username}</Username> }
            {user === 'otherUser' && isBirthday && <Birthday as={BirthdayContainer} username={profile.username}/>}
            <p>{age}, {profile.gender}</p>
            <p>{profile.location}</p>
            <p>{birthday.format('DD-MM-YYYY')}</p>
            <p>Distance: {distance}</p>
            { conversationUid && <CurrentTime user={user} conversationUid={conversationUid}/> }
            { userOnline && <p>{userOnline ? 'Status: Online' : 'Status: Offline'}</p> }
          </section>
          { showProfileDescription && <section>
            { profile.profileText && <div>
              <p className="userDescription">{profile.profileText}</p>
              <a onClick={action}>Show more</a>
            </div> }
            { !profile.profileText && <div>
              <p>No description</p>
            </div> }
          </section> }
        </div>
      </div>
    </UserInfoContainer>
  )
}

UserInfo.propTypes = {
  conversationUid: PropTypes.string,
  user: PropTypes.oneOf(['me', 'otherUser']),
  userOnline: PropTypes.bool,
  profile: PropTypes.object,
  action: PropTypes.func,
  matched: PropTypes.bool,
  meLiked: PropTypes.bool,
  otherUserLiked: PropTypes.bool,
  viewedAt: PropTypes.number,
  showInfoButtons: PropTypes.bool,
  showProfileDescription: PropTypes.bool,
  showUsername: PropTypes.bool,
  showImageGallery: PropTypes.bool,
}

export default inject('store')(observer(UserInfo))
