import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import ChatMessageList from './ChatMessageList'
import ChatInput from './ChatInput'

const ChatWidgetContainer = styled.div`
  flex: none;
  resize: both;
  overflow: auto;
  max-height: 90%;
`
const ChatContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 0 1em 0;
  border-bottom: solid 1px lightblue;
  position: relative;
`

const ChatWidget = ({ conversation, pendingImageMessage, setPendingImageMessage }) => {
  const sendMessage = async(message) => {
    conversation.send(message)
  }

  const [stopAutoScroll, setStopAutoScroll] = useState(false)

  function fetchLatest() {
    conversation.fetchMessages()
  }

  return (
    <ChatWidgetContainer>
      <ChatContent>
        <ChatMessageList
          conversation={conversation}
          messages={toJS(conversation.messages)}
          setStopAutoScroll={setStopAutoScroll}
          stopAutoScroll={stopAutoScroll}
        />
        {stopAutoScroll && <div className="goToCurrent" onClick={() => {setStopAutoScroll(false); fetchLatest()}}>Go to newest</div>}
        <ChatInput onSubmit={sendMessage} conversation={conversation} pendingImageMessage={pendingImageMessage} setPendingImageMessage={setPendingImageMessage}/>
      </ChatContent>
    </ChatWidgetContainer>
  )
}

ChatWidget.defaultProps = {
  conversation: {},
}

ChatWidget.propTypes = {
  conversation: PropTypes.object,
}

export default withRouter(observer(ChatWidget))

