import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'
import { CCP_SET_WHITELABELS, CCP_SET_GLOBAL_FEATURE_SWITCHES } from '../../../../core/endpoints'
import request from 'core/request'

export const FeatureSwitchButton = ({ whiteLabelUid, featureSwitch, on, type, onClick = () => {} }) => {
  const [loading, setLoading] = useState(false)
  const toggleFeature = async() => {
    setLoading(true)
    if (type == 'whiteLabel') {
      await request.post(CCP_SET_WHITELABELS,  { whiteLabelUid, [featureSwitch]: !on})
        .then((response) => {
          setLoading(false)
          if(response.result.updated) {
            onClick({ whiteLabelUid, featureSwitch, value: !on })
          }
        })
    }

    if (type == 'global') {
      await request.post(CCP_SET_GLOBAL_FEATURE_SWITCHES, {[featureSwitch]: !on})
        .then((response) => {
          setLoading(false)
          if (response.result.updated) {
            onClick({ featureSwitch, value: !on })
          }
        })
    }
  }
  return (
    <Button
      color={on ? 'green' : 'red'}
      onClick={toggleFeature}
      loading={loading}
    >
      {on ? 'Enabled' : 'Disabled'}
    </Button>
  )
}
