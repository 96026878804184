import React from 'react'
import { observable, reaction, makeObservable } from 'mobx'
import { observer, inject } from 'mobx-react'
import { Form, Input, Item, TextArea } from 'semantic-ui-react'
import { toast } from 'react-toastify'

class Notes extends React.Component {
  loading = false

  state = {
    name: '',
    job: '',
    hobbies: '',
    otherInfo: '',
    dates: ''
  }

  constructor(props) {
    super(props)

    makeObservable(this, {
      loading: observable
    })
  }

  componentDidMount() {
    const { store } = this.props

    // Once data is loaded, add it to local state
    this.dispose = reaction( () => store.conversation && store.conversation.otherUserDetails, details => {
      if(!details) { //no conversation in store
        return
      }
      this.setState({
        name: details.name,
        job: details.job,
        hobbies: details.hobbies,
        otherInfo: details.otherInfo,
        dates: details?.dates || ''
      })
    }, {
      fireImmediately: true,
    })
  }

  componentWillUnmount() {
    // Stop listening to changes
    this.dispose()
  }

  handleSubmit = () => {
    const { actions, store } = this.props

    this.loading = true
    actions.ccp.updateUserData(store.conversation.otherUserUid, {...this.state})
      .then(([, error]) => {
        if(error) {
          toast.error('We couldn\'t save your notes, please try again')
        } else {
          toast.success('Your notes were saved successfully')
        }
        this.loading = false
      })
  }

  handleChange = (e, { name, value }) => {
    this.setState({
      [name]: value
    })
  }

  render() {
    return (
      <Item.Group className="chat-notes">
        <Form size="tiny" onSubmit={this.handleSubmit} loading={this.loading}>
          <Form.Field>
            <label>Name</label>
            <TextArea
              type="text"
              name="name"
              rows="1"
              placeholder="Enter name..."
              value={this.state.name || ''}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Job</label>
            <TextArea
              type="text"
              name="job"
              rows="1"
              placeholder="Enter job..."
              value={this.state.job || ''}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Hobbies</label>
            <TextArea
              type="text"
              name="hobbies"
              rows="1"
              placeholder="Enter hobbies..."
              value={this.state.hobbies || ''}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Dates/Meets</label>
            <TextArea
              type="text"
              name="dates"
              placeholder="Enter dates/meets..."
              value={this.state.dates || ''}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Other info</label>
            <TextArea
              type="text"
              name="otherInfo"
              placeholder="Enter other details..."
              value={this.state.otherInfo || ''}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Button type="submit" size="small" color='facebook' style={{ fontWeight: 'normal' }}>
            Update profile
          </Form.Button>
        </Form>
      </Item.Group>
    )
  }
}

Notes.propTypes = {}

export default inject('actions', 'store')(observer(Notes))
