import React from 'react'
import { observer } from 'mobx-react'
// Components

const WrapperStyle = {
  display: 'flex',
  flexDirection: 'row',
  minHeight: '100vh',
}

const Authorize = ({ children }) => {
  return (
    <div style={WrapperStyle}>
      <div style={{flex: '1'}}>
        <div>
          {children}
        </div>
      </div>
    </div>
  )
}

export default observer(Authorize)
