import React, { useEffect } from 'react'
import { get } from 'lodash'
import styled from 'styled-components'
import ChatWindowAgent from '../components/Chat/ChatWindowAgent'
import { Conversations } from '../components/Chat/Conversations'
import Correspondent from '../components/Chat/Correspondent'
import Impersonator from '../components/Chat/Impersonator'
import ConversationPreview from '../components/Chat/ConversationPreview'
import SiteName from '../components/Chat/elements/SiteName'
import { inject, observer } from 'mobx-react'
import { useState } from 'react'
import { HideConversationsBtn } from '../components/elements/HideButton'

const ConversationInfoContainer = styled.div`
  position: relative;
  background: #eaf1f7;
  padding: 1rem 0.8rem;
  overflow-y: auto;
  border-left: solid 1px lightblue;
  max-width: 400px;
  min-width: 170px;
  height: 100%;
  .ui.input input {
    border: 1px solid #b1c2d8;
    &:focus {
      border: 1px solid saturate(#b1c2d8, 80%);
    }
  }
`

const ChatContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  min-height: 0;
`

const Chat = (props) => {
  const { store, actions, match } = props
  const conversationUid = get(match, 'params.conversationUid')
  const [hideChats, setHideChats] = useState(false)

  useEffect(() => {
    if (conversationUid) {
      actions.chat.setConversation(conversationUid)
    }
  }, [conversationUid])

  useEffect(() => {
    initializeChat()
  }, [])

  const handleHide = () => {
    setHideChats(prevState => !prevState)
  }

  const initializeChat = async() => {
    await Promise.all([
      actions.agent.fetchConfig()
    ])
    actions.chat.fetchAgentConversations()
      .then(async([, error]) => {
        if (!error) {
          if (!conversationUid && !store.conversation && store.conversations[0]) {
            await actions.chat.setConversation(store.conversations[0].conversationUid)
          }
          if (store.conversation) {
            await store.conversation.fetchMessages()
          }

          actions.chat.fetchInteractions()
        }
      })
  }

  const showWidget = window.location.pathname !== 'chat-widget'
  return (
    <ChatContainer>
      <Conversations/>
      {store.conversationPreview.conversationUid
        ? <ConversationPreview/>
        : showWidget && <ChatWindowAgent/>
      }
      {store.conversation && (
        <div className={`${hideChats ? '' : 'conv-open'} conversation-wrapper`}>
          <ConversationInfoContainer className={`${hideChats ? 'conv-hidden' : ''} minimal-scrollbar`}>
            <SiteName siteName={store.conversation.siteName} siteUrl={store.conversation.siteUrl}/>
            <div>
              <Correspondent/>
              <Impersonator conversation={store.conversation}/>
            </div>
          </ConversationInfoContainer>
          <HideConversationsBtn className={`${hideChats ? 'conv-btn-left' : 'conv-btn-right'} hide-menu-btn hide-chat-info-btn`} onClick={() => handleHide()}/>
        </div>
      )}
    </ChatContainer>
  )
}

export default inject('store', 'actions')(observer(Chat))
