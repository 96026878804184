import React from 'react'
import { Icon } from 'semantic-ui-react'

@connect()
class ObjectivesIndicator extends React.Component {

  getObjective(id, i) {
    const { store } = this.props
    const objective = store.objectives.find(r => {
      return r.id === id
    })
    if (objective) {
      return (<Icon
        key={i}
        style={{ color: objective.frontendData.color }}
        name="circle"
        size="big"
        title={objective.name}
      />)
    }
  }

  render() {
    const {agentObjectives} = this.props
    return(
      <div>
        {
          agentObjectives.map((id, i) => {
            return this.getObjective(id,i)
          })
        }
      </div>
    )

  }
}

export default ObjectivesIndicator
