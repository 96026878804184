import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ButtonGroupWrapper = styled.ul`
  margin: 0;
  padding: 0 0;
  list-style-type: none;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props => props.align};
  flex-direction: ${props => props.direction};

  li {
   margin: 2px;
  }
`

export default function ButtonGroup({ align, direction, children }) {
  return (
    <ButtonGroupWrapper align={align} direction={direction}>
      { children.map((button, i) => <li key={i}>{button}</li>)}
    </ButtonGroupWrapper>
  )
}

ButtonGroup.propTypes = {
  align: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'space-between', 'space-evenly']),
  direction: PropTypes.oneOf(['row', 'column'])
}
