/* global CCP_ASSETS_URL */
import { forOwn } from 'lodash'
import { LOGS_SORT_TYPES as SORT_BY } from './constants'
import { imageDomain } from "core/localhost"

function soundUrl(name) {
  try {
    return require(`../assets/sounds/${name}.mp3`)
  } catch (e) {
    return `./assets/sounds/${name}.mp3`
  }
}

// cleans hostname of additional elements, such as www, staging ...
export function cleanHostname(hostname = window.location.hostname) {
  return hostname
    .replace(/www\./g, '')
    .replace(/secure\./g, '')
    .replace(/\.com/g, '')
    .replace(/staging/g, '')
    .replace(/m\./g, '')
    .replace(/\./g, '')
}

export function getCcpAssetsUrl(env) {
  let url = CCP_ASSETS_URL

  if (env === 'MS-test') {
    url = url.replace('staging', 'test')
  }

  return url
}

export function playSound(name, options = {loop: false}) {
  const sound = Object.assign(new Audio(soundUrl(name), options))

  forOwn(options, (value, optionKey) => {
    sound[optionKey] = value
  })

  const soundPromise = sound.play()

  if (soundPromise) {
    soundPromise.catch(error => {
      console.warn(error, 'Sound not played due to browser restrictions')
      _fireOnEnded()
    })
  }
  else {
    _fireOnEnded()
  }

  function _fireOnEnded() { //in order to fire onended callback if sound is not supported
    if (options.onended && typeof options.onended === 'function') {
      options.onended()
    }
  }

  return function() {
    sound.pause()
  }
}

export function simpleHash(str) {
  let hash = 0
  let chr

  if(str.length === 0) {
    return hash
  }
  for (let i = 0; i < str.length; i++) {
    chr   = str.charCodeAt(i)
    hash  = ((hash << 5) - hash) + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

const TEMP_LOWER = 1
const TEMP_UPPER = 1000000

export function generateNotSentId() {
  const random_in_range = Math.floor(Math.random() * (TEMP_UPPER - TEMP_LOWER)) + TEMP_LOWER
  return random_in_range * -1 //temp numbers should be negative and smaller than -1
}

export function isNotSentId(id) {
  return id > (TEMP_UPPER * -1) && id < (TEMP_LOWER * -1)
}

//used to sort conversations stats records for the admin interface
export const LOG_SORTERS = {
  [SORT_BY.DATE_DESC]: (a, b) => (
    a.attachedAt < b.attachedAt ? 1 : a.attachedAt > b.attachedAt ? -1 : 0
  ),
  [SORT_BY.DATE_ASC]: (a, b) => (
    a.attachedAt < b.attachedAt ? -1 : a.attachedAt > b.attachedAt ? 1 : 0
  ),
  [SORT_BY.AGENT]: (a, b) => (
    a.name < b.name ? -1 : a.name > b.name ? 1 : LOG_SORTERS[SORT_BY.DATE_DESC](a, b)
  ),
  [SORT_BY.AGENT_MESSAGES]: (a, b) => (
    a.agentMessages < b.agentMessages ? 1 : a.agentMessages > b.agentMessages ? -1 : LOG_SORTERS[SORT_BY.DATE_DESC](a, b)
  ),
  [SORT_BY.UPGRADE]: (a, b) => {
    const acalc = a.initialSale + a.rebill
    const bcalc = b.initialSale + b.rebill
    return (
      acalc < bcalc ? 1 : acalc > bcalc ? -1 : LOG_SORTERS[SORT_BY.DATE_DESC](a, b)
    )
  },
}

export function truncateName(name, maxLength = 18) {
  if(!name) {
    return ''
  }
  if(name.length > maxLength) {
    return `${name.slice(0, maxLength - 2)}...` //replacing last 2 characters, because 3 dots usually have less width than 3 characters
  }
  return name
}
export const wait = ms => new Promise(resolve => setTimeout(resolve, ms))

export function isPicture(type) {
  return ['image', 'gift', 'sexicon'].includes(type)
}

export function pictureLink(message, conversationUid, whiteLabelUid)
{
  let imageUrl = message
  try {
      new URL(message)
  } catch (err) {
      imageUrl = `${imageDomain}/media/${whiteLabelUid}/${conversationUid.replace(':', '_')}/${message}`
  }
  return imageUrl
}

export function shortenMessage(message, shortenLength = 80) {

  return message && message.length > shortenLength
    ? `${message.slice(0, shortenLength)}...`
    : message
}
