import React from 'react'
import IdleTimer from 'react-idle-timer'
import { UT } from 'core/constants'
import { inject } from 'mobx-react'
import * as Config from 'core/config'
import store from '../../store'

@inject('actions')
class IdleTimerContainer extends React.Component {
  constructor(props) {
    super(props)
  }

  _onActive = async() => {
    const { type, actions } = this.props
    if (type === undefined) {
      return
    }

    switch(type) {
      case UT.AGENT:
        break
      case UT.USER:
        await actions.widget.back()
    }
  }

  _onIdle = async() => {
    const { type, actions } = this.props
    if (type === undefined) {
      return
    }

    switch(type) {
      case UT.AGENT:
        if(store.account.online) {
          store.account.online = false
          store.account.autoBreak = true
          actions.agent.cancelMoreConversations()
          actions.agent.leave(true)
        }
        break
      case UT.USER:
        await actions.widget.leave()
    }
  }

  render() {
    const { type } = this.props

    if (type === UT.ADMIN) {
      return null
    }

    return (
      <div>
        <IdleTimer
          element={document}
          activeAction={this._onActive}
          idleAction={this._onIdle}
          timeout={Config.get('idleTimeout')}
        />
      </div>
    )
  }
}
export default IdleTimerContainer
