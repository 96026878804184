import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import DateTimePicker from 'react-datetime'
import { pick, intersection } from 'lodash'
import moment from 'moment'
import Section from '../UI/Section'
import { Icon, Table, Grid, Form, Button, Pagination, Loader, Select} from 'semantic-ui-react'
import './css/datepicker.css'
import { getQueryStringParams, toQueryString } from 'core/request'
import { LOGS_SORT_TYPES as SORT } from 'core/constants'
import AgentsListDropdown from '../elements/AgentsListDropdown'
import { pictureLink, isPicture, shortenMessage } from '../../../core/helpers'

@boundary()
@connect()
class AgentMessages extends React.Component {

  state = {
    error: undefined,
    confirmOpen: false,
    isLoading: true,
    form: {
      sortBy: SORT.DATE_DESC,
      page: 1,
      perPage: 50,
      from: Date.now() - 1000 * 60 * 60 * 24 * 7, //one week
      to: null,
      agentIds: [],
      messageType: 'all',
    },
    agents: [],
    messages: [],
    total: 0,
  }

  async componentDidMount() {
    const { location } = this.props
    const { form } = this.state
    const queryParams = getQueryStringParams(location.search)
    const queryParamKeys = Object.keys(queryParams)

    const keysToAdd = intersection(Object.keys(form), queryParamKeys)
    this.setState( previousState => ({
        ...previousState,
        form: { ...previousState.form, ...pick(queryParams, keysToAdd) }
      }),
      this.search
    )
  }

  onChange = (varName) => (e, data) => {
    if(e.target && e.target.type === 'text') {
      this.setState({ form: { ...this.state.form, [varName]: e.target.value } })
    }else if(data.icon === 'dropdown') {
      this.setState({ form: { ...this.state.form, [data.name]: data.value } })
    }
  }

  onTimeChange = (varName) => (e) => {
    this.setState({ form: { ...this.state.form, [varName]: (e !== '' && e._d !== undefined) ? e._d.valueOf() : null } })
  }

  handlePaginationChange = async(e, { activePage }) => {
    this.setState( previousState => ({ ...previousState, form: { ...previousState.form, page: activePage } }), this.search)
  }


  search = async(resetActivePage) => {
    const { actions, history } = this.props
    const { form } = this.state
    const page = resetActivePage ? 1 : form.page

    this.setState( previousState => ({
      ...previousState,
      isLoading: true,
      error: null
    }))

    const querySection = toQueryString(Object.keys(form).reduce(formReducer(form, resetActivePage), {}))
    history.push(`/admin/agentMessages${querySection}`)

    const response = await actions.admin.getAgentMessages({ ...this.state.form, page })
    this.setState({ isLoading: false })
    if(!response.messages || response.messages.length === 0) {
      this.setState({ error: response.error || 'No Messages found', messages: [], isLoading: false, form: { ...form, page } })
    }else {
      this.setState({ error: null, isLoading: false, messages: response.messages, total: response.total, form: { ...form, page } })
    }

  }

  sortOptions = [
    { key: 1, text: 'Recent first', value: SORT.DATE_DESC },
    { key: 2, text: 'Oldest first', value: SORT.DATE_ASC },
    { key: 3, text: 'Agent', value: SORT.AGENT },
    { key: 4, text: 'Conversions first', value: SORT.UPGRADE },
    { key: 5, text: 'Most Messages first', value: SORT.AGENT_MESSAGES },
  ]

  messageTypeOptions = [
    { key: 1, text: 'All', value: 'all'},
    { key: 2, text: 'Message', value: 'message'},
    { key: 3, text: 'Image', value: 'image'},
    { key: 4, text: 'Sexicon', value: 'sexicon'},
    { key: 5, text: 'Gift', value: 'gift'},
  ]

  renderPagignation() {
    const { page, perPage } = this.state.form

    return (
      <Grid columns={1}>
        <Grid.Column>
          { <Pagination floated activePage={page} onPageChange={this.handlePaginationChange} totalPages={Math.ceil(this.state.total / perPage)}/> }
        </Grid.Column>
      </Grid>
    )
  }

  render() {
    const { form, messages = [] } = this.state
    return (
      <div>
        <h1 title="AKA: Agent Messages">Chat logs</h1>
        <div className='content' id='stats'>
          <div className='content-body'>
            <Section>
              <Form onSubmit={this.search.bind(this, [true])}>
                <Form.Group widths="equal">
                  <AgentsListDropdown name="agentIds" actions={this.props.actions} onChange={this.onChange('agentsIds')} value={this.state.form.agentIds} multiple={true}/>
                  <Form.Field>
                    <label>From</label>
                    <DateTimePicker onChange={this.onTimeChange('from')} value={form.from ? new Date(form.from) : null}/>
                  </Form.Field>
                  <Form.Field>
                    <label>To</label>
                    <DateTimePicker onChange={this.onTimeChange('to')} value={form.to ? new Date(form.to) : null}/>
                  </Form.Field>
                  <Form.Field>
                    <Form.Field control={Select} label='Sort by' id='sortBy' name='sortBy' value={form.sortBy} onChange={this.onChange('sortBy')} options={this.sortOptions} multiple={false}/>
                  </Form.Field>
                  <Form.Field control={Select} label='Message Type' id='messageType' name='messageType' value={form.messageType} onChange={this.onChange('messageType')} options={this.messageTypeOptions} multiple={false}/>
                  <Form.Field>
                    <Button type='submit' primary fluid style={{height: '100%'}}>
                      Find messages
                    </Button>
                  </Form.Field>
                </Form.Group>
              </Form>
              { this.renderPagignation() }
              <Loader active={this.state.isLoading}/>

              <Table celled striped>
                <Table.Header className="admin-messages-header">
                  <Table.Row>
                    <Table.HeaderCell>Time</Table.HeaderCell>
                    <Table.HeaderCell>Agent</Table.HeaderCell>
                    <Table.HeaderCell>Conversation</Table.HeaderCell>
                    <Table.HeaderCell>Chat by agent</Table.HeaderCell>
                    <Table.HeaderCell>message count/<br/>conversions</Table.HeaderCell>
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>

                  {messages
                    .map((message, i) => {
                      let messageElement = message.message
                      if (isPicture(message.type)) {
                        const url = pictureLink(message.message, `${message.user0Uid}:${message.user1Uid}`, message.whiteLabelUid)
                        messageElement = <a href={url} target="_blank" rel="noreferrer">{shortenMessage(url)}</a>
                      }
                      return (
                        <Table.Row key={i}>
                          <Table.Cell>{moment(message.sentAt).format('MMM DD YYYY HH:mm')}</Table.Cell>
                          <Table.Cell>{message.name}</Table.Cell>
                          <Table.Cell>{`${message.user0Uid} <-> ${message.user1Uid}`}</Table.Cell>
                          <Table.Cell className="admin-messages-messageCell">{messageElement}</Table.Cell>
                          <Table.Cell>{message.agentMessages}/{message.initialSale || '-'}</Table.Cell>
                          <Table.Cell>
                            <Link to={`/admin/conversations/${message.user0Uid}:${message.user1Uid}`}>
                              <Icon name="unhide" size="small"/>
                            </Link>
                          </Table.Cell>

                        </Table.Row>
                      )
                    })
                  }
                </Table.Body>
              </Table>
              { this.renderPagignation() }
              <Grid centered={true}>
                <Grid.Column style={{ display: 'flex', justifyContent: 'center'}}>
                  { this.state.error && <h4>{this.state.error }</h4>}
                </Grid.Column>
              </Grid>
            </Section>
          </div>
        </div>
      </div>
    )
  }
}

const formReducer = (form, resetActivePage) => (obj, key) => {
  if(form[key] !== null && form[key] !== '' && form[key] !== 'null' && form[key] !== undefined &&   form[key] !== false) {
    if(resetActivePage && key === 'page') {
      obj[key] = 1
    } else {
      obj[key] = form[key]
    }
  }
  return obj
}

export default withRouter(AgentMessages)
