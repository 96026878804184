import React, {useState} from 'react'
import sexIcons from '../../../../assets/sexicons.json'
import Lips from '../Icons/Lips'

const SexIconPicker = ({onClickGif}) => {
  const [toggleGallery, setToggleGallery] = useState(true)
  const [toggleHardIcons, setToggleHardIcons] = useState(true)
  const [toggleSoftIcons, setToggleSoftIcons] = useState(false)

  const galleryBtnHandler = () => {
    setToggleGallery(!toggleGallery)
  }

  const iconBtnHandler = () => {
    setToggleHardIcons(!toggleHardIcons)
    setToggleSoftIcons(!toggleSoftIcons)
  }

  const onSelectGif = (e) => {
    setToggleGallery(!toggleGallery)
    onClickGif(e.target)
  }

  return (
    <>
      <div className="sex-icon-wrapper">
        <a className="sex-icon-button" onClick={galleryBtnHandler}><span><Lips/></span> Sexicons </a>
        <div className={`sex-icon-gallery ${toggleGallery ? 'hidden' : ''}`}>
          <div className="sex-icon-hard">
            <div className="sex-icon-header" onClick={iconBtnHandler}><span>Kinky</span> <span className={`${!toggleHardIcons ? 'arrow-up' : 'arrow-down'}`}></span></div>
            <div className={`icon-wrapper ${toggleHardIcons ? 'hidden' : ''}`}>
              {sexIcons.hard.map((item, index) => (
                <div key={index} className="sex-icon" onClick={onSelectGif}><img alt='gif' src={item}/></div>
              ))}
            </div>
          </div>
          <div className="sex-icon-innocent">
            <div className="sex-icon-header" onClick={iconBtnHandler}><span>Innocent</span> <span className={`${!toggleSoftIcons ? 'arrow-up' : 'arrow-down'}`}></span></div>
            <div className={`icon-wrapper ${toggleSoftIcons ? 'hidden' : ''}`}>
              {sexIcons.innocent.map((item, index) => (
                <div key={index} className="sex-icon" onClick={onSelectGif}><img alt='gif' src={item}/></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>

  )
}
export default SexIconPicker
