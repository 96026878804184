import React from "react"

const PeaceSign = ({ isClicked }) => (
  <svg
    viewBox="0 0 20 20"
    width="20"
    height="20"
    className={isClicked ? "emoji-highlighted" : ""}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.25 2.537a7.5 7.5 0 0 0-5.483 11.636L9.25 8.689zm0 14.926a7.48 7.48 0 0 1-4.523-2.13l4.523-4.522zm6.983-3.29A7.5 7.5 0 0 0 10.75 2.537v6.151zm-.96 1.16a7.48 7.48 0 0 1-4.523 2.13v-6.652zM10 1a9 9 0 1 0 0 18 9 9 0 0 0 0-18"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default PeaceSign