import React from 'react'
import PropTypes from 'prop-types'
import { Button, Confirm, Form, Modal } from 'semantic-ui-react'
import { getDebugConversations, getUserData } from './helpers'

class ModalBaseClass extends React.Component {

  state = {
    isOpen: false,
    conversations: [],
    userData: {},
  }

  handleOpen = async() => {
    this.setState({
      isOpen: true,
    }, async() => {
      await Promise.all([this.fetchConversations(), this.fetchUserData()])
    })
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  }

  handleConfirm = async() => {
    this.onConfirm()
    this.setState({ confirm: false, isOpen: false })
  }

  handleCancel = () => {
    this.setState({ confirm: false })
  }

  fetchConversations = async() => {
    this.setState({
      conversations: await getDebugConversations(this.props.userUid)
    })
  }

  fetchUserData = async() => {
    this.setState({
      userData: await getUserData(this.props.userUid)
    })
  }

  get renderBody() {

    const ModalBase = ({ headerText, triggerButtonText, confirmButtonText, children }) => {
      const { confirm } = this.state
      return (
        <Modal
          open={this.state.isOpen}
          onClose={this.handleClose}
          trigger={<Button
            onClick={this.handleOpen}
            disabled={this.props.disabled}>
            {triggerButtonText}
          </Button>}>
          <Modal.Header>{headerText}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form>
                {children}
                <Confirm
                  open={confirm}
                  onCancel={this.handleCancel}
                  onConfirm={this.handleConfirm}
                />
                <Button type="button" color='red' onClick={() => this.setState({ confirm: true })}>
                  {confirmButtonText}
                </Button>
              </Form>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      )
    }

    ModalBase.propTypes = {
      headerText: PropTypes.string.isRequired,
      triggerButtonText: PropTypes.string.isRequired,
      confirmButtonText: PropTypes.string.isRequired,
      children: PropTypes.any.isRequired,
    }

    return ModalBase
  }
}

export default ModalBaseClass
