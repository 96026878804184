import React, {useState} from 'react'
import Button from "../../elements/Button"
import {Notification} from "../Settings/Notification"
import actions from "../../../actions"

export const Overview = () => {

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const clearCache = async() => {
    setLoading(true)
    const result = await actions.admin.clearCache()

    if (result[0].status === 'success') {
      setSuccess(true)
    } else {
      setError(true)
    }

    setLoading(false)

    setTimeout(() => {
      setSuccess(false)
      setError(false)
    }, 3000)
  }

  return (
    <div>
      <h2> Clear Cache: </h2>
      <Notification state={(success && 'cacheSuccess') || (error && 'cacheError')}/>
      <Button loading={loading} onClick={clearCache}>Clear Cache</Button>
    </div>
  )
}
