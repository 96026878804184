import React, { useState, useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { Form, Item, TextArea } from 'semantic-ui-react'
import { LOCALSTORAGE_CHATBOT_KEY, CHAT_BOT_LOGIN, CHAT_BOT_MESSAGE_SEND } from '../../../core/constants'
import { setSessionToken, getSessionToken } from '../../actions/Auth'
import { toast } from 'react-toastify'

const ChatBot = ({ store }) => {
  const SESSION_LENGTH_IN_HOURS = 24

  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState('')
  const [message, setMessage] = useState('')
  const [response, setResponse] = useState('')

  useEffect(async() => {
    setToken(await getToken('It will retry during message generating process.'))
  }, [])

  useEffect(() => {
    setMessage(store.conversation.lastMessageOfOtherUser)
    setResponse('')
  }, [store.conversation.lastMessageOfOtherUser])

  const handleSubmit = async() => {
    setResponse('Generating...')
    setLoading(true)
    let sessionToken = token
    if (!sessionToken) {
      sessionToken = await getToken('Refresh the page.')
      if (!sessionToken) {
        return
      }
      setToken(sessionToken)
    }
    setResponse(await getResponse(sessionToken))
    setLoading(false)
  }

  const handleChange = (e, { name, value }) => {
    if (name === 'message') {
      setMessage(value)
    } else if (name === 'response') {
      setResponse(value)
    }
  }

  async function getToken(toastMessage) {
    let session = getSessionToken(LOCALSTORAGE_CHATBOT_KEY)
    let sessionToken = session.token

    const now = new Date().getTime()
    const sessionExpiration = session.expiration ?? 0
    if(now > sessionExpiration) {
      const botAccount = {
        username: process.env.BOT_USER,
        email: process.env.BOT_EMAIL,
        password: process.env.BOT_PASS,
      }
      const loginRequestData = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(botAccount)
      }
      try {
        sessionToken = (await (await fetch(CHAT_BOT_LOGIN, loginRequestData)).json()).token
        if(!sessionToken) {
          throw new Error('Token is missing')
        }
        setSessionToken({token: sessionToken, expiration: now + (SESSION_LENGTH_IN_HOURS * 60 * 60 * 1000)}, LOCALSTORAGE_CHATBOT_KEY)
      } catch (error) {
        console.error('Error: ', error)
        toast.error(`ChatBot login is not succesfull. ${toastMessage}`)
        setLoading(false)
      }
    }
    return sessionToken
  }

  async function getResponse() {
    const conversation = store.conversation
    const messageSendData = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        participant_r_id: conversation.otherUserUid,
        participant_v_id: conversation.meUid,
        participant_r_profile: prepareProfile(
          conversation.otherUserProfile,
          await conversation.getClassifiers(conversation.otherUserProfile.userUid),
          conversation.otherUserLastSentAt
        ),
        participant_v_profile: prepareProfile(
          conversation.meProfile,
          await conversation.getClassifiers(conversation.meProfile.userUid)
        ),
        message_content: message
      })
    }
  
    let responseContent = ''
    const unknownResponse = 'No Bot Response. Retry.'
    try {
      responseContent = (await (await fetch(CHAT_BOT_MESSAGE_SEND, messageSendData)).json()).bot_msg?.content
      if(!responseContent) {
        throw new Error(unknownResponse)
      }
    } catch (error) {
      console.error('Error: ', error)
      toast.error(unknownResponse)
    }
    return responseContent
  }

  function prepareProfile(profile, classifiers, timestamp) {
    const datetime = timestamp ? new Date(timestamp) : new Date()
    const formattedDate = (new Intl.DateTimeFormat('en-US', {weekday: 'long', day: 'numeric', month: 'long'})).format(datetime)
    return {
      //required
      location: profile.location,
      location_date: formattedDate,
      username: profile.username,
      //non-required
      self_description: profile.profileText,
      gender: profile.gender,
      looking_for: classifiers.LookingForWhat?.split(', '),
      my_characteristics: classifiers.MyCharacteristics?.split(', '),
      favorite_activities: classifiers.FavoriteActivities?.split(', '),
      what_turns_me_on: classifiers.WhatTurnsMeOn?.split(', '),
      religion: classifiers.Religiton,
      profession: classifiers.Profession,
      marital_status: classifiers.MaritalStatus,
      race: classifiers.Race,
      height: classifiers.Height,
      hair_color: classifiers.HairColor,
      eye_color: classifiers.EyeColor,
      body_type: classifiers.BodyType,
      drinking: classifiers.Drinking,
    }
  }

  return (
    <Item.Group className="chat-notes">
      <Form size="tiny" onSubmit={handleSubmit} loading={loading}>
        <Form.Field>
          <label>Customer message</label>
          <TextArea
            type="text"
            name="message"
            placeholder="Enter message..."
            value={message || ''}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Bot response</label>
          <TextArea
            type="text"
            name="response"
            placeholder="Press generate to get a response"
            value={response || ''}
            rows={15}
          />
        </Form.Field>
        <Form.Button type="submit" size="small" color='facebook' style={{ fontWeight: 'normal' }}>
          Generate
        </Form.Button>
      </Form>
    </Item.Group>
  )
}

export default inject('store')(observer(ChatBot))
