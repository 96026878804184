import { chatBotEndpoint } from './localhost'

export const NEW_MESSAGE = 'message-service:newMessage'
export const SYSTEM_NOTIFICATION = 'message-service:systemNotification'
export const NEW_CONVERSATION = 'message-service:newConversation'
export const NEW_CONVERSATION_AGENT = 'ccp-service:newConversation'
export const NEW_CONVERSATIONS_AGENT = 'ccp-service:newConversations'
export const ACCOUNT_UPGRADED = 'ccp-service:accountUpgraded'
export const TYPING = 'ccp-interface:typing'
export const USER_LEAVE = 'ccp-service:userLeave'
export const USER_BACK = 'ccp-service:userBack'
export const RECLAIM_CONVERSATION = 'ccp-service:reclaimConversation'
export const DETACH_CONVERSATION = 'ccp-service:detachConversation'
export const AGENT_LEFT = 'ccp-service:leaveEvent'
export const AGENT_LOGGED_IN = 'ccp-service:loggedIn'
export const AGENT_FORCE_LOGOUT = 'ccp-service:agentForceLogout'
export const DOWNLOAD_STATS = 'log-service:downloadStats'
export const AGENT_STATS = 'log-service:agentStats'
export const USER_DELETED = 'ccp-service:userDeleted'
export const ON_PROFILE_LIKED = 'ccp-service:onProfileLiked'
export const ON_PROFILE_VIEW = 'ccp-service:onProfileViewed'
export const ON_PROFILE_MATCH = 'ccp-service:onProfileMatched'
export const ON_PHOTO_REQUEST = 'ccp-service:onPhotoRequest'
export const MARK_AS_READ = 'ccp-service:markAsRead'

export const UT = {
  ADMIN: 'admin',
  AGENT: 'agent',
}

export const OFFLINE = 'offline'
export const ONLINE = 'online'

export const LOCALSTORAGE_SESSION_KEY = 'auth'
export const LOCALSTORAGE_CHATBOT_KEY = 'chatBot'

export const MESSAGE_TYPE_WARNING = 'warning'
export const MESSAGE_TYPE_UPGRADE = 'upgradeRequest'
export const MESSAGE_TYPE_DEFAULT = 'message'
export const MESSAGE_TYPE_IMAGE = 'image'
export const MESSAGE_TYPE_SEXICON = 'sexicon'
export const MESSAGE_TYPE_GIFT = 'gift'
export const MESSAGE_TYPE_SYSTEM  = 'systemNotification'
export const MESSAGE_TYPE_PRESENCE  = 'presenceNotification'
export const MESSAGE_TYPE_CONTACT_INFO  = 'contactInfoDetected'
export const PENDING_MESSAGE_ID = 9999999999

export const OBJECTIVE_UPGRADE = 'Upgrade to Premium'
export const OBJECTIVE_CREDITS = 'Sell credits'
export const OBJECTIVE_REBILLING = 'Activate rebilling'

export const LOGS_SORT_TYPES = {
  DATE_DESC: 'dateDesc',
  DATE_ASC: 'dateAsc',
  AGENT: 'agent',
  UPGRADE: 'upgrade',
  AGENT_MESSAGES: 'agentMessages',
}

export const CHAT_BOT_LOGIN = `${chatBotEndpoint}/auth/generate-token`
export const CHAT_BOT_MESSAGE_SEND = `${chatBotEndpoint}/message-send`
