import React, { useState } from 'react'
import { Form, Grid, Pagination, Select } from 'semantic-ui-react'

const TablePagination = ({ activePage = 1, amount, pageSizes = [2, 25, 50, 100], defaultPageSize = 2, onPageChange = () => {}, onPageSizeChange = () => {} }) =>  {

  const itemsPerPage = pageSizes.map((size, key) => ({ key, text: `${size} per page`, value: size }))

  const [perPage, setPerPage] = useState(defaultPageSize || pageSizes[0].value)

  const onChange = (e, { value }) => {
    setPerPage(value)
    onPageSizeChange(value)
  }

  return (
    <Grid columns={1}>
      <Grid.Column>
        { amount > perPage && <Pagination floated activePage={activePage} onPageChange={onPageChange} totalPages={Math.ceil(amount / perPage)}/> }
        <Form.Field control={Select} compact={true} id='perPage' name='perPage'  value={perPage} onChange={onChange} options={itemsPerPage}/>
      </Grid.Column>
    </Grid>
  )
}

export default TablePagination
