import { action, toJS, makeObservable } from 'mobx'
import { orderBy, get, pick } from 'lodash'
import { toast } from 'react-toastify'
import * as Config from 'core/config'
import Conversation from '../models/Conversation'
import store from '../store'
import sessionxtd from 'core/sessionxtd'
import { ccpProvider} from 'core/CcpProvider'

const parseBoolean = x => x == 'true' || x == '1'

const getUserDetails = async(conv) => {
  const [otherUser] = await ccpProvider.api.chat.getUserData(conv.otherUserUid, conv.identifier)
  conv.otherUserProfile.isPremium = parseBoolean(otherUser.isPremium)
  conv.otherUserProfile.rebillEnabled = parseBoolean(otherUser.isPremium)  && parseBoolean(otherUser.rebillEnabled)
  conv.otherUserProfile.isFree = parseBoolean(otherUser.isFree)
  conv.otherUserProfile.expiryDate = otherUser.expiryDate
  conv.otherUserProfile.isFeatured = parseBoolean(otherUser.isFeatured)
  conv.otherUserProfile.blocked = parseBoolean(otherUser.blocked)
}

export default class Chat {
  constructor() {
    makeObservable(this, {
      initWebSocket: action,
      fetchAgentConversations: action,
      fetchInteractions: action,
      addConversation: action,
      markConversationAsPremium: action,
      userToggledRebill: action,
      markConversationAsNotPremium: action,
      markConversationAsBlocked: action,
      markConversationAsUnblocked: action,
      setInteractionLike: action,
      setInteractionMatch: action,
      setInteractionView: action,
      setInteractionPhotoRequest: action,
      setConversation: action,
      removeConversationsDeletedUser: action,
      getTimezone: action,
      startLocalTimesClock: action,
      readConversation: action
    })
  }

  async initWebSocket() {
    store.socket = await ccpProvider.initWebsocket()
    store.socket.connect()
  }

  findByUid(uid) {
    return store.conversations.find( conv => (conv.conversationUid === uid))
  }

  findByUserUids(uid0, uid1) {
    return store.conversations.find( conv => (
      (conv.meUid === uid0 && conv.otherUserUid === uid1) ||
      (conv.otherUserUid === uid0 && conv.meUid === uid1) ||
      (conv.conversationUid === `${uid0}:${uid1}`) ||
      (conv.conversationUid === `${uid1}:${uid0}`)
    ))
  }

  async fetchAgentConversations() {
    // If we're an agent, hide normal conversations
    const filterConversations = x => (store.account.type === 'agent') ? x.type === 'virtual' : x

    // Gets initial conversations that we can pick from
    const [response, err] = await ccpProvider.api.chat.fetchConversations()
    if(err) {
      return [null, err]
    }
    const conversations = response.filter(filterConversations).map(c => new Conversation(c))

    if (!store.conversationUid) {
      const uid = topConv(conversations)
      if (uid) {
        this.setConversation(uid)
      }
    }
    if (conversations.length) {
      store.conversations.replace(conversations)
    }
    return [response, null]
  }
  async fetchInteractions(conversation) {
    const params = conversation ? { user0Uid: conversation.meUid, user1Uid: conversation.otherUserUid, whiteLabelUid: conversation.identifier } : { me: true }

    //set loading state of the interactions for all conversations
    store.conversations.forEach(c => (c.interactions.loading = true))

    return ccpProvider.api.chat.fetchInteractions(params)
      .then(([response, err]) => {
        //set loading state of the interactions for all conversations
        if(err) {
          store.conversations.forEach(c => {
            c.setInteractions({ loading: false, error: true })
          })
          toast.error('Interactions could not be fetched. ')
          return [null, err]
        }
        if(response && response.length) {
          if(params.me && response.some(i => i.error)) {
            const errors = response.filter(i => i.error)
            const conversationUids = store.conversations.map(x => x.conversationUid)
            if(errors.filter(x => conversationUids.includes(x)).length) {
              toast.error('Some interactions could not be fetched.')
            }
          }

          const interactions = response.reduce((x, interaction) => ({ ...x, [interaction.conversationUid]: interaction }), {})
          store.conversations.forEach(c => {
            const interaction = interactions[c.conversationUid]
            if(interaction) {
              c.setInteractions({ ...interaction, loading: false, error: interaction.error })
            }
          })
        }
        return [response]
      })
  }

  async addConversation(data, isEmbedded) {
    let conv = this.findByUid(data.conversationUid)
    if (!conv) {
      conv = this.findByUserUids(data.user0Uid || data.otherUserUid, data.user1Uid || data.meUid)
    }

    if (!conv) {
      conv = new Conversation(data, isEmbedded)
      await conv.getCredits()

      // maybe a duplicate appeared during the credits call
      let duplicateConv = this.findByUid(data.conversationUid)
      if (!duplicateConv) {
        duplicateConv = this.findByUserUids(data.user0Uid || data.otherUserUid, data.user1Uid || data.meUid)
      }

      if (!duplicateConv) {
        conv.isNewAttached = true
        store.conversations.push(conv)
      }
    }

    await conv.fetchMessages(null, true)
  }

  async markConversationAsPremium(data) {
    const conv = store.conversations.find(c => c.conversationUid === data.conversationUid)
    if (conv) {
      await conv.getCredits()
      await getUserDetails(conv)
    }
    return conv
  }

  async userToggledRebill(data) {
    const conv = store.conversations.find(c => c.conversationUid === data.conversationUid)
    if (conv) {
      await getUserDetails(conv)
    }
    return conv
  }

  async markConversationAsNotPremium(data) {
    const conv = store.conversations.find(c => c.conversationUid === data.conversationUid)
    if (conv) {
      await getUserDetails(conv)
    }
    return conv
  }

  async markConversationAsBlocked(data) {
    const conv = store.conversations.find(c => c.conversationUid === data.conversationUid)
    if (conv) {
      conv.remainingMessages = 0
      conv.otherUserProfile.blocked = true
    }
    return conv
  }

  async contactInfoDetected(data) {
    if (store.user) {
      store.user.secondBlock = false
    }
    const conversation = store.conversations.find(c => c.conversationUid === data.conversationUid)

    if (conversation) {
      conversation.otherUserProfile.blocked = false
    }

    return conversation
  }

  markConversationAsUnblocked(data) {
    if(store.user) {
      store.user.secondBlock = false
    }
    const conv = store.conversations.find(c => c.conversationUid === data.conversationUid)
    if (conv) {
      conv.otherUserProfile.blocked = false
    }
    return conv
  }

  setInteractionLike(conversationUid, { likedByUserUid }, state = true) {
    const conversation = store.conversations.find(c => c.conversationUid === conversationUid)
    if(conversation) {
      conversation.interactions[`${likedByUserUid === conversation.meUid ? 'me' : 'otherUser'}Liked`] = state
    }
    return conversation
  }

  setInteractionMatch(conversationUid, state = true) {
    const conversation = store.conversations.find(c => c.conversationUid === conversationUid)
    if(conversation) {
      conversation.interactions.matched = state
    }
    return conversation
  }

  setInteractionView(conversationUid, { viewedByUserUid }, viewedAt = 0) {
    const conversation = store.conversations.find(c => c.conversationUid === conversationUid)
    if(conversation) {
      conversation.interactions[`${viewedByUserUid === conversation.meUid ? 'me' : 'otherUser'}Viewed`] = viewedAt
    }
    return conversation
  }

  setInteractionPhotoRequest(conversationUid, { requestedByUserUid }, state = true) {
    const conversation = store.conversations.find(c => c.conversationUid === conversationUid)
    if(conversation) {
      conversation.interactions[`${requestedByUserUid === conversation.meUid ? 'me' : 'otherUser'}RequestedPhoto`] = state
    }
    return conversation
  }

  setConversation(conversationUid, oldConversationUid) {
    store.conversationPreview.conversationUid = null

    if(oldConversationUid) {
      const oldConversation = store.conversations.find(c => c.conversationUid === oldConversationUid)
      if (oldConversation && oldConversation.userDeleted) {
        store.conversations.replace(store.conversations.filter(c => c.conversationUid !== oldConversationUid))
      }
    }
    if (store.conversationUid !== conversationUid) {
      store.conversationUid = conversationUid
    }
  }

  removeConversationsDeletedUser() {
    const activeConversations = store.conversations.filter(c => store.conversationUid === c.conversationUid || !c.userDeleted)
    store.conversations.replace(activeConversations)
  }

  async getTimezone(conversationUid, user) {
    const conv = store.conversations.find(c => c.conversationUid === conversationUid)

    if(!conv) {
      return [null, true]
    }

    const hasInsufficientData = (data, requiredPropCount) => Object.values(data).some(v => !v) || Object.values(data).length < requiredPropCount

    if(!conv[`${user}Profile`].timezone) {
      const data = {
        ...pick(conv[`${user}Profile`], ['country', 'state', 'city']),
      }
      const insufficientDataOtherUser = hasInsufficientData(data, 3)
      if(insufficientDataOtherUser) {
        return [null, 'notFound']
      } else {
        const [timezone, err] = await ccpProvider.api.chat.getTimezone(data)
        conv[`${user}Profile`].timezone = err ? null : timezone
        return [timezone, err]
      }
    }


  }


  //TODO investigate and switch to timezomeoffset
  startLocalTimesClock() {
    if (!store.ticker) {
      store.ticker = setInterval(() => {
        store.conversations.forEach((c) => {
          if (c.otherUserProfile.localTime && c.otherUserProfile.localTime !== 'notFound') {
            c.otherUserProfile.localTime.minutes++
            if (c.otherUserProfile.localTime.minutes === 60) {
              c.otherUserProfile.localTime.minutes = 0
              c.otherUserProfile.localTime.hours++
              if (c.otherUserProfile.localTime.hours === 24) {
                c.otherUserProfile.localTime.hours = 0
              }
            }
          }
          if (c.meProfile.localTime && c.meProfile.localTime !== 'notFound') {
            c.meProfile.localTime.minutes++
            if (c.meProfile.localTime.minutes === 60) {
              c.meProfile.localTime.minutes = 0
              c.meProfile.localTime.hours++
              if (c.meProfile.localTime.hours === 24) {
                c.meProfile.localTime.hours = 0
              }
            }
          }
        })
      }, 60 * 1000)
    }
  }

  readConversation(conversation) {
    const index = store.conversations.findIndex(c => c.conversationUid === conversation.conversationUid)
    store.conversations[index].unreadByMeCount = 0
    store.conversations[index].isNewAttached = false
    store.conversations[index].meReadAt = parseInt(Date.now() / 1000)
    const identifier = get(store, 'conversation.identifier', Config.get('identifier')) // in order to work for both agent and widget
    ccpProvider.api.ccp.readConversation({ conversationUid: conversation.conversationUid, senderUid: conversation.meUid }, { identifier })
  }

  sendContactInfoNotification(conversationId) {
    return ccpProvider.api.chat.sendContactInfoNotification(conversationId)
  }

  updateConversationCache = (conv) => {
    const cacheKey = ccpProvider.api.generateCacheKey(ccpProvider.api.widget.endpoints.WIDGET_GET_CONVERSATIONS, { userUid: Config.get('userUid') })
    const { item, expiration } = sessionxtd.getItem(cacheKey, true) || {}
    if (item) {
      const index = item.conversations.findIndex(c => c.conversationUid === conv.conversationUid)
      if (index >= 0) { // existing conv
        item.conversations[index] = { ...item.conversations[index], ...conv }
      } else { // new conv
        item.conversations.push(conv)
      }
      sessionxtd.setItem(cacheKey, { ...item, conversations: item.conversations }, 0, expiration)
    }
  }
}

const topConv = (conversations) => {
  const now = parseInt(Date.now() / 1000)
  const timePrecision = (3600 * 24 * 2)

  if (conversations.length === 0) {
    return false
  }

  const convs = conversations.map(conversation => {
    // Getters are not allowed to modify observable values, so we create a clone
    const conv = toJS(conversation)

    // Round to nearest day
    if ((now - conv.otherUserLastSentAt) > timePrecision) {
      conv.otherUserLastSentAt = conv.otherUserLastSentAt - parseInt(conv.otherUserLastSentAt % timePrecision)
    }
    return conv
  })

  const orderedConversations =  orderBy(convs, ['lastMessageSentAt'], ['asc'])
  const unansweredConversations = orderedConversations.filter( c => {
    return c.lastMessageSentAt === c.otherUserLastSentAt
  })
  if (unansweredConversations.length > 0) {
    return unansweredConversations[0].conversationUid
  }
  const answeredVisibleConversations = orderedConversations.filter ( c => {
    return c.lastMessageSentAt != 0
  })
  if (answeredVisibleConversations.length === 0) {
    return false
  }
  return answeredVisibleConversations[0].conversationUid
}
