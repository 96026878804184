import React, { Fragment } from 'react'
import { Button, Icon } from 'semantic-ui-react'

class GoogleMapsBtn extends React.Component {
  constructor(props) {
    super (props)
  }

  getLink() {
    const { location } = this.props
    const base = 'https://www.google.com/maps/search/?api=1'
    if(location) {
      return `${base}&query=${encodeURIComponent(location)}`
    }
    return null
  }

  openLink = (link) => {
    window.open(link, '_blank')
  }

  render() {
    const link = this.getLink()
    return (
      <Fragment>
        { link &&
        <Button basic icon color='blue' onClick={this.openLink.bind(this, link)} style={{margin: '0', padding: '8px'}} title='Google Maps'>
          <Icon name='map'/>
        </Button> }
      </Fragment>
    )
  }
}

export default GoogleMapsBtn
