import React from 'react'

const Lips = () => (
  <svg id="Group_23" data-name="Group 23" xmlns="http://www.w3.org/2000/svg"
       width="17.47" height="18.344" viewBox="0 0 17.47 18.344">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_14" data-name="Rectangle 14" width="17.47" height="18.344" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-3">
        <rect id="Rectangle_10" data-name="Rectangle 10" width="4.907" height="2.128" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-4">
        <rect id="Rectangle_11" data-name="Rectangle 11" width="0.798" height="1.078" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-5">
        <rect id="Rectangle_12" data-name="Rectangle 12" width="0.446" height="0.329" fill="none"/>
      </clipPath>
    </defs>
    <g id="Group_22" data-name="Group 22" clipPath="url(#clip-path)">
      <g id="Group_21" data-name="Group 21" transform="translate(0 0)">
        <g id="Group_20" data-name="Group 20" clipPath="url(#clip-path)">
          <path id="Path_1" data-name="Path 1"
                d="M17.415,9.534a16.307,16.307,0,0,0-.441-1.99A9.7,9.7,0,0,0,15.331,1.6a1.291,1.291,0,0,0-1.871-.364,5.749,5.749,0,0,0-1.6-1.2c-.687-.249-2.944.911-2.944.911C6.755-.378,5.823.131,5.823.131,1.894,1.22.149,9.749.149,9.749c-.012.059-.02.113-.027.164s-.037.068-.063.135C0,10.181.027,10.14,0,10.214c.172,7.987,8.785,8.87,11.723,7.721,2.967-1.161,3.691-1.995,4.522-3.311a8.7,8.7,0,0,0,1.218-4.8"
                transform="translate(0 0)" fill="#231f20"/>
          <path id="Path_2" data-name="Path 2"
                d="M13.232,16.29c-.242-.053-1.662-.361-2.553-.559-.63-.139-1.709-.294-2.424-.4-.245-.035-.479-.068-.59-.087A2.921,2.921,0,0,0,6.227,14.02a.571.571,0,0,1,.1-.281.142.142,0,0,0-.1-.222,10.138,10.138,0,0,1,.852-.624,13.8,13.8,0,0,1,5.682-2.026.159.159,0,0,0,.037-.01A7.629,7.629,0,0,0,14.3,10,5.555,5.555,0,0,0,15.607,8.65a4.967,4.967,0,0,0,.51-.974A4.735,4.735,0,0,1,18,5.215a1.459,1.459,0,0,1,.688-.2,1.041,1.041,0,0,1,.882.522,9.022,9.022,0,0,1,1.521,5.934,5.641,5.641,0,0,1-.422,1.594c-.023.079-.042.138-.061.2h0c-.03.092-.067.188-.109.286a.238.238,0,0,0-.158.145,2.412,2.412,0,0,1-.548.426.144.144,0,0,0-.024.02,2.976,2.976,0,0,1-1.547.974,1.107,1.107,0,0,0-.126-.005,6.23,6.23,0,0,0-2.082.551,1.543,1.543,0,0,0-.3-.041A9.761,9.761,0,0,0,19.867,8.4a.141.141,0,0,0-.126-.146h-.015a.142.142,0,0,0-.139.117,10.8,10.8,0,0,1-5.667,7.72Z"
                transform="translate(-4.465 -3.599)" fill="#b13932"/>
          <path id="Path_3" data-name="Path 3"
                d="M17.51,4.8h0a.908.908,0,0,1,.765.459,8.878,8.878,0,0,1,1.5,5.838,5.493,5.493,0,0,1-.411,1.554.281.281,0,0,0-.014.04q-.023.085-.052.173c-.023.071-.05.144-.081.218a.375.375,0,0,0-.172.184,2.726,2.726,0,0,1-.5.38.277.277,0,0,0-.049.038,3.047,3.047,0,0,1-1.444.932c-.042,0-.086,0-.13,0a5.657,5.657,0,0,0-1.831.436,10,10,0,0,0,3.748-7.009.283.283,0,0,0-.253-.291l-.03,0a.283.283,0,0,0-.278.233A10.5,10.5,0,0,1,12.7,15.59l-.651.2c-.322-.07-1.663-.362-2.517-.551-.635-.141-1.718-.3-2.434-.4-.191-.027-.386-.055-.511-.074A2.988,2.988,0,0,0,5.211,13.58a.392.392,0,0,1,.055-.118.283.283,0,0,0,.014-.3c.222-.175.457-.344.7-.5a13.66,13.66,0,0,1,5.624-2,.287.287,0,0,0,.073-.021,7.768,7.768,0,0,0,1.533-.875A5.683,5.683,0,0,0,14.55,8.371a5.056,5.056,0,0,0,.525-1A4.591,4.591,0,0,1,16.89,4.981,1.323,1.323,0,0,1,17.51,4.8m0-.283a1.6,1.6,0,0,0-.755.213,4.868,4.868,0,0,0-1.945,2.535,4.742,4.742,0,0,1-.5.946,5.385,5.385,0,0,1-1.271,1.314,7.467,7.467,0,0,1-1.477.843,13.893,13.893,0,0,0-5.739,2.048,9.892,9.892,0,0,0-1.238.956,4.316,4.316,0,0,0,.447-.074.741.741,0,0,0-.126.453c.25.11,1.27.593,1.474,1.259.008.025,2.113.283,3.09.5s2.59.567,2.59.567l.746-.224a10.861,10.861,0,0,0,5.743-7.822A9.453,9.453,0,0,1,13.87,15.5a2.188,2.188,0,0,1,.6-.092,1.406,1.406,0,0,1,.381.05,6.352,6.352,0,0,1,2.069-.559q.07,0,.137.005a3.014,3.014,0,0,0,1.635-1.015,2.636,2.636,0,0,0,.6-.484c.025-.052.049-.07.069-.07a.057.057,0,0,1,.042.028,3.582,3.582,0,0,0,.163-.408h0c.02-.063.04-.125.056-.186a5.768,5.768,0,0,0,.433-1.634A9.213,9.213,0,0,0,18.51,5.1a1.181,1.181,0,0,0-1-.584"
                transform="translate(-3.291 -3.24)" fill="#231f20"/>
          <path id="Path_4" data-name="Path 4"
                d="M18.015,34.805a5.366,5.366,0,0,0-1.085.408,2.554,2.554,0,0,1-.6.529s-.9.955-1.543.981a13.968,13.968,0,0,0-2.33.582A.983.983,0,0,1,12.2,37.3c-1.156.288-2.24.564-2.331.577-.051.007-.11.02-.185.03-.048.012-.083.02-.1.022a.411.411,0,0,1-.077-.012,2.813,2.813,0,0,1-.927-.2c-.054-.019-.123-.04-.2-.063-.373-.087-.8-.187-1.251-.286-1.018-.208-2.239-.423-2.791-.519a2.944,2.944,0,0,0-.5-.02,7.089,7.089,0,0,0-1.4-1.233l0,0-.081-.038a6.746,6.746,0,0,0-.682-.28c-.216.273-.274.187-.234-.063-.054-.011-.1-.022-.136-.032s-.058-.007-.085-.008c.018.3.047.589.084.859.007.048.013.1.02.143A7.07,7.07,0,0,0,2.7,39.711c2.52,4.042,8.5,3.176,8.5,3.176,6.878-1.218,6.868-7.043,6.81-8.082"
                transform="translate(-0.869 -24.957)" fill="#fa5442"/>
          <path id="Path_5" data-name="Path 5"
                d="M2.313,10.851A9.892,9.892,0,0,1,3.551,9.9,15.42,15.42,0,0,1,4.674,8.222C4.986,7.91,5.68,7.167,6.431,6.543a5.92,5.92,0,0,1,1.227-.826,4.023,4.023,0,0,1,2.21-.349l.064,0h.037a9.338,9.338,0,0,1,2.07.319,4.756,4.756,0,0,0,.5-.946A4.868,4.868,0,0,1,14.479,2.21a2.406,2.406,0,0,0-1.767-1.094,6.965,6.965,0,0,0-2.7.822c-.045,0-.094,0-.147-.007a5.976,5.976,0,0,0-1.839-.747,1.7,1.7,0,0,0-1.838.178C5.95,1.519,4.44,2.3,2.618,6.777c0,0-.338,1.405-.521,2.03a7.959,7.959,0,0,1-.625,1.848c-.023.028-.041.052-.058.074a2.15,2.15,0,0,0,.9.122"
                transform="translate(-1.015 -0.719)" fill="#fa5442"/>
          <path id="Path_6" data-name="Path 6"
                d="M12.587,5.071a.21.21,0,0,0,.31.1A12.52,12.52,0,0,1,15.18,3.605c.818-.238.476-.768,0-.721a5.021,5.021,0,0,0-2.6,2.187"
                transform="translate(-9.025 -2.066)" fill="#fff"/>
          <path id="Path_7" data-name="Path 7"
                d="M11.446,11.565s.176-.254.276-.071-.164.359-.294.383c-.1.019-.059-.188.018-.312"
                transform="translate(-8.152 -8.194)" fill="#fff"/>
          <path id="Path_8" data-name="Path 8" d="M36.581,10.272A2.2,2.2,0,0,0,38.132,8.3a3.606,3.606,0,0,1-1.551,1.974"
                transform="translate(-26.23 -5.95)" fill="#231f20"/>
          <path id="Path_9" data-name="Path 9" d="M43.927,13.792s1.521-.18,1.748-1.354a2.425,2.425,0,0,1-1.748,1.354"
                transform="translate(-31.497 -8.919)" fill="#231f20"/>
          <path id="Path_10" data-name="Path 10"
                d="M22.285,11.832a2.177,2.177,0,0,1-1.677-1.6A1.855,1.855,0,0,0,22.285,11.9Z"
                transform="translate(-14.777 -7.34)" fill="#231f20"/>
          <path id="Path_11" data-name="Path 11" d="M14.86,11.428s-.037.818,1.363,1c0,0-1.072-.275-1.363-1"
                transform="translate(-10.655 -8.194)" fill="#231f20"/>
          <path id="Path_12" data-name="Path 12"
                d="M18.5,45.723s0-.115.214-.21a21.64,21.64,0,0,1,3.3.445,5.591,5.591,0,0,0,2.733-.555c.641-.183.483.368.244.595a5.959,5.959,0,0,1-5.023.474s-1.45-.443-1.465-.748"
                transform="translate(-13.267 -32.53)" fill="#fff"/>
          <path id="Path_13" data-name="Path 13"
                d="M43.1,44.606a.589.589,0,0,1,.587-.123c.377.1.138.505-.057.511s-.609.127-.529-.388"
                transform="translate(-30.897 -31.877)" fill="#fff"/>
          <path id="Path_14" data-name="Path 14"
                d="M22.99,45.82s.283-.986.893-1.189l-.195-.044a1.717,1.717,0,0,0-.7,1.233"
                transform="translate(-16.485 -31.971)" fill="#231f20"/>
          <path id="Path_15" data-name="Path 15"
                d="M17.879,43.367a2.653,2.653,0,0,0-1.394.664A2.455,2.455,0,0,1,17.6,43.3Z"
                transform="translate(-11.82 -31.048)" fill="#231f20"/>
          <path id="Path_16" data-name="Path 16"
                d="M33.544,44.791a3.9,3.9,0,0,1,.99,1.231,2.465,2.465,0,0,0-.864-1.269Z"
                transform="translate(-24.052 -32.09)" fill="#231f20"/>
          <path id="Path_17" data-name="Path 17" d="M37.737,43.483a2.133,2.133,0,0,1,1.1.923,2.034,2.034,0,0,0-1-.941Z"
                transform="translate(-27.059 -31.166)" fill="#231f20"/>
          <path id="Path_18" data-name="Path 18"
                d="M14.368,45.974a1.07,1.07,0,0,1,.718-.15c.29.1-.122.394-.359.439s-.541-.2-.359-.289"
                transform="translate(-10.262 -32.84)" fill="#fff"/>
          <path id="Path_19" data-name="Path 19" d="M44.157,47.1s.138-.084.221,0-.023.207-.076.214-.252-.1-.145-.214"
                transform="translate(-31.64 -33.747)" fill="#fff"/>
          <path id="Path_20" data-name="Path 20"
                d="M11.136,47.092a2.879,2.879,0,0,0-.888,2.009s-.057.29-.129-.1a2.048,2.048,0,0,1,1.017-1.914"
                transform="translate(-7.247 -33.767)" fill="#231f20"/>
          <path id="Path_21" data-name="Path 21"
                d="M15.526,49.026a4.793,4.793,0,0,0-.481,2.389s0,.257-.125-.062a2.763,2.763,0,0,1,.606-2.327"
                transform="translate(-10.661 -35.154)" fill="#231f20"/>
          <path id="Path_22" data-name="Path 22"
                d="M43.162,48.075a2.88,2.88,0,0,1,1.026,1.942s.077.285.122-.1a2.048,2.048,0,0,0-1.148-1.838"
                transform="translate(-30.949 -34.472)" fill="#231f20"/>
          <path id="Path_23" data-name="Path 23"
                d="M40.237,52.276a2.3,2.3,0,0,1-.021,1.754s-.055.23.125-.026a1.636,1.636,0,0,0-.1-1.728"
                transform="translate(-28.831 -37.484)" fill="#231f20"/>
          <path id="Path_24" data-name="Path 24"
                d="M31.647,17.838a.239.239,0,0,0,.239.239h.559a5.39,5.39,0,0,0,1.271-1.314,9.338,9.338,0,0,0-2.07-.319Z"
                transform="translate(-22.692 -11.791)" fill="#fff"/>
          <path id="Path_25" data-name="Path 25"
                d="M19.38,18.028h2.959a.239.239,0,0,0,.239-.239v-1.4a4.023,4.023,0,0,0-2.21.349,5.912,5.912,0,0,0-1.227.826v.222a.239.239,0,0,0,.239.239"
                transform="translate(-13.725 -11.742)" fill="#fff"/>
          <path id="Path_26" data-name="Path 26"
                d="M58.355,29.653s-.173-.9-.355-1.757a5.771,5.771,0,0,1-.433,1.634c-.017.061-.036.124-.056.186a1.463,1.463,0,0,0,.844-.064"
                transform="translate(-41.238 -20.003)" fill="#fa5442"/>
          <g id="Group_13" data-name="Group 13" transform="translate(2.962 7.893)" opacity="0.5">
            <g id="Group_12" data-name="Group 12">
              <g id="Group_11" data-name="Group 11" clipPath="url(#clip-path-3)">
                <path id="Path_27" data-name="Path 27"
                      d="M10.783,30.024s-.461-.123-.265-.4A9.6,9.6,0,0,1,15.242,27.9s.39.252-.159.4a16.806,16.806,0,0,0-4.3,1.731"
                      transform="translate(-10.469 -27.896)" fill="#f5c6c7"/>
              </g>
            </g>
          </g>
          <g id="Group_16" data-name="Group 16" transform="translate(12.43 2.54)" opacity="0.5">
            <g id="Group_15" data-name="Group 15">
              <g id="Group_14" data-name="Group 14" clipPath="url(#clip-path-4)">
                <path id="Path_28" data-name="Path 28"
                      d="M44.67,9.164s-.268.418-.407.7-.362.226-.334,0a1.258,1.258,0,0,1,.513-.815c.274-.137.345-.077.228.111"
                      transform="translate(-43.927 -8.978)" fill="#f5c6c7"/>
              </g>
            </g>
          </g>
          <g id="Group_19" data-name="Group 19" transform="translate(13.272 1.936)" opacity="0.5">
            <g id="Group_18" data-name="Group 18">
              <g id="Group_17" data-name="Group 17" clipPath="url(#clip-path-5)">
                <path id="Path_29" data-name="Path 29"
                      d="M46.9,7.109s.093.15.332-.021.036-.254-.01-.246a.4.4,0,0,0-.322.268"
                      transform="translate(-46.905 -6.841)" fill="#f5c6c7"/>
              </g>
            </g>
          </g>
          <path id="Path_30" data-name="Path 30"
                d="M12.5,24.935l-.117-.018a12.2,12.2,0,0,0-5.7,2.028,4.2,4.2,0,0,1-.814,1v.082a3.481,3.481,0,0,0,.937-.981c.267-.513,3.687-2.072,5.61-2.017Z"
                transform="translate(-4.207 -17.867)" fill="#231f20"/>
        </g>
      </g>
    </g>
  </svg>
)

export default Lips
