import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Button from './Button'
import { Dropdown, Icon, Loader } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import request from 'core/request'
import {GET_ASSIGNED_WHITELABELS, ASSIGN_WHITELABEL_MAPPING, REMOVE_WHITELABEL_MAPPING} from "core/endpoints"
import { ccpProvider } from 'core/CcpProvider'

const AddContainer = styled.div`
  display: flex;
  min-width: 15em;
  .ui.selection.dropdown {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ui.button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-right: 0;
  }
`

const AddMapping = ({ agentId, mapping = [], mappingAdded = () => {} }) => {
  const [whiteLabels, setWhiteLabels] = useState([])
  const [filteredWhiteLabels, setFilteredWhiteLabels] = useState([])
  const [selectedWhiteLabel, setSelectedWhiteLabel] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    ccpProvider.api.admin.listWhiteLabels()
      .then(([result]) => {
        setLoading(false)
        setWhiteLabels(result)
      })
  }, [])

  useEffect(() => {
    const whiteLabelUids = mapping.reduce((x, { whiteLabelUid }) => [...x, whiteLabelUid], [])
    setFilteredWhiteLabels(whiteLabels
      .filter(x => !whiteLabelUids.includes(x.whiteLabelUid))
      .map(n => ({ key: n.whiteLabelUid, text: n.displayName, value: n.whiteLabelUid }))
    )
  }, [whiteLabels, mapping, loading])

  const add = e => {
    e.preventDefault()
    if(!selectedWhiteLabel) {
      return
    }
    setLoading(true)

    request.post(ASSIGN_WHITELABEL_MAPPING, { agentId, whiteLabelUid: selectedWhiteLabel })
      .then((response) => {
        let result = response.result

        setLoading(false)
        if (result.error) {
          if(result.error.code === 'duplicateWhiteLabelMapping') {
            toast.error('This site is already assigned to this agent')
            return
          }
          toast.error('There was an issue assigning this site to the agent, please try again.')
          return
        }

        const addedWhiteLabel = whiteLabels.find(x => x.whiteLabelUid === selectedWhiteLabel)
        mappingAdded(addedWhiteLabel)
        toast.success(`${addedWhiteLabel.displayName} site assigned to agent.`)
      })
  }

  return (
    <AddContainer>
      <Dropdown
        placeholder='Select WhiteLabel'
        fluid
        selection
        options={filteredWhiteLabels}
        disabled={loading}
        onChange={(_, { value }) => setSelectedWhiteLabel(value)}
      >
      </Dropdown>
      <Button color="blue" disabled={!selectedWhiteLabel} loading={loading} onClick={add}>Add</Button>
    </AddContainer>
  )
}

const RemoveMapping = ({ whiteLabel, agentId, whiteLabelRemoved = () => {} }) => {
  const [loading, setLoading] = useState(false)

  const remove = e => {
    e.preventDefault()
    setLoading(true)

    request.post(REMOVE_WHITELABEL_MAPPING, { agentId, whiteLabelUid: whiteLabel.whiteLabelUid })
      .then((response) => {
        let result = response.result

        setLoading(false)
        if (result.error) {
          toast.error('There was an issue deleting the assigned site, please try again.')
          return
        }
        whiteLabelRemoved(whiteLabel.whiteLabelUid)
        toast.success(`${whiteLabel.displayName} site removed from agent.`)
      })
  }

  return (
    <Button icon onClick={remove} loading={loading}><Icon className="red" name="remove"/></Button>
  )
}

const WhiteLabelMappingContainer = styled.div`
  position: relative;
  margin: 2em 0;
`
const WhiteLabelList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 1em 0;
  max-width: 15em;
  li {
    border: solid lightgrey;
    border-width: 1px 1px 0 1px;
    padding: 0.4em 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &:first-child {
      border-radius: 5px 5px 0 0;
    }
    &:last-child {
      border-radius: 0 0 5px 5px;
      border-width: 1px 1px 1px 1px;
    }
    &:first-child:last-child {
      border-radius: 5px;
    }
  }
`
const Title = () => <h3>Assigned WhiteLabels</h3>
const WhiteLabelMapping = ({ agentId }) => {
  const [loading, setLoading] = useState(true)
  const [mapping, setMapping] = useState([])

  useEffect(() => {
    setLoading(true)

    request.get(GET_ASSIGNED_WHITELABELS, {agentId})
      .then((response) => {
        let result = response.result

        setLoading(false)
        if(result.error && result.error.statusCode !== 404) {
          toast.error('There was an error fetching the assigned sites, please refresh the page to re-fetch them.')
          return
        }
        setMapping(result)
      })
  }, [])

  const mappingAdded = (whiteLabel) => {
    setMapping([...mapping, whiteLabel])
  }

  const whiteLabelRemoved = (whiteLabelUid) => {
    setMapping(mapping.filter(m => m.whiteLabelUid !== whiteLabelUid))
  }

  if(loading) {
    return (
      <WhiteLabelMappingContainer>
        <Title/>
        <Loader active={true}/>
      </WhiteLabelMappingContainer>
    )
  }

  return (
    <WhiteLabelMappingContainer>
      <Title/>
      <WhiteLabelList>
        {!loading && mapping.length === 0 && <p>No WhiteLabels are assigned to this agent</p>}
        {mapping.map((whiteLabel, index) => <li key={index}>{whiteLabel.displayName}<RemoveMapping whiteLabel={whiteLabel} whiteLabelRemoved={whiteLabelRemoved} agentId={agentId}/></li>) }
      </WhiteLabelList>
      <AddMapping mappingAdded={mappingAdded} mapping={mapping} agentId={agentId}/>
    </WhiteLabelMappingContainer>
  )
}

export default WhiteLabelMapping
