import React, { useEffect, useState } from 'react'
import { FeatureSwitchName } from './FeatureSwitches'
import { FeatureSwitchButton } from './FeatureSwitchButton'
import { Table, TableBody, TableHeader, TableHeaderH } from '../misc/table'
import { InfoBubble } from '../misc/InfoBubble'

export const GlobalFeatureSwitches = ({ globalFeatureSwitches = [], descriptions = {} }) => {
  const [featureSwitches, setFeatureSwitches] = useState(globalFeatureSwitches)
  useEffect(() => (setFeatureSwitches(globalFeatureSwitches)), [globalFeatureSwitches])

  const toggleFeatureSwitch = ({ featureSwitch, value }) => {
    setFeatureSwitches(prevState => {
      return {
        ...prevState,
        [featureSwitch]: value
      }
    })
  }

  return (
    <div>
      <h2>Global feature switches</h2>
      {Object.keys(featureSwitches).length > 0 &&
        <Table>
          <TableHeader>
            <tr>
              <TableHeaderH>Feature switch</TableHeaderH>
              <TableHeaderH/>
            </tr>
          </TableHeader>
          <TableBody>
            {
              Object.entries(featureSwitches).map(([fsName, fsValue]) => (
                <tr key={fsName}>
                  <td>
                    <FeatureSwitchName name={fsName}/>
                    <InfoBubble content={descriptions[fsName]} id={fsName}/>
                  </td>
                  <td>
                    <FeatureSwitchButton
                      featureSwitch={fsName}
                      on={fsValue}
                      onClick={toggleFeatureSwitch}
                      type="global"
                    />
                  </td>
                </tr>
              ))
            }
          </TableBody>
        </Table>
      }
    </div>
  )
}
