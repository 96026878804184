import Auth from './Auth'
import Agent from './Agent'
import Ccp from './Ccp'
import Chat from './Chat'
import UI from './UI'
import Admin from './Admin'

// We add state to window for easy access in chrome dev tools
export default {
  auth: new Auth(),
  agent: new Agent(),
  ccp: new Ccp(),
  chat: new Chat(),
  UI: new UI(),
  admin: new Admin(),
}
