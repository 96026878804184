import React from 'react'
import { observable, reaction, makeObservable } from 'mobx'
import { observer, inject } from 'mobx-react'
import { Table, Image, Label, Loader, Message } from 'semantic-ui-react'
import moment from 'moment'
import { get } from 'lodash'
import { toast } from 'react-toastify'

class History extends React.Component {
  loading = false

  state = {
    conversations: [],
    loading: false,
    error: false,
  }

  constructor(props) {
    super(props)

    makeObservable(this, {
      loading: observable
    })
  }

  componentDidMount() {
    const { store, actions } = this.props
    // Once data is loaded, add it to local state
    this.dispose = reaction( () => !!store.conversation && store.conversation.otherUserUid, async userUid => {
      if(!userUid) {
        this.setState({ error: 'No conversation selected', loading: false })
        return
      }
      this.setState({loading: true})
      const [response, error] = await actions.ccp.getConversationsForEmail(userUid)
      if (error) {
        this.setState({ error, loading: false })
      } else {
        const { conversations } = response
        this.setState({
          conversations: conversations,
          loading: false,
          error: false
        })
      }
    }, {
      fireImmediately: true,
    })
  }

  componentWillUnmount() {
    // Stop listening to changes
    this.dispose()
  }

  showHistory(conversation) {
    const { conversationUid } = this.props.store.conversationPreview

    if(conversationUid) {
      toast.info('Please close the conversation history preview before opening another one.')
      return
    }

    this.props.actions.ccp.setConversationPreview(conversation)
  }

  renderError(error) {
    let errorMessage = ''
    switch (error.statusCode) {
      case 404:
        errorMessage = 'The user with this email address has no other conversations'
        break
      default:
        errorMessage = 'An error has occurred on our end'
    }

    return (
      <Message negative>
        <Message.Header>Error upon fetching conversations</Message.Header>
        <p>{errorMessage}</p>
      </Message>
    )
  }

  render() {
    const { conversations, loading, error } = this.state
    const { conversationUid, attachedAt, detachedAt } = this.props.store.conversationPreview
    const { profileImage } = get(this.props.store, 'conversation.otherUserProfile')

    const activePreview = conversations.findIndex( c => c.conversationUid === conversationUid && c.attachedAt === attachedAt && c.detachedAt === detachedAt)
    return (
      <div className="history-wrapper">
      { loading ? <Loader style={{display: 'block'}}>Loading</Loader> :
        error ? this.renderError(error) :
        conversations.length === 0 ? <Message info header="User has no other conversations"/> :
        (
         <Table className={`history-table ${!conversationUid ? 'selectable': ''}`} basic striped={false}>
           <Table.Body>
           { conversations.map( (c, i) => (
             <Table.Row className={i === activePreview ? 'selected' : null} key={`${c.conversationUid}-${c.attachedAt}-${c.detachedAt}`} onClick={() => { this.showHistory(c) }}>
               <Table.Cell>
                 <div className="history-avatar-container">
                   <Image src={profileImage} avatar/>
                   <Label color='blue' circular className="history-count-label">
                     {c.messagesCount}
                   </Label>
                 </div>
               </Table.Cell>
               <Table.Cell><span>spoke to agent <strong>{c.agentName}</strong> on <strong>{c.siteName}</strong></span></Table.Cell>
               <Table.Cell textAlign='right'>{ parseTime((c.attachedAt || 0)) }</Table.Cell>
             </Table.Row>
           ))}
           </Table.Body>
         </Table>
        )}
      </div>
    )
  }
}

function parseTime(time) {
  const now = Date.now()
  time = moment(time)
  if(time.isAfter(now - 1000 * 60 * 60 * 24)) {//same day
    return time.format('h:mm a')
  }
  if(time.isAfter(now - 1000 * 60 * 60 * 24 * 7)) {//same week
    return time.format('ddd')
  }
  if(time.isAfter(now - 1000 * 60 * 60 * 24 * 365)) {//same year
    return time.format('MMM')
  }
  return time.format('MMM/YYYY')
}

History.propTypes = {}

export default inject('actions', 'store')(observer(History))
