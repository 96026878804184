import { get, upperFirst } from 'lodash'
import { inject, observer } from "mobx-react"
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'
import InfoModal from "../InfoModal"
import UsersBalance from './UsersBalance'

const SiteNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  text-align: left;
  font-size: large;
  color: #3b5998;
  a {
    display: inline-block;
    font-weight: bolder;
    cursor: pointer;
  }
`
const SiteNameContainer = styled.div`
  display: flex;
  flex-direction: column;
`
function SiteName({ store, siteName, siteUrl }) {
  const [showInfoModal, setShowInfoModal] = useState(false)
  const toggleInfoModal = () => setShowInfoModal(!showInfoModal)

  const profile = get(store, 'conversation.otherUserProfile', {})

  const { conversation } = store

  return (
    <SiteNameContainer>
      <SiteNameWrapper>
        <a onClick={toggleInfoModal}>{profile.username}</a><a href={siteUrl} target="_blank" rel="noopener noreferrer">{upperFirst(siteName)}</a>
      </SiteNameWrapper>
      <UsersBalance/>
      {showInfoModal && <InfoModal
        user={1}
        userUid={conversation.meUid}
        apUid={conversation.otherUserUid}
        toggleInfoModal={toggleInfoModal}
        showInfoModal={showInfoModal}
        identifier={conversation.identifier}/>
      }
    </SiteNameContainer>
  )
}

SiteName.propTypes = {
  siteName: PropTypes.string,
  siteUrl: PropTypes.string,
}

export default inject('store')(observer(SiteName))
