import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import React, { forwardRef } from 'react'
import { toast } from 'react-toastify'

const MoreConversationsBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 4em;
  margin: 0.5em 0.2em 2.4em 0.2em;
  padding: 0.2em;
  
  background: #3b5998;
  color: white;
  font-weight: bold;
  
  border-radius: 0.5em;
  
  cursor: pointer;
  user-select: none;
  
  &:hover {
    background-color: #304d8a;
  }
`
export const MoreConversationsButton = inject('store', 'actions')(observer(forwardRef(({ store, actions }, ref) => {
  const extraConversationsActive = store.agent.extraConversationsActive

  const handleMoreConversations = async() => {
    const errorHandler = ([, err]) => {
      if(err && err.statusCode) {
        switch (err.statusCode) {
          case 404:
            actions.agent.cancelMoreConversations()
            toast.info('No conversations left in queue.')
            break
          case 429:
            actions.agent.cancelMoreConversations()
            toast.info('Maximum number of extra conversations reached.')
            break
        }
      }
    }
    if (!store.agent.extraConversationsActive) {
      actions.agent.queueMoreConversationsRequest(10, errorHandler)
    }
    else {
      actions.agent.cancelMoreConversations()
    }
  }

  return (
    <MoreConversationsBtn ref={ref} onClick={handleMoreConversations}>
      { extraConversationsActive ? 'Requesting more conversations...' : 'Request more conversations' }
    </MoreConversationsBtn>
  )
})))
