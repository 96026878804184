import React, { useState, useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { Button, Form, Modal } from 'semantic-ui-react'
import { ccpProvider } from 'core/CcpProvider'
import Transfer from '../Icons/Transfer'
import ModalClose from '../Icons/ModalClose'

const TransferAgentModal = ({ store, actions, transfer, isSelectedOption, modalActions }) => {
  const [isOpen, setIsOpen] = useState(isSelectedOption)
  const [loading, setLoading] = useState(false)
  const [errorText, setErrorText] = useState('')

  useEffect(() => {
    setIsOpen(isSelectedOption)
  }, [isSelectedOption])

  const handleClose = () => {
    setIsOpen(false)
    setErrorText('')
    modalActions.clearSelectedOption()
  } 

  const handleTransfer = async() => {
    const currentConversationsAmount = store.conversations.length
    setErrorText('')
    setLoading(true)
    const [result, error] = await ccpProvider.api.agent.transferAttachedConversation(transfer.conversationId, transfer.agentId)
    setLoading(false)
    if(result && currentConversationsAmount - 1 < parseInt(store.config.maxConversations)) {
      actions.agent.fetchMoreConversations(false, 1)
    }
    if(result) {
      modalActions.successfulTransferredConversation()
      store.conversation.detachConversation()
      handleClose()
      return
    }
    if(error) {
      setErrorText(`${error.statusCode} : ${error.code}`)
    } else {
      setErrorText(`Unknown response`)
    }
  }

  return (
    <Modal open={isOpen}>
      <Modal.Content>
        <Modal.Header><span onClick={handleClose}><ModalClose/></span></Modal.Header>
        <Modal.Description>
          <Form style={{textAlign: 'center'}}>
            <h4>Transfer conversation to Agent {transfer?.agentName}?</h4>
            <h5 style={{color: 'red'}}>{errorText}</h5>
            <Button id='modal-transfer-button' type='button' onClick={handleTransfer} loading={loading}>
              {!loading && <Transfer/>}
              <span style={{marginLeft: '0.3rem'}}>Transfer Now</span>
            </Button>
          </Form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}

export default inject('store', 'actions')(observer(TransferAgentModal))
