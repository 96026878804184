import { useEffect, useRef } from 'react'

export const useEventListener = (eventName, handler, dependencies = [], element = document) => {
  const savedHandler = useRef()
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])
  useEffect(
    () => {
      if (!element) {
        return
      }

      const eventListener = event => savedHandler.current(event)
      element.addEventListener(eventName, eventListener)
      return () => {
        element.removeEventListener(eventName, eventListener)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventName, element, ...dependencies] // Re-run if eventName or element changes
  )
}

export default useEventListener
