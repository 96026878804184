import React from "react"

const Messages = ({ className = "" }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px" viewBox="0 0 24 24"
      width="24px"
      fill="currentColor"
      className={className}
    >
        <path d="M0 0h24v24H0z" fill="none"/>
        <path d="M21 6h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1zm-4 6V3c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z"/>
    </svg>
)
export default Messages
