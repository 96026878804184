const expirySeparator = '{-expiry-}'

export default {
  setItem(key, value, expiresIn = 0, expiryDate) {
    if(expiryDate) {
      expiresIn = `${expiryDate}${expirySeparator}`
    }else {
      expiresIn = expiresIn ? `${expiresIn + Date.now()}${expirySeparator}` : ''
    }

    try {
      sessionStorage.setItem(key, `${expiresIn}${JSON.stringify(value)}`)
      return true
    } catch (error) {
      console.warn('Session storage not supported by browser')
      return false
    }
  },
  /**
   * returns an unexpired item from the cache or removes it after expiration
   * @param {string} key
   * @param {boolean} returnExpiration
   * @param {boolean} isPartialKey : used to get the first occurence of data with a key including the provided
   *  (helpful for getting cached requests, regardless of user or parameters)
   * @returns {object || string || null} : { item, expiration, key } || "item" || null (if item expired/not found)
   */
  getItem(key, returnExpiration = false, isPartialKey = false) {
    if(isPartialKey) {
      for ( let k of Object.keys(sessionStorage)) {
        if(k.indexOf(key) !== -1) {
          key = k
          break
        }
      }
    }

    const fromSession = sessionStorage.getItem(key)
    if (!fromSession) {
      return null
    }

    const expirationAndItem = fromSession.split(expirySeparator)
    const item = expirationAndItem[1] || expirationAndItem[0]
    const expiration = expirationAndItem.length === 2 ? parseInt(expirationAndItem[0]) : null

    if (expiration && Date.now() > expiration) {
      sessionStorage.removeItem(key)
      return null
    }
    try{
      return returnExpiration || isPartialKey ? { item: JSON.parse(item), expiration, key } : JSON.parse(item)
    }catch (e) {
      return returnExpiration || isPartialKey ? { item, expiration, key } : item
    }
  }
}
