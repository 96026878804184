import './localhost'

export const LOGIN = '/v2/ccp/logIn'
export const GET_SESSION = '/v2/ccp/me/getAgentInfo'
export const AGENT_CREATE = '/v2/ccp/agents/create'
export const AGENT_UPDATE = '/v2/ccp/agents/update'
export const AGENT_DELETE = '/v2/ccp/agents/delete'
export const AGENT_LIST = '/v2/ccp/agents/list'
export const AGENT_FIND = '/v2/ccp/agents/find'
export const CCP_SEND = '/v2/ccp/send'
export const WIDGET_GET_CONVERSATIONS = '/v2/message/userConversations'
export const MESSAGE_CONVERSATION = '/v2/message/conversation'
export const CCP_GET_USERDATA = '/v2/ccp/userData/getUserData'
export const CCP_GET_PICTURES = '/v2/ccp/userData/getUserPictures'
export const CCP_REQUEST_CONVERSATIONS = '/v2/ccp/requestConversations'
export const CCP_GET_CONVERSATIONS = '/v2/ccp/me/getConversations'
export const CCP_OBJECTIVES_LIST = '/v2/ccp/objectives/getAll'
export const CCP_WHITELABELS_LIST = '/v2/ccp/agents/getWhiteLabels'
export const CCP_USER_UPDATE = '/v2/ccp/userData/update'
export const WEBSOCKET_AGENT_CREATE = '/v2/websocket/agent/create'
export const WEBSOCKET_ADMIN_CREATE = '/v2/websocket/agent/adminCreate'
export const WEBSOCKET_TYPING = '/v2/websocket/typing'
export const CCP_GET_AGENTS_SETTINGS = '/v2/ccp/me/getConfig'
export const CCP_GET_STANDARD_SETTINGS = '/v2/ccp/getSettings/standard'
export const CCP_GET_CONVERSATION = '/v2/ccp/me/getConversation'
export const CCP_GET_FEATURE_SWITCH_DESCRIPTIONS = '/v2/ccp/featureSwitches/getFeatureSwitchDescriptions'
export const CCP_GET_FEATURE_SWITCHES_GLOBAL = '/v2/ccp/featureSwitches/getFeatureSwitchesGlobal'
export const CCP_SET_WHITELABELS = '/v2/ccp/whiteLabels/setWhiteLabel'
export const CCP_SET_GLOBAL_FEATURE_SWITCHES = '/v2/ccp/featureSwitches/setGlobalFeatureSwitch'
export const GIFT_PRICES = '/v2/ccp/gifts/getGiftPricing'
export const GET_WHITELABEL = '/v2/ccp/whiteLabels/getWhiteLabel'
export const MESSAGE_SEARCH = '/v2/ccp/message/search'
export const MESSAGE_SEARCH_FRAMES = '/v2/ccp/message/getInFrame'
export const GET_ASSIGNED_WHITELABELS = '/v2/ccp/whiteLabelMapping/getMappings'
export const ASSIGN_WHITELABEL_MAPPING = '/v2/ccp/whiteLabelMapping/add'
export const REMOVE_WHITELABEL_MAPPING = '/v2/ccp/whiteLabelMapping/remove'
