import React from "react"

const Plane = ({ isClicked }) => (
  <svg
    viewBox="0 0 20 20"
    width="20"
    height="20"
    className={isClicked ? "emoji-highlighted" : ""}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.47 2.97c1.28-1.28 2.473-1.547 3.352-1.453a1.87 1.87 0 0 1 1.66 1.66c.095.88-.172 2.073-1.452 3.353l-1.473 1.474 1.927 6.937a2.75 2.75 0 0 1-.33 2.213l-.383.603c-.704 1.107-2.344 1.032-2.946-.134l-2.681-5.206-.114.113-.01.011-.011.01-2.255 2.081a4.1 4.1 0 0 1-.536 3.034c-.772 1.235-2.626 1.05-3.137-.315l-.305-.81a2.25 2.25 0 0 0-1.316-1.318l-.811-.304c-1.364-.511-1.55-2.365-.315-3.138a4.1 4.1 0 0 1 3.034-.535l2.08-2.255.011-.01.01-.011.114-.114-5.206-2.682c-1.166-.6-1.24-2.24-.134-2.945l.603-.384a2.75 2.75 0 0 1 2.213-.33l6.937 1.928zl.526.526zm3.193.039c-.381-.041-1.139.027-2.133 1.021l-1.782 1.783-.31.31-.421-.118-7.36-2.044a1.25 1.25 0 0 0-1.006.15l-.603.384a.2.2 0 0 0 .016.346l6.12 3.152.913.47-.727.727-.83.83-2.38 2.579-.332.36-.463-.16a2.61 2.61 0 0 0-2.236.255.259.259 0 0 0 .047.461l.81.304a3.75 3.75 0 0 1 2.195 2.194l.304.811c.075.2.348.228.461.046a2.61 2.61 0 0 0 .255-2.235l-.16-.463.36-.333 2.58-2.38.829-.83.726-.726.47.913 3.153 6.12a.2.2 0 0 0 .346.015l.384-.602a1.25 1.25 0 0 0 .15-1.006l-2.044-7.36-.117-.421.309-.31L15.97 5.47l.49.49-.49-.49c.994-.995 1.062-1.752 1.021-2.133a.37.37 0 0 0-.328-.328"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Plane
