import { Message } from 'semantic-ui-react'
import React from 'react'

export const Notification = ({ state }) => {
  if(!state) {
    return null
  }

  const notificationText = {
    success: { header: 'Updated', content: 'Successfully updated the settings' },
    error: { header: 'Failed', content: 'Something went wrong, please try again' },
    cacheSuccess: { header: 'Cache Cleared', content: 'Successfully cleared the cache' },
    cacheError: { header: 'Failed', content: 'Something went wrong, please try again' },
  }

  return (
    <Message
      error={state === 'error'}
      success={state === 'success'}
      header={notificationText[state].header}
      content={notificationText[state].content}
    />
  )
}
