import { action, makeObservable } from 'mobx'
import {omit} from 'lodash'
import { ccpProvider } from 'core/CcpProvider'
import store from '../store'

export default class Ccp {
  constructor() {
    makeObservable(this, {
      clear: action
    })
  }

  async getUserData(userUid, identifier) {
    return ccpProvider.api.chat.getUserData(userUid, identifier)
  }

  async getConversationsForEmail(userUid) {
    return ccpProvider.api.agent.getConversationsForEmail(userUid)
    .then( ([response, err]) => {
      if(err) {
        return [null, err]
      }
      return [response]
    })
  }

  async getUsersInfo(userUid, apUid) {
    return ccpProvider.api.chat.getUsersInfo({userUid, apUid})
  }

  async fetchMessages(params, opts) {
    return ccpProvider.api.ccp.fetchMessages(params, opts)
  }

  async fetchMessagesBetween(params, opts) {
    return ccpProvider.api.chat.getMessagesBetween(params, opts)
  }

  setConversationPreview(conversation) {
    store.conversationPreview = conversation ? conversation : { conversationUid: null }
  }

  async updateUserData(userUid, userData) {
    const data = omit(userData, ['userUid'])
    const [newDetails, err] = await ccpProvider.api.chat.updateUserData(userUid, data)
    if(err) {
      console.error(err)
      return [null, err]
    }
    store.conversations.forEach(conv => {
      if(conv.otherUserUid === newDetails.userUid) {
        conv.otherUserDetails = newDetails
      }
    })
    return [newDetails]

  }

  blockUser(userUid, blocked = true, { identifier }) {
    return ccpProvider.api.chat.blockUser( userUid, blocked, { identifier })
  }

  uploadImage(conversationUid, file, { identifier }, skipSendAsMessage = false, mimeType = 'image/jpeg') {
    return ccpProvider.api.ccp.uploadImage({conversationUid: conversationUid, skipSendAsMessage: skipSendAsMessage, file, mimeType: mimeType}, { identifier }, skipSendAsMessage)
  }

  setUserOffline(userUid, conversationUid, manual = true) {
    const conv = store.conversations.find(c => c.conversationUid === conversationUid)
    if (conv) {
      conv.meSentAfterOffline = false
    }
    return ccpProvider.api.chat.detachConversation({ conversationUid, manual })
  }

  sendNotification({ senderUid, receiverUid, identifier }, message, type = 'warning') {
    return ccpProvider.api.chat.sendNotification(
    { senderUid, receiverUid },
      message,
      type,
     { identifier })
  }

  addLog(data) {
    return ccpProvider.api.ccp.addLog(data)
  }

  clear() {
    store.profiles = []
  }
}
