import React from 'react'

export default function View() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26.013" height="18.05" viewBox="0 0 26.013 18.05">
      <g id="Group_8" data-name="Group 8" transform="translate(0 0.075)">
        <path id="Path_5" data-name="Path 5" d="M25.4,7.4,22.8,4.5a12.891,12.891,0,0,0-19.7,0L.6,7.4a2.3,2.3,0,0,0,0,3.1l2.6,2.9a12.891,12.891,0,0,0,19.7,0l2.6-2.9A2.422,2.422,0,0,0,25.4,7.4ZM13,16a7,7,0,1,1,7-7A6.957,6.957,0,0,1,13,16Z" fill="#358fd3"/>
        <circle id="Ellipse_1" data-name="Ellipse 1" cx="4" cy="4" r="4" transform="translate(9 5)" fill="#358fd3"/>
      </g>
    </svg>
  )
}
