import React from 'react'

export default function Match() {
 return (
   <svg xmlns="http://www.w3.org/2000/svg" width="38.825" height="22" viewBox="0 0 38.825 22">
     <g id="match" transform="translate(0.05)">
       <path id="Path_1" data-name="Path 1" d="M13.6,14.384h0a9.207,9.207,0,0,1-2.9-6.792A9.732,9.732,0,0,1,12.6,1.9a7.7,7.7,0,0,0-10.4.3,7.471,7.471,0,0,0,0,10.688l9.5,8.59a1.873,1.873,0,0,0,2.6,0l3.6-3.3-4.3-3.8Z" transform="translate(0 0)" fill="#bc265c"/>
       <path id="Path_2" data-name="Path 2" d="M36.6,2.2a7.734,7.734,0,0,0-10.8,0A7.648,7.648,0,0,0,20.4,0a3.406,3.406,0,0,0-1,.1A7.576,7.576,0,0,0,15,2.2a7.471,7.471,0,0,0,0,10.688l4.4,4,5.1,4.595a1.942,1.942,0,0,0,2.6,0l9.5-8.59A7.652,7.652,0,0,0,36.6,2.2Z" fill="#e52e66"/>
     </g>
   </svg>
 )
}
