import storage from './storage'
let api = null

let imageDomain= 'https://ccp.media'
const chatBotEndpoint = 'https://chat-staging.senti.live/v1'
// Code inside this check is stripped off when not on localhost
if (process.env.NODE_ENV !== 'production') {
  // please also update the options in src/pages/Playground.js
  const environment = (process.env && process.env.NODE_ENV.indexOf('development') >= 0) ? 'dev' : storage.get('SERVER_ENV', 'staging')
  switch(environment) {
    case 'production':
      api = 'https://ccp.agilewings.com'
      break
    case 'staging':
      api = 'https://ccp.staging.acamee.com'
      break
    case 'dev':
      api = 'http://ccp-api.dev.starcoders.com'
      break
    case 'test':
      api = 'https://api.ccp.test.msnebula.com'
      break
    default:
      api = '/api'
  }
  imageDomain = 'https://ccp.staging.msnebula.com'
}

export { api, imageDomain, chatBotEndpoint }
