import React, { useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Picker } from "./Picker"
import Smiley from "../Icons/Smiley"
import Leaf from "../Icons/Leaf"
import Burger from "../Icons/Burger"
import Plane from "../Icons/Plane"
import Football from "../Icons/Football"
import Lightbulb from "../Icons/Lightbulb"
import PeaceSign from "../Icons/PeaceSign"
import Flag from "../Icons/Flag"

const EmojiTrigger = styled.div`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "cursor")};
`

const EmojiDropdown = styled.div`
  border: solid 1px #c3f0ff;
  background: #fbfdff;
  position: absolute;
  bottom: 40px;
  margin: 1em;
  width: 286px;
  height: 297px;
  overflow-y: scroll;
  padding: 0.4em;
`

const EmojiHeader = styled.div`
  background: #fbfdff;
  position: sticky;
  top: 28px;
  padding-bottom: 10px;
  z-index: 500;
`

const EmojiPickerContainer = styled.div`
  display: flex;
  align-items: center;
  height: 30px;

  svg {
    height: 26px;
    margin: 5px;
    position: relative;
    cursor: pointer;

    &:hover {
      fill: #696868;
    }
  }
`

const EmojiPicker = ({ disabled, onClickEmoji = () => {} }) => {
  const [emojiPicker, setEmojiPicker] = useState(false)
  const [emojiType, setEmojiType] = useState("Smileys & People")

  const handleEmojiToggle = () => {
    if (disabled) {
      return
    }
    setEmojiPicker(!emojiPicker)
  }

  return (
    <EmojiPickerContainer>
      <EmojiTrigger onClick={handleEmojiToggle} disabled={disabled}>
        <svg viewBox="0 0 77.64406 77.64406">
          <path
            className="svg-icon"
            d="M38.822,0a38.822,38.822,0,1,0,38.822,38.822A38.822,38.822,0,0,0,38.822,0Zm0,73.55753A34.7355,34.7355,0,1,1,73.55753,38.822,34.7355,34.7355,0,0,1,38.822,73.55753Z"
          />
          <path
            className="svg-icon"
            d="M49.65133,49.61047A15.93744,15.93744,0,0,1,38.822,53.12488a15.93744,15.93744,0,0,1-10.8293-3.51441,2.04326,2.04326,0,0,0-2.86057,2.90143A19.90139,19.90139,0,0,0,38.822,57.21141,19.90139,19.90139,0,0,0,52.5119,52.5119a2.04326,2.04326,0,0,0-2.86057-2.90143Z"
          />
          <path
            className="svg-icon"
            d="M30.649,30.649a4.08653,4.08653,0,1,0-4.08653,4.08653A4.08653,4.08653,0,0,0,30.649,30.649Zm-2.04326,0"
          />
          <path
            className="svg-icon"
            d="M51.08162,26.56244A4.08653,4.08653,0,1,0,55.16815,30.649,4.08653,4.08653,0,0,0,51.08162,26.56244Zm0,4.08653"
          />
        </svg>
      </EmojiTrigger>
      {emojiPicker && !disabled && (
        <EmojiDropdown>
          <div className="emoji-category">
            <span onClick={() => setEmojiType("Smileys & People")}>
              <Smiley isClicked={emojiType == "Smileys & People"}/>
            </span>
            <span onClick={() => setEmojiType("Animals & Nature")}>
              <Leaf isClicked={emojiType == "Animals & Nature"}/>
            </span>
            <span onClick={() => setEmojiType("Food & Drink")}>
              <Burger isClicked={emojiType == "Food & Drink"}/>
            </span>
            <span onClick={() => setEmojiType("Travel & Places")}>
              <Plane isClicked={emojiType == "Travel & Places"}/>
            </span>
            <span onClick={() => setEmojiType("Activities")}>
              <Football isClicked={emojiType == "Activities"}/>
            </span>
            <span onClick={() => setEmojiType("Objects")}>
              <Lightbulb isClicked={emojiType == "Objects"}/>
            </span>
            <span onClick={() => setEmojiType("Symbols")}>
              <PeaceSign isClicked={emojiType == "Symbols"}/>
            </span>
            <span onClick={() => setEmojiType("Flags")}>
              <Flag isClicked={emojiType == "Flags"}/>
            </span>
          </div>
          <EmojiHeader>{emojiType}</EmojiHeader>
          <Picker
            onEmojiSelected={onClickEmoji}
            hidePicker={() => setEmojiPicker(false)}
            emojiType={emojiType}
          />
        </EmojiDropdown>
      )}
    </EmojiPickerContainer>
  )
}

EmojiPicker.defaultProps = {
  emojiProps: {
    style: {
      height: "1.6rem",
      width: "1.6rem",
    },
    className: "hide-mobile",
  },
}

EmojiPicker.propTypes = {
  onClickEmoji: PropTypes.func.isRequired,
  emojiProps: PropTypes.object,
}

export default EmojiPicker
