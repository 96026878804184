import React, { useEffect, useState } from 'react'
import { inject } from 'mobx-react'
import { Icon} from 'semantic-ui-react'
import styled from 'styled-components'
import { usePushToHistory } from 'core/hooks'
import { FeatureSwitches } from '../components/Admin/FeatureSwitches'
import { Settings as AdminSettings } from '../components/Admin/Settings'
import { Overview } from '../components/Admin/Development/Overview'

const settings = [
  { name: 'Admin settings', index: 1, icon: 'settings', Component: AdminSettings },
  { name: 'Feature switches', index: 2, icon: 'toggle on', Component: FeatureSwitches },
  { name: 'Development', index: 4, icon: 'code', Component: Overview },
]

const SidebarContainer = styled.div`
  background: #0a82fe;
  min-width: 180px;
  height: 100%;
  border: solid #0d70d5;
  border-width: 1px 1px 0 0;
`
const SidebarButton = styled.div`
  font-size: 1.2rem;
  padding: 0.5em 1em;
  user-select: none;
  border: #73b8ff solid;
  border-width: 0 0 1px 0;
  a {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    cursor: pointer;
    i {
      margin: 0.2em 0;
    }
  }
  &:first-of-type {
    border-width: 1px 0 1px 0;
  }
  &:hover {
    background: ${props => !props.active ? '#2c70fe' : '#2d5ab7'};
  }
  background: ${props => props.active && '#2d5ab7' };
`
const IconGroupContainer = styled.div`
  .icons {
    i:first-child {
      margin-right: 0;
    }
  }
`
const SidebarCustom = ({ activeTab, onTabChange }) => {

  return (
    <SidebarContainer>
      {settings.map(({ name, index, icon, iconGroup }) => (
        <SidebarButton key={index} active={activeTab === index} onClick={() => onTabChange(index)}>
          <a>{name}
            {!iconGroup && <Icon name={icon} size="large"/>}
            {iconGroup &&
              <IconGroupContainer>
                <Icon.Group>
                  {iconGroup.map((i, iconIndex) => <Icon name={i.name} key={iconIndex} size={i.size}/>)}
                </Icon.Group>
              </IconGroupContainer>
            }
          </a>
        </SidebarButton>
      ))}
    </SidebarContainer>
  )
}

const ContentContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  scrollbar-gutter: stable;
  padding-right: 1em;
  & > div {
    padding: 1.5em;
  }
`
const Content = ({ activeTab }) => {
  if(!activeTab) {
    return null
  }

  const Component = settings.find(x => x.index === activeTab)?.Component

  if(Component) {
    return <ContentContainer><Component/></ContentContainer>
  }

  return null
}

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
`
const Settings = () => {
  const [activeTab, setActiveTab] = useState()
  const [queryParams, saveQueryParams] = usePushToHistory()

  useEffect(() => {
    const tab = !isNaN(queryParams.tab) && !isNaN(parseFloat(queryParams.tab))
    setActiveTab(tab ? parseInt(queryParams.tab) : 1)
  }, [])

  useEffect(() => {
    if(activeTab && `${activeTab}` !== queryParams.tab) {
      saveQueryParams({ tab: activeTab })
    }
  }, [activeTab])

  if(!activeTab) {
    return null
  }

  const onTabChange = tab => setActiveTab(tab)
  return (
    <SettingsContainer>
      <SidebarCustom activeTab={activeTab} onTabChange={onTabChange}/>
      <Content activeTab={activeTab}/>
    </SettingsContainer>
  )
}

export default inject('featureFlags', 'overrideFeatureFlags')(Settings)
