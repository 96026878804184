import React from 'react'
import styled from 'styled-components'
import Star from './Icons/Star'
import { INTERACTIONS } from './elements/Interaction'
import Birthday from './elements/Birthday'

const Container = styled.div`
  padding: 10px 10px;
  border: solid 1px lightgray;
  margin: 20px 10px;
  border-radius: 4px;
`
const List = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px 0 20px;
  
  li {
    display: flex;
    align-items: center;
    min-width: 220px;
    padding: 0.4em 0;
    span {
      margin: 0 10px;
    }
  }
`

export default IconLegend
function IconLegend() {
  return (
    <Container>
      <h4>Icon legend</h4>
      <List>
        <li><span><Star/></span> Premium user</li>
        <Birthday as="li"/>
        {Object.values(INTERACTIONS).map((v, i) => <li key={i}><span>{v.icon}</span> {typeof v.text === 'string' ? v.text : v.text()}</li>)}
      </List>
    </Container>
  )
}
