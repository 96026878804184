import React from "react"

const ArrowLeft = ({ className = "" }) => (
  <i className={className}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="20"
      viewBox="0 0 12 20"
    >
      <path
        id="arrow"
        d="M114.787,172,116,170.706,106,160,96,170.706,97.206,172,106,162.594Z"
        transform="translate(-160 116) rotate(-90)"
        fill="#38cb86"
      />
    </svg>
  </i>
)
export default ArrowLeft
