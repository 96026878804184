if (typeof window !== 'undefined' && !window._babelPolyfill) {
  // require('babel-polyfill')
}

/* eslint-disable react/no-deprecated */
// require('url-polyfill')

// These needs to be "require" instead of "import" for IE11
const React = require('react')
const PropTypes = require('prop-types')

// Some libs an old version of react that requires propTypes
React.PropTypes = PropTypes

// Usefull for generating ids for React keys
String.prototype.hashCode = function() {
  let hash = 0
  for (let i = 0; i < this.length; i++) {
    hash = ((hash << 5) - hash) + this.charCodeAt(i) | 0
  }
  return Math.abs(hash)
}
