import React from "react"

const Football = ({ isClicked }) => (
  <svg
    viewBox="0 0 20 20"
    width="20"
    height="20"
    className={isClicked ? "emoji-highlighted" : ""}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.57 2.631a10.7 10.7 0 0 0-5.92 3.017 10.7 10.7 0 0 0-3.02 5.922l5.8 5.8a10.7 10.7 0 0 0 5.921-3.018A10.7 10.7 0 0 0 17.37 8.43zm1.995-.127 3.93 3.93a10.8 10.8 0 0 0-.468-2.846.93.93 0 0 0-.615-.615 10.8 10.8 0 0 0-2.847-.469M2.973 16.412a10.8 10.8 0 0 1-.469-2.847l3.93 3.93a10.8 10.8 0 0 1-2.846-.468.93.93 0 0 1-.615-.615M4.588 4.588a12.25 12.25 0 0 1 12.264-3.049c.77.236 1.372.839 1.609 1.609a12.25 12.25 0 0 1-3.05 12.264A12.25 12.25 0 0 1 3.149 18.46a2.43 2.43 0 0 1-1.609-1.609A12.25 12.25 0 0 1 4.588 4.588M8 10.939l-.47-.47a.75.75 0 0 0-1.06 1.061l1 1 1 1a.75.75 0 0 0 1.06-1.06L9.06 12l.94-.94.47.47a.75.75 0 1 0 1.06-1.06l-.47-.47.94-.94.47.47a.75.75 0 1 0 1.06-1.06l-1-1-1-1a.75.75 0 1 0-1.06 1.06l.47.47-.94.94-.47-.47a.75.75 0 1 0-1.06 1.06l.47.47z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Football
