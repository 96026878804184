import React, { useState } from 'react'
import { get } from 'lodash'
import { observer, inject } from 'mobx-react'
import { Item, Menu, Segment } from 'semantic-ui-react'
import InfoModal from './InfoModal'
import Notes from './Notes'
import History from './History'
import ChatBot from './ChatBot'
import UserInfo from './elements/UserInfo'
import ButtonGroup from './elements/ButtonGroup'
import BackToQueueBtn from "./elements/BackToQueueBtn"
import DetachConversationBtn from "./elements/DetachConversationBtn"
import styled from 'styled-components'

const ConversationButtons = styled.div`
  margin-top: 10px;
  padding-top: 10px;
  border-top: 2px solid #8cc9d9;
`
const Correspondent = ({ store }) => {
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [activeTab, setActiveTab] = useState('notes')

  const changeTab = (e, { name }) => setActiveTab(name)
  const toggleInfoModal = () => setShowInfoModal(!showInfoModal)

  const profile = get(store, 'conversation.otherUserProfile', {})

  const { conversationUid, conversation } = store

  return (
    <Item.Group className="chat-correspondent">
      <UserInfo
        conversationUid={conversationUid}
        user={'otherUser'}
        userOnline={conversation.otherUserActive}
        profile={profile}
        action={toggleInfoModal}
        showInfoButtons={true}
        showProfileDescription={true}
        showImageGallery={true}
      />
      <ConversationActionButton/>
      <Menu attached="top" tabular className="chat-correspondent-tabs">
        <Menu.Item
          name="history"
          active={activeTab === 'history'}
          onClick={changeTab}/>
        <Menu.Item
          name="notes"
          active={activeTab === 'notes'}
          onClick={changeTab}/>
        <Menu.Item
          name="chatbot"
          active={activeTab === 'chatbot'}
          onClick={changeTab}/>
      </Menu>
      <Segment attached="bottom">
        {activeTab === 'notes' && <Notes/>}
        {activeTab === 'history' && <History/>}
        {activeTab === 'chatbot' && <ChatBot/>}
      </Segment>
      {showInfoModal && <InfoModal
        user={1}
        userUid={conversation.meUid}
        apUid={conversation.otherUserUid}
        toggleInfoModal={toggleInfoModal}
        showInfoModal={showInfoModal}
        identifier={conversation.identifier}/>
      }
    </Item.Group>
  )
}

const ConversationActionButton = () => {
    return (
      <ConversationButtons>
        <ButtonGroup align="center">
          <DetachConversationBtn/>
          <BackToQueueBtn/>
        </ButtonGroup>
      </ConversationButtons>
    )
}

export default inject('store', 'actions')(observer(Correspondent))
