import React from 'react'
import PropTypes from 'prop-types'

// uses default checkbox, as Re-Bulma checkbox 
// does not yet support onChange
class FormCheckbox extends React.Component {
  render() {
    const { label, name, value, onChange, checked, children } = this.props
    return (
      <div className='form-group'>
        <label className="form-label" htmlFor={name+value}>{label}</label>
        <div className='form-checkbox-container'>
          <label className="form-checkbox" htmlFor={name+value}>
          <input
            id={name+value}
            name={name}
            type="checkbox"
            value={value}
            onChange={(e) => onChange({
              name,
              value,
              checked: e.target.checked
            })}
            defaultChecked={checked}
          />
          <i className="form-icon"/> {label}
          {children}
          </label>
        </div>
      </div>
    )
  }
}

FormCheckbox.defaultProps = {
  checked: false,
  label: 'label',
}

FormCheckbox.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default FormCheckbox
