import React from "react"

const CloseModal = ({ className = "" }) => (
  <i className={className}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g
        id="close_copy"
        data-name="close copy"
        transform="translate(-812 -258)"
      >
        <path
          id="normal"
          d="M825.656,271.656a8,8,0,1,1,0-11.313A8,8,0,0,1,825.656,271.656Zm-1.414-9.9a6,6,0,1,0,0,8.486A6,6,0,0,0,824.242,261.757ZM822,269a1,1,0,0,1-.707-.293h0L820,267.414l-1.293,1.293h0a1,1,0,0,1-1.414-1.414h0L818.585,266l-1.293-1.293h0a1,1,0,1,1,1.414-1.414h0L820,264.585l1.293-1.293h0a1,1,0,1,1,1.414,1.414h0L821.414,266l1.293,1.293h0A1,1,0,0,1,822,269Z"
          fill="#9c9c9f"
        />
      </g>
    </svg>
  </i>
)
export default CloseModal
