import React from 'react'
import { inject } from 'mobx-react'
import { reaction } from 'mobx'
import Header from './../components/layout/Header'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

@inject('store', 'actions')
class Index extends React.Component {

  state = {}

  constructor() {
    super()
  }
  componentDidMount() {
    const { store } = this.props
    const tokenObserver = reaction(
      () => store.token,
      () =>  {
        location.href = '/login'
      }
    )
    this.setState({ tokenObserver })
  }

  componentWillUnmount() {
    if(this.state.tokenObserver) {
      this.state.tokenObserver()
    }
  }

  render() {
    const { children } = this.props
    return (
      <Container>
        <Header/>
        {children}
      </Container>
    )
  }
}

export default Index
