import React, { useState } from 'react'
import { HeaderLink } from '../layout/Header'
import { inject } from 'mobx-react'

const LoginForm = inject('store', 'actions')(({ store, actions }) => {
  const [loading, setLoading] = useState(false)

  const logout = async e => {
    e.preventDefault()
    if(loading) {
      return
    }
    setLoading(true)
    if(store.agent && store.agent.extraConversationsActive) {
      actions.agent.cancelMoreConversations()
    }

    try {
      await actions.auth.logout()
    } finally {
      setLoading(false)
    }
  }

  return (
    <HeaderLink onClick={logout} to="/logout" icon="sign-out" loading={loading} disabled={loading}>
      Logout
    </HeaderLink>
  )
})

export default LoginForm
