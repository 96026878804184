import React, { useState, useEffect } from "react"
import gifts from "../../../../assets/gifts.json"
import Gift from "../Icons/Gift"
import ArrowLeft from "../Icons/ArrowLeft"
import CloseModal from "../Icons/CloseModal"
import request from "core/request"
import { GIFT_PRICES } from "../../../../core/endpoints.js"
import { ModalHeader, ModalContent, Modal } from "semantic-ui-react"

const Gifts = ({ onClickGif, conversation }) => {
  const [showGiftModal, setShowGiftModal] = useState(false)
  const [giftCategory, setGiftCategory] = useState("")
  const [giftSelected, setGiftSelected] = useState({})
  const [giftPrices, setGiftPrices] = useState(null)

  const [images, setImages] = useState({})

  function sendGift() {
    setShowGiftModal(false)
    onClickGif(giftSelected?.src)
  }
  useEffect(() => {
    async function handleAsync() {
      const res = await request.get(GIFT_PRICES)
      if (res && res.result && res.result.nameToPrice) {
        setGiftPrices(JSON.parse(res.result.nameToPrice))
      }
    }
    handleAsync()
  }, [])

  useEffect(() => {
    const giftsImages = Object.keys(gifts?.gifts).map((key) => {
      return {
        src: gifts?.gifts[key],
        fileName: key,
        price: giftPrices?.gifts[key] || 1,
      }
    })
    const kinkyImages = Object.keys(gifts?.kinky).map((key) => {
      return {
        src: gifts?.kinky[key],
        fileName: key,
        price: giftPrices?.kinky[key] || 1,
      }
    })
    const natureImages = Object.keys(gifts?.nature).map((key) => {
      return {
        src: gifts?.nature[key],
        fileName: key,
        price: giftPrices?.nature[key] || 1,
      }
    })
    const romanticImages = Object.keys(gifts?.romantic).map((key) => {
      return {
        src: gifts?.romantic[key],
        fileName: key,
        price: giftPrices?.romantic[key] || 1,
      }
    })
    const sweetsImages = Object.keys(gifts?.sweets).map((key) => {
      return {
        src: gifts?.sweets[key],
        fileName: key,
        price: giftPrices?.sweets[key] || 1,
      }
    })
    setImages({
      gifts: giftsImages,
      kinky: kinkyImages,
      nature: natureImages,
      romantic: romanticImages,
      sweets: sweetsImages,
    })
  }, [giftPrices])
  return (
    <div className="gift-wrapper">
      <Modal
        onClose={() => {
          setGiftSelected({})
          setShowGiftModal(false)
        }}
        onOpen={() => {
          setGiftSelected({})
          setGiftCategory("")
          setShowGiftModal(true)
        }}
        open={showGiftModal}
        className="gift-modal"
        trigger={
          <a className="gift-icon-button">
            <Gift/>
          </a>
        }
      >
        {giftCategory == "" ? (
          <ModalHeader>
            <GiftHeader
              setGiftSelected={setGiftSelected}
              setShowGiftModal={setShowGiftModal}
              setGiftCategory={setGiftCategory}
              conversation={conversation}
            />
          </ModalHeader>
        ) : (
          <ModalHeader className="gift-mobile-header">
            <GiftHeader
              setGiftSelected={setGiftSelected}
              setShowGiftModal={setShowGiftModal}
              setGiftCategory={setGiftCategory}
              className="gift-toggle-header"
              conversation={conversation}
            />
            <div className="gift-modal-header-gifts">
              <div
                className="gift-modal-back"
                onClick={() => setGiftCategory("")}
              >
                <ArrowLeft className="gift-back-arrow"/>
                Back
              </div>
              <div className="gift-modal-gift-title">
                {giftCategory?.charAt(0).toUpperCase() + giftCategory?.slice(1)}
              </div>
              <div
                className="gift-close"
                onClick={() => {
                  setShowGiftModal(false)
                  setGiftCategory("")
                }}
              >
                <CloseModal/>
              </div>
            </div>
          </ModalHeader>
        )}
        <ModalContent className="gift-modal-content">
          {giftCategory == "" && images?.kinky ? (
            <GiftsContent
              giftCategory={giftCategory}
              setGiftCategory={setGiftCategory}
              images={images}
              setGiftSelected={setGiftSelected}
              giftSelected={giftSelected}
            />
          ) : (
            <>
              <div className="gift-modal-gifts-desktop">
                <GiftsContent
                  giftCategory={giftCategory}
                  setGiftCategory={setGiftCategory}
                  images={images}
                  setGiftSelected={setGiftSelected}
                  giftSelected={giftSelected}
                />
              </div>
              <div className="gift-modal-gifts">
                {images && (
                  <div className="gifts-container">
                    {images[giftCategory]?.map((img) => {
                      const { src, fileName, price } = img
                      return (
                        <div
                          key={src}
                          className={`${
                            giftSelected?.fileName == fileName &&
                            "gift-selected"
                          } gift-modal-gift`}
                          onClick={() => setGiftSelected(img)}
                        >
                          <img src={src}/>
                          <div className="gift-price">
                            {price}{" "}
                            <img
                              className="gift-cost-icon"
                              src="https://images.ccp.media/gifts/coins_icon.png"
                              width={18}
                              height={18}
                            />
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            </>
          )}
        </ModalContent>
        {Object.keys(giftSelected).length !== 0 && (
          <div className="gift-confirmation">
            <div
              className="gift-close"
              onClick={() => {
                setGiftSelected({})
              }}
            >
              <CloseModal/>
            </div>
            <div className="gift-modal-gift">
              <img src={giftSelected?.src}/>
              <div className="gift-price">
                {giftSelected?.price}
                <img
                  className="gift-cost-icon"
                  src="https://images.ccp.media/gifts/coins_icon.png"
                  width={18}
                  height={18}
                />
              </div>
            </div>
            <div className="gift-confirmation-btn" onClick={() => sendGift()}>
              SEND A GIFT
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
}
export default Gifts

function GiftsContent({
  giftCategory,
  setGiftCategory,
  images,
  setGiftSelected,
  giftSelected,
}) {
  return (
    <div className="gift-category-container">
      <div className="gift-modal-categories">
        <div
          className={`${
            giftCategory == "" || giftCategory == "romantic"
              ? "gift-default-selection"
              : ""
          } gift-modal-selection`}
          onClick={() => setGiftCategory("romantic")}
        >
          <div className="gift-modal-icon">
            <img
              width={30}
              height={30}
              src={gifts?.romantic["heart_gift.png"]}
            />
          </div>
          <div>Romantic</div>
        </div>
        <div
          className={`${
            giftCategory == "sweets" ? "gift-default-selection" : ""
          } gift-modal-selection`}
          onClick={() => setGiftCategory("sweets")}
        >
          <div className="gift-modal-icon">
            <img
              width={30}
              height={30}
              src={gifts?.sweets["piece_of_cake.png"]}
            />
          </div>
          <div>Sweets</div>
        </div>
        <div
          className={`${
            giftCategory == "nature" ? "gift-default-selection" : ""
          } gift-modal-selection`}
          onClick={() => setGiftCategory("nature")}
        >
          <div className="gift-modal-icon">
            <img width={30} height={30} src={gifts?.nature["colibri.png"]}/>
          </div>
          <div>Nature</div>
        </div>
        <div
          className={`${
            giftCategory == "kinky" ? "gift-default-selection" : ""
          } gift-modal-selection`}
          onClick={() => setGiftCategory("kinky")}
        >
          <div className="gift-modal-icon">
            <img
              width={30}
              height={30}
              src={gifts?.kinky["leather_wipe.png"]}
            />
          </div>
          <div>Kinky</div>
        </div>
        <div
          className={`${
            giftCategory == "gifts" ? "gift-default-selection" : ""
          } gift-modal-selection`}
          onClick={() => setGiftCategory("gifts")}
        >
          <div className="gift-modal-icon">
            <img
              width={30}
              height={30}
              src="https://images.ccp.media/gifts/coins_icon.png"
            />
          </div>
          <div>Gifts</div>
        </div>
      </div>
      <div className="gifts-container-desktop">
        <>
          {images[giftCategory == "" ? "romantic" : giftCategory]?.map(
            (img) => {
              const { src, fileName, price } = img
              return (
                <div
                  key={src}
                  className={`${
                    giftSelected?.fileName == fileName && "gift-selected"
                  } gift-modal-gift`}
                  onClick={() => setGiftSelected(img)}
                >
                  <img src={src}/>
                  <div className="gift-price">
                    {price}{" "}
                    <img
                      className="gift-cost-icon"
                      src="https://images.ccp.media/gifts/coins_icon.png"
                      width={18}
                      height={18}
                    />
                  </div>
                </div>
              )
            }
          )}
        </>
      </div>
    </div>
  )
}

function GiftHeader({
  setShowGiftModal,
  setGiftCategory,
  className,
  conversation,
}) {
  return (
    <div className={`${className} gift-modal-header`}>
      <div
        className="gift-close"
        onClick={() => {
          setShowGiftModal(false)
          setGiftCategory("")
        }}
      >
        <CloseModal/>
      </div>
      <div className="send-gift-title">
        Send {conversation.otherUserProfile.username} a Virtual Gift!
      </div>
    </div>
  )
}
