import styled from 'styled-components'

export const Table = styled.table`
  width: ${props => props.fullWidth ? '100%' : ''};
  background: #fff;
  margin: 1em 0;
  border: 1px solid rgba(34,36,38,.15);
  box-shadow: none;
  border-radius: 0.28571429rem;
  text-align: left;
  color: rgba(0,0,0,.87);
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
`

export const TableHeader = styled.thead`
  text-align: left;
  color: rgba(0,0,0,.87);
  border-collapse: separate;
  border-spacing: 0;
  ${props => props.sticky ? `
    tr {
      position: sticky;
      top: 0;
    }
  `: ''}
`
export const TableHeaderH = styled.th`
  position: relative;
  padding: 0.7em;
  background: #f9fafb;
  border: solid rgba(34,36,38,.15);
  border-width: 1px 0 1px 0;
`

export const TableBody = styled.tbody`
  tr {
    &:nth-child(even) {
      background: #f9fafb;
    }

    td {
      padding: 0.7em;
      border: solid rgba(34,36,38,.1);
      border-width: 1px 0 1px 0;
    }
  }
`
