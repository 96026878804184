import styled from 'styled-components'

export const HideConversationsBtn = styled.button`
  position: absolute;
  background:none;
  border: solid 1px lightblue;
  border-radius: 100%;
  padding: 5px 7px 5px 7px;
  background: #eaf1f7;
  cursor: pointer;
  visibility: visible;
  z-index: 10;
`