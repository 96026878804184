import React, { useState } from 'react'
import { Button, Modal, Tab } from 'semantic-ui-react'
import { inject, observer } from 'mobx-react'
import { User } from './User'

const InfoModal = ({ store, toggleInfoModal = () => {}, user, ...props }) => {
  const conversation = store.conversation || props.conversation
  const [activeTab, setActiveTab] = useState(user === 'me' ? 0 : 1)

  const meProfile = conversation?.meProfile || {}
  const otherUserProfile = conversation?.otherUserProfile || []

  const hideMoreInfo = () => toggleInfoModal()

  const panes = [
    { menuItem: (props.history === true) ? props.ap.username : meProfile.username, render: () => (props.history === true) ?
        <User conversation={conversation} userIndex={2} history={props.ap}/> :
        <User conversation={conversation} userIndex={0}/>},
    { menuItem: otherUserProfile.username, render: () => <User conversation={conversation} userIndex={1} showLocation={true}/> },
  ]

  return (
    <div>
      <Modal dimmer="blurring" open={true} >
        <Modal.Header>More Information</Modal.Header>
        <Modal.Content>
          <Tab menu={{ secondary: true, pointing: true }}
               panes={panes}
               onTabChange={(_, data) => setActiveTab(data.activeIndex)}
               activeIndex={activeTab}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={hideMoreInfo}>Close</Button>
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export default inject('store', 'actions')(observer(InfoModal))
