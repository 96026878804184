import { action, makeObservable } from 'mobx'
import {get} from 'lodash'
import store from '../store'

import { ccpProvider } from 'core/CcpProvider'

export default class Agent {
  constructor() {
    makeObservable(this, {
      update: action,
      setActiveStatus: action,
      delete: action,
      restore: action,
      detachAll: action,
      forceFullyLogout: action,
      deleteCurrentConversation: action,
      listAgents: action,
      mapAgents: action,
      listObjectives: action,
      listWhiteLabels: action,
      clear: action,
      leave: action,
      back: action,
      getUsersInfo: action,
      queueMoreConversationsRequest: action,
      cancelMoreConversations: action,
      fetchMoreConversations: action,
      fetchConfig: action,
      reAuthorize: action,
      softDetachConversation: action,
      detachConversation: action,
    })
  }

  create(newAgentData) {
    return ccpProvider.api.admin.create(newAgentData)
  }

  find(id, view = 'public') {
    return ccpProvider.api.admin.find(id, view)
  }

  update(agent) {
    return ccpProvider.api.admin.update(agent)
  }

  async setActiveStatus(id, newStatus) {
    const [response, error] = await ccpProvider.api.admin.setActiveStatus(id, newStatus)
    if(!error) {
      const agent = store.agents.find(a => a.id === response.id)
      agent.isActive = response.isActive
    }
    return [response, error]
  }

  async delete(agentId) {
    const [response, error] = await ccpProvider.api.admin.delete(agentId)
    if(error) {
      console.error(error)
    }
    return [response, error]
  }

  async restore(agentId) {
    const [response, error] = await ccpProvider.api.admin.restore(agentId)
    if(error) {
      console.error(error)
    }
    return [response, error]
  }

  async detachAll(agentId) {
    const [response, error] = await ccpProvider.api.admin.detachAll(agentId)
    if(error) {
      console.error(error)
    }
    return [response, error]
  }

  async forceFullyLogout(agentId) {
    const [response, error] = await ccpProvider.api.admin.forceFullyLogout(agentId)
    if(error) {
      console.error(error)
    }
    return [response, error]
  }

  async detachOldPremiumConversations(allowReattach = true) {
    const conversationUids = store.conversations.slice().reduce((res, conv) => {
      if (conv.lastMessageSentAt !== conv.otherUserLastSentAt //last message is from agent
        && (conv.lastMessageSentAt * 1000 + 15 * 60 * 1000) < Date.now() //15 minutes have passed since the agent last replied
        && get(conv, 'otherUserProfile.isPremium', 0)) {//other user has premium status
          conv.detachConversation() // deals with websocket and removes from queue
          res.push(conv.conversationUid)
        }
        return res
      }, [])

    if (!conversationUids.length) {
      return
    }

    const [response, error] = await ccpProvider.api.chat.detachConversations({ conversationUids, allowReattach })
    if(error) {
      console.error(error)
      return error
    }
    return response
  }

  async deleteCurrentConversation(manual = false) {
    const { conversation } = store
    const [result, error] = await ccpProvider.api.chat.detachConversation({ conversationUid: conversation.conversationUid, manual })
    if(error) {
      console.error(error)
      return error
    }
    conversation.detachConversation()
    return [result, error]
  }

  static agentMapping
  async listAgents(data) {
    const [response] = await ccpProvider.api.admin.listAgents(data)
    store.agents = response.agents
    return response.agents
  }

  async mapAgents() {
    if(this.agentMapping) {
      return this.agentMapping
    }

    const agents = await this.listAgents()
    this.agentMapping = agents.reduce((map, agent) => {
      map[agent.id] = agent
      return map
    },{})
    return this.agentMapping
  }

  listObjectives() {
    return ccpProvider.api.admin.listObjectives().then(([response]) => {
      store.objectives.replace(response.objectives)
      return response.objectives
    })
  }

  listWhiteLabels() {
    return ccpProvider.api.admin.listWhiteLabels().then(([response]) => {
      store.whiteLabels.replace(response)
      return response
    })
  }

  clear() {
    store.agents = []
  }

  async leave() {
    await ccpProvider.api.agent.leave()
    store.conversations.replace([])
  }

  back() {
    return ccpProvider.api.agent.back()
  }

  async getUsersInfo(userUid, apUid) {
    return ccpProvider.api.chat.getUsersInfo({ userUid, apUid })
  }

  queueMoreConversationsRequest(seconds, callback) {
    store.agent.extraConversationsActive = true
    store.agent.extraConversationsTimer = setTimeout(() => { this.fetchMoreConversations().then(callback) }, seconds * 1000)
  }

  cancelMoreConversations() {
    if (store.agent.extraConversationsActive) {
      clearTimeout(store.agent.extraConversationsTimer)
      store.agent.extraConversationsActive = false
      store.agent.extraConversationsTimer = null
    }
  }

  fetchMoreConversations(manual = true, extraConversations = 0) {
    return ccpProvider.api.agent.fetchMoreConversations({
      conversationsLength: store.conversations.length,
      extraConversations: extraConversations || store.config.extraConversations,
      manual,
    })
  }

  async fetchConfig() {
    const [response] = await ccpProvider.api.agent.fetchConfig()
    Object.assign(store.config, response)
  }

  async reAuthorize() {
    return ccpProvider.api.agent.reAuthorize()
  }

  async softDetachConversation(conversationUid) {
    return ccpProvider.api.agent.softDetachConversation(conversationUid)
  }

  async detachConversation(conversationUid, allowReattach, manual) {
    const { conversation } = store
    const [result, error] = await ccpProvider.api.agent.detachConversation({conversationUid, allowReattach, manual})
    if(error) {
      console.error(error)
      return [result, error]
    }
    conversation.detachConversation()
    return [result, error]
  }
}
