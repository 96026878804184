import { useRef } from 'react'
//https://www.developerway.com/posts/implementing-advanced-use-previous-hook
export const usePrevious = (value) => {
  const ref = useRef({
    value: value,
    prev: null,
  })
  const current = ref.current.value
  if (value !== current) {
    ref.current = {
      value: value,
      prev: current,
    }
  }
  return ref.current.prev
}
