import { usePrevious } from 'core/hooks'
import { useEffect } from 'react'

const CREDIT_TIMER = 5000 //every 5 seconds

export const useGetUsersCredits = (store) => {
  const { conversation } = store
  const { isPremium, credits } = conversation?.otherUserProfile || {}
  const isCreditSite = conversation?.isCreditSite || false
  const doesUserHaveCredits = !!(credits?.expiryDate)

  const previousIsPremium = usePrevious(isPremium)

  const getCredits = () => {
    if(isCreditSite && doesUserHaveCredits && (!previousIsPremium && isPremium)) {
      conversation.getCredits()
    }
  }

  useEffect(() => {
    getCredits()
  }, [isPremium])

  useEffect(() => {
    let timer = null
    if(previousIsPremium && !isPremium && !doesUserHaveCredits) {
      clearInterval(timer)
    }

    if(isCreditSite && doesUserHaveCredits && (!previousIsPremium && isPremium)) {
      timer = setInterval(async() => {
        conversation.getCredits()
      }, CREDIT_TIMER)
    }
    return () => {
      if(timer) {
        clearInterval(timer)
      }
    }
  }, [credits, isPremium])

  return { getCredits }
}
