import React, { Fragment } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { get } from 'lodash'
import { logServiceError } from 'core/request'

const WIKIPEDIA_BASE_URL = 'https://en.wikipedia.org'
const WIKIPEDIA_DEFAULT_URL_PARAMS = '&origin=*&format=json'

const RESPONSE_STATUS = {
  success: 'success',
  noArticle: 'noArticle',
}

class WikipediaBtn extends React.Component {
  constructor(props) {
    super (props)
  }

  openLink = async() => {
    const { city, state } = this.props
    const url = await wikipediaSearch(city, state)
    window.open(url, '_blank')
  }

  render() {
    return (
      <Fragment>
        <Button basic icon color='blue' onClick={this.openLink} style={{margin: '0', padding: '8px'}} title='Wikipedia'>
          <Icon name='wikipedia w'/>
        </Button>
      </Fragment>
    )
  }
}

async function wikipediaSearch(city, state) {

  const cityUrl = `${WIKIPEDIA_BASE_URL}/w/api.php?action=query&prop=info&format=json&inprop=url&redirects&origin=*&titles=${city.replace(/ /g,'_')}`
  const stateUrl = `${WIKIPEDIA_BASE_URL}/w/api.php?action=query${WIKIPEDIA_DEFAULT_URL_PARAMS}&prop=info&inprop=url&redirects&titles=${`${city.replace(/ /g,'_')},_${state.replace(/ /g,'_')}`}`

  const responseWithState = await fetchWikipedia(stateUrl)
  if(responseWithState.status === RESPONSE_STATUS.success) {
    //we have a match, return the url
    return responseWithState.url
  }else if(responseWithState.status === RESPONSE_STATUS.noArticle) {
    //no match found, do a search by city only
    const responseWithCity = await fetchWikipedia(cityUrl)
    //return either the city article or an overview page with possible articles
    if(responseWithCity.status === RESPONSE_STATUS.success) {
      return responseWithCity.url
    }else{
      //article not found return the 404 page of wikipedia
      return responseWithState.url
    }
  }
  else{
    //error fetching page, return wikipedia homepage
    return WIKIPEDIA_BASE_URL
  }

}

async function fetchWikipedia(url) {
  try{
    const rawResponse = await fetch(url)
    if(rawResponse.status >= 403) {
      const response = await rawResponse.json()
      throw (response.error || response)
    }
    const response = await rawResponse.json()
    return processWikipediaResponse(response)
  }catch(error) {
    logServiceError(error, url)
    throw error
  }

}

function processWikipediaResponse(response) {
  if(response.query && response.query.pages) {
    const pages = response.query.pages
    const pageIds = Object.keys(pages)
    if(pageIds.length === 0) {
      return null
    }
    //no article found
    if(pageIds[0] === '-1') {
      return { status: RESPONSE_STATUS.noArticle }
    }
    const fullUrl = get(response, `query.pages[${pageIds[0]}].fullurl`, null)
    if(fullUrl) {
      return { status: RESPONSE_STATUS.success, url: fullUrl }
    }
    return null
  }
}

export default WikipediaBtn
