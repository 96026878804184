import React from 'react'
import PropTypes from 'prop-types'

class FormSelect extends React.Component {
  render() {
    const { label, name, value, defaultValue, placeholder, onChange, options, required } = this.props
    const id = name.hashCode()
    const opts = {}
    if (typeof value !== 'undefined') {
      Object.assign(opts, { value })
    } else {
      Object.assign(opts, { defaultValue })
    }
    return (
      <div className='form-group'>
        {label && (
          <label className="form-label" htmlFor={id}>
            {label}
          </label>
        )}
        <select {...opts}
                id={id}
                name={name}
                className="form-select"
                onChange={(e) => {
                  e.preventDefault()
                  onChange({
                    name,
                    value: e.target.value,
                  })
                }}
                required={required}>
          {placeholder && (
            <option value=''>{placeholder}</option>
          )}
          {options.map(([ val, text ]) => (
            <option key={val} value={val}>{text}</option>
          ))}
        </select>
      </div>
    )
   }
}

FormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
}

FormSelect.defaultProps = {
  placeholder: 'Choose an option',
  defaultValue: '',
  value: undefined,
  required: false,
}

export default FormSelect
