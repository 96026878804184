import React from 'react'

/*
 Usage examples:
 @boundary()
 class SomeComponent extends ...
*/

global.boundary = function boundary(opts) {
  if (typeof opts === 'function') {
    return createErrorBoundaryComponent({}, opts)
  } else {
    return _componentClass => createErrorBoundaryComponent(opts, _componentClass)
  }
}

// On production, we might want to not display it
// at all or style according to template
function ErrorBoundaryMessage(props) {
  return (
    <div style={{
      width: '100%',
      color: '#fff',
      backgroundColor: '#dd1136',
      padding: '0.4rem',
    }}>
      Something went wrong.
      {process.env.NODE_ENV !== 'production'
        ? <pre>props: {JSON.stringify(props, null, 2)}</pre>
        : ''}
    </div>
  )
}

/**
 * Make component resistant to failure.
 * Do not over-use
 * @param Component
 * @returns {Function}
 */
function createErrorBoundaryComponent(options, WrappedComponent) {
  return class ErrorBoundary extends React.Component {

    state = { hasError: false }

    componentDidCatch(error, errorInfo) {
      this.setState({ hasError: true })

      let message = error

      if (error instanceof Error) {
        message = JSON.parse(JSON.stringify(error.stack, Object.getOwnPropertyNames(error.stack)))
      }
      window._LTracker?.push({
        interface: window._LTracker.interface,
        tag: window._LTracker.tag,
        category: 'ReactJsException',
        message,
        componentStack: errorInfo.componentStack,
      })
    }

    render() {
      if (this.state.hasError) {
        return (
          <ErrorBoundaryMessage {...this.props}/>
        )
      }
      return <WrappedComponent {...this.props}/>
    }
  }
}
