import React from 'react'

const Cake = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="15 17 70 66" width="26.123" height="28">
    <defs>
      <style>{`.d{fill:#e52e66;}.e{fill:#bc255c;}.f{fill:none;}.g{fill:#fdd84c;}.h{fill:#3b8dd3;}`}</style>
    </defs>
    <g>
      <path className="d"
            d="M85,81.05c-.52,1.16-1.42,1.54-2.67,1.54-21.55-.02-43.11-.02-64.66-.02-.36,0-.74,.02-1.09-.06-1-.23-1.67-1.2-1.57-2.19,.11-1.03,.96-1.83,2.04-1.85,1.59-.03,3.19,0,4.78,0,.25,0,.49,0,.82,0v-12.61c1.83,.4,3.46-.11,5.1-.61,1.28-.39,2.57-.79,3.86-1.15,2.8-.77,5.57-.65,8.35,.16,2.16,.63,4.32,1.29,6.53,1.71,3.51,.67,6.98,.29,10.39-.75,1.63-.5,3.26-1.01,4.93-1.38,2.4-.53,4.8-.31,7.15,.41,1.41,.43,2.84,.83,4.25,1.27,1.32,.42,2.66,.61,4.12,.35v12.59c1.78,0,3.48,0,5.18,0,.32,0,.64,.03,.95,.09,.79,.15,1.18,.78,1.55,1.41v1.1Z"/>
      <path className="e"
            d="M20.94,57.78c0-.75,0-1.51,0-2.26,.02-1.82,1.19-3.15,3.01-3.39,.38-.05,.77-.07,1.16-.07,16.59,0,33.17,0,49.76,0,.61,0,1.24,.05,1.82,.22,1.52,.45,2.36,1.61,2.37,3.2,.01,1.53,0,3.06,0,4.59,0,2.19-1.74,3.59-3.89,3.05-1.63-.41-3.24-.9-4.85-1.41-3.93-1.24-7.83-1.17-11.76,.04-2.04,.63-4.11,1.21-6.21,1.58-3.09,.54-6.1-.08-9.08-.99-1.97-.6-3.99-1.11-6.03-1.42-2.99-.46-5.88,.22-8.72,1.14-1.21,.39-2.43,.76-3.67,1.06-2.19,.53-3.91-.84-3.92-3.09,0-.75,0-1.51,0-2.26Z"/>
      <path className="h"
            d="M54.14,49.53h-8.16c-.01-.26-.03-.48-.03-.7,0-4.59,0-9.17,0-13.76,0-1.62,.65-2.26,2.28-2.27,1.37,0,2.73-.02,4.1,0,1.11,.02,1.86,.75,1.86,1.85,.01,4.88,0,9.77,0,14.65,0,.04-.02,.09-.05,.22Z"/>
      <path className="h"
            d="M41.7,49.53h-8.17c-.01-.25-.03-.47-.03-.7,0-4.59,0-9.17,0-13.76,0-1.61,.66-2.27,2.29-2.27,1.34,0,2.69-.01,4.03,0,1.16,.01,1.93,.7,1.93,1.85,.02,4.88,0,9.77,0,14.65,0,.04-.02,.09-.05,.22Z"/>
      <path className="h"
            d="M58.34,49.51v-.76c0-4.52,0-9.04,0-13.56,0-1.77,.62-2.39,2.37-2.39,1.34,0,2.69-.02,4.03,0,1.1,.02,1.85,.77,1.85,1.87,.01,4.86,0,9.72,0,14.58,0,.07-.02,.13-.04,.25h-8.22Z"/>
      <path className="g"
            d="M33.36,25.23c.25-.8,.42-1.63,.77-2.39,.74-1.59,1.55-3.15,2.39-4.69,.5-.93,1.68-1,2.16-.1,1.02,1.93,2.06,3.87,2.85,5.9,.87,2.25-.51,4.65-2.78,5.26-2.3,.62-4.58-.74-5.15-3.06-.07-.29-.09-.58-.13-.88l-.1-.04Z"/>
      <path className="g"
            d="M66.64,25.2c-.13,1.8-.9,3.07-2.46,3.77-1.58,.72-3.1,.5-4.4-.62-1.3-1.12-1.9-2.61-1.25-4.24,.84-2.08,1.88-4.09,2.91-6.09,.42-.81,1.62-.82,2.04,0,1.05,2.04,2.01,4.13,2.97,6.21,.15,.32,.14,.71,.18,.97Z"/>
      <path className="g"
            d="M54.24,25.22c-.12,1.78-.9,3.06-2.48,3.77-1.59,.71-3.1,.47-4.4-.66-1.3-1.13-1.86-2.64-1.21-4.26,.83-2.06,1.86-4.05,2.89-6.03,.42-.82,1.58-.84,2.05-.05,1.22,2.03,2.38,4.1,3.04,6.4,.08,.28,.09,.58,.12,.82Z"/>
    </g>
    <rect className="f" width="80" height="80"/>
  </svg>
)

export default Cake
