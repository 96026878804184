import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import useEventListener from 'core/hooks/useEventListener'
import { Button, Loader, Modal } from 'semantic-ui-react'

const TitleContainer = styled.h3`
  margin: 0 0 1em 0;
`

const PicturesContainer = styled.div`
  position: relative;
  border: solid 1px lightgray;
`

const ImageList = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const ImageThumb = styled.img`
  width: 120px;
  height: auto;
`
const Image = styled.img`
  display: block;
  margin: 0 auto;
`

const ImageContainer = styled.div`
  padding: 1em;
  cursor: pointer;
`

const PicturesErrorContainer = styled.div`
  position: absolute;
  top: calc( 50% - 2em );
  left: 0;
  text-align: center;
  margin: 1em;
`

export const Pictures = observer(({ conversation, userIndex, history }) => {
  const [imageModel, setImageModel] = useState(false)
  const [pictureIndex, setPictureIndex] = useState(null)
  const [profilePictures, setProfilePictures] = useState({pictures: [], error: false, loading: false,})

  useEventListener('keydown', e => {
    switch (e.keyCode) {
      case 37:
        previousPicture()
        break
      case 39:
        nextPicture()
        break
      case 27:
        escapeHandler()
    }
  })

  useEffect(() => {
      getPictures().then()
  }, [userIndex])


  const getPictures =  async() => {
    setProfilePictures({
      ...profilePictures,
      loading: true
    })

    let response = []
    if (history) {
      response = await conversation.fetchPictures(history.userUid, true)
    } else {
      response = await conversation.fetchPictures(userIndex ? conversation.otherUserUid : conversation.meUid)
    }

    setProfilePictures({
      ...profilePictures,
      pictures: response,
      loading: false
    })
  }

  const showPicture = index => {
    setImageModel(true)
    setPictureIndex(index)
  }

  const hidePicture = () => {
    setPictureIndex(null)
    setImageModel(false)
  }

  const escapeHandler = () => hidePicture()

  const previousPicture = () => (pictureIndex > 0 && setPictureIndex(pictureIndex - 1))

  const nextPicture = () => (pictureNumber < profilePictures.pictures.length && setPictureIndex(pictureIndex + 1))

  const pictureNumber = pictureIndex + 1

  const Title = () => <TitleContainer>Pictures</TitleContainer>

  if(profilePictures.loading) {
    return (
      <PicturesContainer>
        <Title/>
        <Loader active={true}/>
      </PicturesContainer>
    )
  }

  if(profilePictures.error) {
    return (
      <PicturesContainer>
        <Title/>
        <PicturesErrorContainer>
          <p>There was an error fetching the pictures of this user, please try again.</p>
          <Button loading={profilePictures.loading} onClick={getPictures} color="blue">retry</Button>
        </PicturesErrorContainer>
      </PicturesContainer>
    )
  }

  if(!profilePictures.pictures.length) {
    return (
      <PicturesContainer>
        <Title/>
        <p>This user does not have any pictures.</p>
      </PicturesContainer>
    )
  }

  return (
    <PicturesContainer>
      <Title/>
      <ImageList>
        {profilePictures.pictures.map((picture, i) => (
            <ImageContainer key={i}>
              <ImageThumb onClick={() => showPicture(i)} src={picture.thumbnail}/>
            </ImageContainer>
          )
        )}
      </ImageList>
      <Modal className="imagePopup" open={imageModel && pictureIndex !== null} basic
             size="small">
        <Modal.Header>
          Picture {pictureNumber} of {profilePictures.pictures.length}
        </Modal.Header>
        <Modal.Content>
          <Image src={(pictureIndex !== null) ? profilePictures.pictures[pictureIndex].url : ''}/>
        </Modal.Content>
        <Modal.Actions>
          {profilePictures.pictures.length > 1 &&
            <Button
              inverted
              icon="arrow left"
              onClick={() => showPicture(pictureIndex - 1)}
              disabled={(pictureNumber === 1)}/>
          }
          {profilePictures.pictures.length > 1 &&
            <Button
              inverted
              icon="arrow right"
              onClick={() => showPicture(pictureIndex + 1)}
              disabled={(pictureNumber === profilePictures.pictures.length)}/>
          }
          <Button color="blue" onClick={hidePicture} inverted>Back</Button>
        </Modal.Actions>
      </Modal>
    </PicturesContainer>

  )
})
