import { observable, makeObservable } from 'mobx'
import { MESSAGE_TYPE_DEFAULT } from '../../core/constants'

export default class Message {

  id

  constructor(data) {
    makeObservable(this, {
      id: observable
    })

    this.id = data.id
    this.sentBy = data.sentBy
    this.senderUid = data.senderUid
    this.receiverUid = data.receiverUid
    this.userUid = data.userUid
    this.sentAt = data.sentAt
    this.message = data.message || ''
    this.type = data.type || MESSAGE_TYPE_DEFAULT
    this.replay = data.replay
    this.receiver = data.receiver //TODO temporary fix for message-service flaw
  }
}
