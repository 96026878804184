import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { toast } from 'react-toastify'
import { useInterval } from 'core/hooks'
import { inject } from 'mobx-react'

export const CurrentTime = inject('actions', 'store')(({ actions, store, conversationUid, user }) => {
  const { conversation } = store
  const [timeZone, setTimeZone] = useState(null)
  const [time, setTime] = useState(null)

  useInterval(() => {
    if(timeZone) {
      setTime(moment().tz(timeZone).format('hh:mm a'),)
    }
  }, 1000)

  const getTimeZone = async() => {
    if(!conversation) {
      return
    }
    if(!conversation[`${user}Profile`].timezone) {
      actions.chat.getTimezone(conversationUid, user)
        .then(([timezone, error]) => {
          if(error) {
            toast.error(`Error fetching local timezone of ${conversation[`${user}Profile`].username}`)
            return
          }
          setTimeZone(timezone)
        })
    }else {
      setTimeZone(conversation[`${user}Profile`].timezone)
    }
  }

  useEffect(() => {
    getTimeZone()
  }, [conversation.conversationUid])

  if (!time) {
    return null
  }
  return (
    <p>Local time: { time }</p>
  )
})
