import React from 'react'
import { observable, reaction, makeObservable } from 'mobx'
import { observer, inject } from 'mobx-react'
import { Loader } from 'semantic-ui-react'
import { sortBy } from 'lodash'
import { toast } from 'react-toastify'
import {MESSAGE_TYPE_SYSTEM, MESSAGE_TYPE_CONTACT_INFO} from 'core/constants'


import SystemNotification from './messages/SystemNotification'
import MessageWarning from './messages/MessageWarning'
import MessageDefault from './messages/MessageDefault'
import InfoModal from "./InfoModal"

class ConversationPreview extends React.Component {
  loading = false

  state = {
    messages: [],
    actionProfileUser: {},
    loading: false,
    showInfoModal: false,
  }

  constructor(props) {
    super(props)

    makeObservable(this, {
      loading: observable
    })
  }

  toggleInfoModal = () => {
    this.setState({showInfoModal: !this.state.showInfoModal})
  }

  componentDidMount() {
    const { store, actions } = this.props

    // Once data is loaded, add it to local state
    this.dispose = reaction( () => !!store.conversation && store.conversationPreview.conversationUid, async conversationUid => {
      if(!conversationUid) {
        actions.ccp.setConversationPreview(null)
        this.dispose()
        return
      }
      this.setState({loading: true})

      // splitting convsersationUid from History to get ap userUid and dating site userUid
      const userUids = store.conversationPreview.conversationUid.split(':')
      // action profile userUid
      const apUid = userUids[0]
      // user from dating site Uid
      const userUid = userUids[1]

      const actionProfileUser = await actions.agent.getUsersInfo(userUid, apUid)
      const { attachedAt, detachedAt } = store.conversationPreview
      const [messages, error] = await actions.ccp.fetchMessagesBetween({
        conversationUid,
        after: Math.floor(attachedAt / 1000) - 60,
        before: Math.floor(detachedAt / 1000) + 60,
      },  { identifier: store.conversationPreview.identifier })
      if(error) {
        toast.error(`There was an error showing the conversation history of ${store.conversationPreview.agentName} and ${store.conversation.otherUserProfile.username} on ${store.conversationPreview.siteName}`)
        this.setState({ messages: [], loading: false})
      }else {
        this.setState({ actionProfileUser: actionProfileUser[0]['user0Profile'], messages, loading: false })
      }
    }, {
      fireImmediately: true,
    })
  }

  componentWillUnmount() {
    // Stop listening to changes
    this.dispose()
  }

  render() {
    const { conversationPreview, conversation: currentConversation } = this.props.store
    const { username } = currentConversation.otherUserProfile
    const { messages, actionProfileUser, loading } = this.state
    const { setConversationPreview } = this.props.actions.ccp
    const {otherUserUid, identifier} = currentConversation


    return (
      <div className="conversation-preview chat-window-agent">
        <div className="header">
          Currently viewing the history between <strong>{username}</strong> and <strong className="impersonator-info" onClick={() => {this.toggleInfoModal()}}>{actionProfileUser.username}</strong> - agent <strong>{conversationPreview.agentName}</strong> on <strong>{conversationPreview.siteName}</strong><span className="closePreview" onClick={() => {setConversationPreview(null)} }>CLOSE HISTORY &#x2716;</span>
        </div>
        <div className="chat-conversation">
          {loading
            ? <Loader style={{display: 'block'}}>Loading</Loader>
            : messages && sortBy(messages, ['sentAt']).map((message, i) => {
            switch(message.type) {
              case MESSAGE_TYPE_SYSTEM: {
                return (
                  <SystemNotification
                    key={i}
                    message={message}
                  />
                )
              }
              case MESSAGE_TYPE_CONTACT_INFO: {
                return (
                  <MessageWarning
                    key={i}
                    message={message}
                    embedded={false}
                    belongsToOtherUser={message.sentBy !== 0}
                  />
                )
              }
              default:
                return (
                  <MessageDefault
                    key={i}
                    message={message}
                    belongsToOtherUser={message.sentBy !== 0}
                    otherUserName={username}
                    history={true}
                  />
                )
            }
          })}
        </div>
        {this.state.showInfoModal &&
          <InfoModal
            user='me'
            userUid={otherUserUid}
            apUid={actionProfileUser.userUid}
            toggleInfoModal={this.toggleInfoModal}
            showInfoModal={this.state.showInfoModal}
            identifier={identifier}
            history={true}
            ap={actionProfileUser}
          />}
      </div>
    )
  }
}

ConversationPreview.propTypes = {}

export default inject('actions', 'store')(observer(ConversationPreview))
