import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {isEmpty, range} from 'lodash'
import { Form, Grid} from 'semantic-ui-react'

class FormDate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      dateAsArray: isEmpty(String(this.props.defaultValue))
        ? getDefaultDate(this.props.minAge)
        : timestampToArray(this.props.defaultValue)
    }
  }

  get yearOptionList() {
    return buildYearOptionList(this.props.minAge, this.props.maxAge)
  }

  get monthOptionList() {
    return range(0, 12).map((label, i) => {
      return {
        value: i + 1,
        label: moment().month(i).format('MMMM')
      }
    })
  }

  get dayOptionList() {
    const year = parseInt(this.state.dateAsArray[0], 10)
    const month = parseInt(this.state.dateAsArray[1], 10)

    return range(1, getDaysInMonth(year, month) + 1).map(value => {
      return {
        value,
        label: value
      }
    })
  }

  componentDidMount() {

    if (this.props.setInitial) {
      this.props.onChange({
        name: this.props.name,
        value: this.getDateAsTimestamp(this.state.dateAsArray)
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value && nextProps.value !== this.props.value && !this.state.loaded) {
      this.setState({
        dateAsArray: timestampToArray(nextProps.value),
        loaded: true
      })
    }
  }

  getDateAsTimestamp = ([year, month, day]) => {
    return moment([
      year,
      month - 1,
      day
    ])
    .unix()
    //.format('YYYY-MM-DD')
  }

  onChange = (type) => (ev) => {
    const value = parseInt(ev.target.value, 10)
    this.setState(prevState => {
      prevState.dateAsArray[type] = value

      // Make sure days dont exceed max per month
      const maxDays = getDaysInMonth(prevState.dateAsArray[0], prevState.dateAsArray[1])
      if (prevState.dateAsArray[2] > maxDays) {
        prevState.dateAsArray[2] = maxDays
      }

      this.props.onChange({
        name: this.props.name,
        value: this.getDateAsTimestamp(prevState.dateAsArray)
      })
      return prevState.dateAsArray
    })
  }

  renderOptions = (values) => {
    const options = values.map((v, i) => <option value={v.value} key={i}>{v.label}</option>)

    if (this.props.noSelected) {
      options.unshift(<option value="" key="0000"> - </option>)
    }

    return options
  }

  render() {

    const { label } = this.props

    return (
      <Form.Field>
        <label>{label}</label>
        <Grid columns={3}>
          <Grid.Row>
            <Grid.Column>
              <select className="ui selection dropdown"
                name={this.props.name + '-day'}
                onChange={this.onChange(2)}
                value={"" + this.state.dateAsArray[2]}>
                {this.renderOptions(this.dayOptionList)}
              </select>
            </Grid.Column>

            <Grid.Column>
              <select className="ui selection dropdown"
                name={this.props.name + '-month'}
                onChange={this.onChange(1)}
                value={"" + this.state.dateAsArray[1]}>
                {this.renderOptions(this.monthOptionList)}
              </select>
            </Grid.Column>

            <Grid.Column>
              <select className="ui selection dropdown"
                name={this.props.name + '-year'}
                onChange={this.onChange(0)}
                value={"" + this.state.dateAsArray[0]}>
                {this.renderOptions(this.yearOptionList)}
              </select>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form.Field>
    )
  }
}

FormDate.defaultProps = {
  minAge: 18,
  maxAge: 120,
  setInitial: true,
}

FormDate.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  minAge: PropTypes.number,
  maxAge: PropTypes.number,
  setInitial: PropTypes.bool,
}

export default FormDate

function buildYearOptionList(minAge, maxAge) {
  const now = new Date().getFullYear()
  return range(now - minAge, now - maxAge).map(y => {
    return {
      value: y,
      label: y
    }
  })
}

function getDefaultDate(minAge) {
  const fromYear = new Date().getFullYear() - minAge - 1
  return [fromYear, 1, 1]
}

function timestampToArray(value) {
  const dateAsString = moment.unix(value).format('YYYY-MM-DD')
  return dateAsString.split('-').map(v => parseInt(v, 10))
}

function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate()
}
