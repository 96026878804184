import React, { useState, useRef } from 'react'
import { get, isEqual } from 'lodash'
import { Button, Icon, Message } from 'semantic-ui-react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import { toast } from 'react-toastify'
import * as mediaQueries from 'styles/mediaQueries'
import MessageDefault from './messages/MessageDefault'
import { generateNotSentId } from 'core/helpers'
import { PENDING_MESSAGE_ID, MESSAGE_TYPE_IMAGE} from '../../../core/constants'
import {imageDomain} from "core/localhost"
import ContactInfoButton from "./elements/ContactInfoButton"
const ERRORS = {
  entityTooLarge: 'File too big, please choose a smaller image',
  allowedImageTypes: 'Only jpeg is allowed, please select another image',
  default: 'There was an error while uploading the image, please try again',
}
const BlockButton = ({ conversation, actions }) => {
  const [loading, setLoading] = useState(false)
  const isPremium = get(conversation, 'otherUserProfile.isPremium', false)
  const blocked = get(conversation, 'otherUserDetails.blocked', false)

  const handleBlock = ()  => {
    setLoading(true)
    actions.ccp.blockUser(conversation.otherUserUid, !blocked, conversation)
      .then(([, error]) => {
        setLoading(false)
        if(error) {
          toast.error('Failed to block user')
        } else {
          toast.success(`${!blocked ? 'Block' : 'Unblock'} is successfull`)
          conversation.otherUserDetails.blocked = !blocked
        }
      })
  }

  return (
    <>
      <Button size="small" basic color='red' icon labelPosition='left' onClick={handleBlock} disabled={!!isPremium} loading={loading}>
        <Icon name='ban'/>{blocked ? "Unblock this user" : "Block user"}
      </Button>
    </>
  )
}

const ChatControlsContainer = styled.div`
  margin: 5px 10px;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;

  .button:nth-child(2) {
    margin: 0 1em;
  }

  @media ${mediaQueries.retina} {
    flex-direction: column;
    .button:nth-child(2) {
      margin: 1em 0;
    }
  }
  .upload-button_error {
    color: #e74c3c;
    margin: 5px 2px;
  }
`
const FileUpload = observer(({ conversation, actions, setPendingImageMessage }) => {
  const ALLOWED_FILE_TYPES = [
    'image/jpeg',
    'image/png'
  ]

  const MAX_FILE_SIZE = 10485760
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const uploadImage = async(file) => {
    const skipSendAsMessage = true
    const mimeType = file.type
    const [imageUploaded, imageUploadError] = await actions.ccp.uploadImage(conversation.conversationUid, file, conversation, skipSendAsMessage, mimeType)

    if (imageUploadError) {
      removeMessage(pendingImage)
      actions.chat.updateConversationCache(this)
      return [null, imageUploadError]
    }
    const uploadKey = imageUploaded.imageUpload.fields.key
    const keyParts = uploadKey.split('/')
    const pendingImage = await previewImage({
      message: keyParts[keyParts.length - 1],
      id: PENDING_MESSAGE_ID,
      sentAt: Math.floor(Date.now()/1000),
      sentBy: 1,
      type: MESSAGE_TYPE_IMAGE,
    }, uploadKey)

    setTimeout(() => {
      if (conversation.messages.find(m => m.id === PENDING_MESSAGE_ID && m.type === MESSAGE_TYPE_IMAGE)) {
        previewImage({...pendingImage, id: generateNotSentId()})
      }
    }, 10000)

    return [imageUploaded]
  }
  const input = useRef(null)

  const removeMessage = async(data) => {
    const messageToRemove = new MessageDefault(data)
    conversation.messages.replace(conversation.messages.filter(m => !isEqual(messageToRemove, m)))
  }

  const previewImage = async(data, uploadKey) => {
    setPendingImageMessage({message: data, image: imageDomain + '/' + uploadKey})
  }

  const handleClick = e => {
    e.preventDefault()

    if (error) {
      setError('')
    }

    input.current.click()
  }
  const handleOnFilesChange = e => {
    setError('')

    const { files } = e.target
    if (!files.length) {
      setLoading(false)
      return
    }

    const file = files[0]
    //validate the file
    const hasError = file.size > MAX_FILE_SIZE ? 'entityTooLarge' : !ALLOWED_FILE_TYPES.includes(file.type) ? 'allowedImageTypes' : null
    if (!hasError) {
      setLoading(true)
      uploadImage(file)
        .then(([, err]) => {
          if (err) {
            setError(err)
          }
          setLoading(false)
        })
    } else {
      setError(hasError)
    }
    e.target.value = ''
  }
  const fileUploadStyle = {
    display: 'none',
  }

  return (
    <>
      <Button size="small" basic color="green" icon labelPosition="left" onClick={handleClick} loading={loading}>
        <Icon name="photo"/>Upload photo
      </Button>
      {error && <p className="error upload-button_error">{get(ERRORS, error, ERRORS.default)}</p>}
      <div style={fileUploadStyle} className={`upload-button-container chat-item-picker chat-item-picker-closed ${ loading ? 'pending' : '' }`}>
        <div className="chat-item-picker-toggle chat-item-picker-toggle" role="button" onClick={handleClick}> Upload</div>
        <input type="file" ref={input} onChange={handleOnFilesChange} disabled={loading} multiple={false} accept=".jpeg,.jpg,.png"/>
      </div>
    </>

  )
})

const ChatControls = ({ store, actions, setPendingImageMessage }) => {
  if(!store.conversation) {
    return
  }

  const blocked = get(store.conversation, 'otherUserDetails.blocked', false)

  return (
    <>
      {blocked ? (
        <Message style={{ marginTop: '1rem' }} negative>
          This user is blocked and will not be able to respond to the messages until he upgrades or is manually unblocked.
        </Message>
      ): null}
      <ChatControlsContainer>
        <Buttons direction="row" align="flex-start" width="100%">
          <BlockButton conversation={store.conversation} actions={actions}/>
          <ContactInfoButton conversation={store.conversation} actions={actions}/>
          <FileUpload conversation={store.conversation} actions={actions} setPendingImageMessage={setPendingImageMessage} label="photo"/>
        </Buttons>
      </ChatControlsContainer>
    </>
  )
}

export default inject('store', 'actions')(observer(ChatControls))
