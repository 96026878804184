import { removeSessionToken } from '../../../actions/Auth'

const hooks = {
  onLoginRequired: () => {
    removeSessionToken()
  },
  onRequestError(data = {}) {
    console.error(`apiError - ${data.endpoint || ''}`, {
      endpoint: data.endpoint,
      statusCode: data.statusCode,
      error: data.response,
    })
  }
}

export default hooks
