import { observable, action, computed, makeObservable, runInAction } from 'mobx'
import { get, isEqual, isNil, maxBy, minBy, omitBy, uniq, orderBy, uniqBy, unionWith, pullAt } from 'lodash'
import storage from 'core/storage'
import * as Config from 'core/config'
import { ccpProvider } from 'core/CcpProvider'
import {
  MESSAGE_TYPE_CONTACT_INFO,
  MESSAGE_TYPE_UPGRADE,
  MESSAGE_TYPE_DEFAULT,
  MESSAGE_TYPE_SYSTEM,
} from 'core/constants'
import { generateNotSentId } from 'core/helpers'

import Message from './Message'
import store from '../store'

export default class Conversation {

  isHidden = false
  messages = []

  meReadAt = 0
  meMessageCount = 0
  meProfile = {}
  mePictures = []

  otherUserReadAt = 0
  otherUserLastSentAt = 0
  lastMessageSentAt = 0
  meLastSentAt = 0
  otherUserProfile = {}
  otherUserDetails = {}
  otherUserPictures = []

  unreadByMeCount = 0
  unreadByOtherUserCount = 0

  meConnections = 0
  otherUserConnections = 0

  otherUserActive = false

  meSentAfterOffline = false

  typingIndicator = 0
  messageReceivedAt = 0
  notificationReceivedAt = 0
  reclaimed = false

  highPriority = false

  userDeleted = false

  sendingMessage = false

  matched = false
  meLiked = false
  otherUserLiked = false
  otherUserViewed = false

  isCreditSite = false
  remainingMessages = -1 // for all other users (priority coloring white)
  isNewAttached = false

  constructor(data, isEmbedded = false) {
    makeObservable(this, {
      isHidden: observable,
      messages: observable.shallow,
      meReadAt: observable,
      meMessageCount: observable,
      meProfile: observable.shallow,
      mePictures: observable.shallow,
      otherUserReadAt: observable,
      otherUserLastSentAt: observable,
      lastMessageSentAt: observable,
      otherUserProfile: observable.deep,
      otherUserDetails: observable.shallow,
      otherUserPictures: observable.shallow,
      unreadByMeCount: observable,
      unreadByOtherUserCount: observable,
      meConnections: observable,
      otherUserConnections: observable,
      otherUserActive: observable,
      meSentAfterOffline: observable,
      typingIndicator: observable,
      messageReceivedAt: observable,
      notificationReceivedAt: observable,
      reclaimed: observable,
      highPriority: observable,
      userDeleted: observable,
      sendingMessage: observable,
      matched: observable,
      meLiked: observable,
      otherUserLiked: observable,
      otherUserViewed: observable,
      isCreditSite: observable,
      remainingMessages: observable,
      isNewAttached: observable,
      meLastSentAt: observable,
      oldestSentAt: computed,
      newestSentAt: computed,
      lastMessage: computed,
      lastMessageOfOtherUser: computed,
      agentMessageCount: computed,
      updateStats: action,
      fetchMessages: action,
      fetchPictures: action,
      getClassifiers: action,
      getCredits: action,
      setInteractions: action,
      send: action,
      sendTypingIndicator: action,
      addMessage: action,
      reorder: action,
      removeMessage: action,
      hideConversation: action,
      removeFromQueue: action,
      detachConversation: action
    })

    const meIndex = 0
    const otherIndex = meIndex ^ 1

    this.id = data.id
    this.chatType = data.chatType
    this.meIndex = meIndex
    this.conversationUid = data.conversationUid
    this.meUid = data.meUid || data[`user${meIndex}Uid`]
    this.meHasDeleted = data.meHasDeleted !== undefined ?  data.meHasDeleted : data[`user${meIndex}HasDeleted`]
    this.meReadAt = data.meReadAt || data[`user${meIndex}ReadAt`]
    this.meProfile = data.meProfile || get(data, `user${meIndex}Profile`, {})
    this.meMessageCount = typeof data.meMessageCount === 'number' ? data.meMessageCount : data[`user${meIndex}MessageCount`]
    this.otherUserMessageCount = typeof data.otherUserMessageCount === 'number' ? data.otherUserMessageCount : data[`user${otherIndex}MessageCount`] //TODO decide if needed
    this.meConnections = 0
    this.meSentAfterOffline = false
    this.otherUserUid = data.otherUserUid || data[`user${otherIndex}Uid`]
    this.otherUserHasDeleted = data.otherUserHasDeleted !== undefined ? data.otherUserHasDeleted : data[`user${otherIndex}HasDeleted`]
    this.otherUserReadAt = data.otherUserReadAt || data[`user${otherIndex}ReadAt`]
    this.otherUserProfile = data.otherUserProfile || get(data, `user${otherIndex}Profile`, {})
    this.otherUserDetails = data.otherUserDetails || data[`user${otherIndex}Details`]
    this.otherUserLastSentAt = data.otherUserLastSentAt || data[`user${otherIndex}LastSentAt`]
    this.meLastSentAt = data.meLastSentAt || data[`user${meIndex}LastSentAt`] // TODO figure if needed
    this.otherUserConnections = 0
    this.otherUserActive = data.otherUserActive || data[`user${otherIndex}Active`]
    //in order to handle cached unreadByMeCount
    this.unreadByMeCount = typeof data.unreadByMeCount === 'number' ? data.unreadByMeCount : get(data, `unreadBy${meIndex}Count`, 0)
    this.unreadByOtherUserCount = typeof data.unreadByOtherUserCount === 'number' ? data.unreadByOtherUserCount : get(data, `unreadBy${otherIndex}Count`, 0)
    this.identifier = data.identifier // whitelabelUid
    this.siteName = data.siteName
    this.siteUrl = data.siteUrl
    this.niche = data.niche
    this.lastMessageSentAt = data.lastMessageSentAt
    this.isHidden = storage.get('hidden-conversations', []).find(id => id === data.conversationUid)
    this.hasMoreMessages = true
    this.typingIndicator = 0
    this.messageReceivedAt = 0
    this.notificationReceivedAt = 0
    this.reclaimed = false
    this.isEmbedded = isEmbedded
    this.interfaceNotificationIndex = 0
    this.createdAt = get(data, 'createdAt', null)
    this.highPriority = get(data, 'highPriority', false)
    this.isCreditSite = get(data, 'isCreditSite', false)
    this.remainingMessages = get(data, 'user1Profile.credits.remainingMessages', -1)
    this.isNewAttached = false
    this.userDeleted = false

    this.timer = 0

    //admin
    this.agentMessages = get(data, 'agentMessages', 0)
    this.userMessages = get(data, 'userMessages', 0)
    this.flowMessages = get(data, 'flowMessages', 0)
    this.flowTypes = get(data, 'flowTypes', 0)
    this.conversationType = get(data, 'conversationType')
    this.siteName = get(data, 'siteName')
    this.logs = get(data, 'logs', [])


    this.interactions = observable.object({
      match: false,
      meLiked: false,
      otherUserLiked: false,
      meViewed: false,
      otherUserViewed: false,
      meRequestedPhoto: false,
      otherUserRequestedPhoto: false,
      error: false,
      loading: false,
    })

    this.mePictures = observable.object({
      pictures: [],
      error: false,
      loading: false,
    })
    this.otherUserPictures = observable.object({
      pictures: [],
      error: false,
      loading: false,
    })

    this.meClassifiers = observable.object({
      classifiers: {},
      error: false,
      loading: false,
    })
    this.otherUserClassifiers = observable.object({
      classifiers: {},
      error: false,
      loading: false,
    })

    if (data.messages) {
      const existingIds = this.messages.map(m => m.id)
      data.messages.forEach(message => {
        if(existingIds.indexOf(message.id) === -1) {
          this.messages.push(new Message(message))
        }
      })
    }

    this.setLocations()
  }

  get oldestSentAt() {
    return get(minBy(this.messages, 'sentAt'), 'sentAt')
  }

  get newestSentAt() {
    return get(maxBy(this.messages, 'sentAt'), 'sentAt')
  }

  get lastMessage() {
    return get(maxBy(this.messages, 'id'), 'message')
  }

  get lastMessageOfOtherUser() {
    return get(maxBy(this.messages.filter( m => m.senderUid === this.otherUserUid && m.type === 'message'), 'id'), 'message')
  }

  get agentMessageCount() {
    return this.messages.filter(m => m.senderUid === this.meUid).length
  }

  updateStats(data) {
    const stats = omitBy(data, isNil)
    Object.entries(stats).forEach(([stat, value]) => {
      this[`${stat}`] = value
    })
  }

  async fetchMessages(lastSentAt = null, isDescending = true) {
    const params = {
      lastSentAt,
      userUid: this.meUid,
      otherUid: this.otherUserUid,
      isDescending: isDescending,
    }

    const [response] = await ccpProvider.api.ccp.fetchMessages(omitBy(params, isNil), { identifier: this.identifier })

    this.otherUserActive = response[`user${this.meIndex ^ 1}Active`]

    if(response.messages.length > 0) {
      this.otherUserLastSentAt = response[`user${this.meIndex ^ 1}LastSentAt`]
      this.meLastSentAt = response[`user${this.meIndex}LastSentAt`]
      this.lastMessageSentAt = response.lastMessageSentAt
    }

    //only replace temp notifications if lastSentAt param equals null, messages that haven't been fetched yet don't contain temp notifications
    if(lastSentAt === null) {
      let updatedMessages = (unionWith(this.messages, response.messages.map(x => new Message(x), isEqual)))
      //remove temp system notifications, saved ones have been fetched
      const tempNotificationsToReplace = []
      for(const [index, msg] of this.messages.entries()) {
        //temp notifications have a negative id
        if(msg.id < 0 && msg.type === MESSAGE_TYPE_SYSTEM) {
          //find real message
          const newMessage = response.messages.find(x => x.id > 0 && x.type === MESSAGE_TYPE_SYSTEM && msg.sentAt === x.sentAt && msg.message === x.message)
          if(newMessage) {
            tempNotificationsToReplace.push(index)
          }
        }
      }
      pullAt(updatedMessages, tempNotificationsToReplace)
      this.messages.replace(updatedMessages)
    } else {
      this.messages.push(...response.messages.map(x => new Message(x)))
    }
    // If no new messages, set flag
    if (lastSentAt && response.messages.length === 0) {
      this.hasMoreMessages = false
    }
  }

  async fetchLastMessage(lastSentAt = null) {
    const params = {
      lastSentAt,
      userUid: this.meUid,
      otherUid: this.otherUserUid,
    }

    const [response] = await ccpProvider.api.ccp.fetchMessages(omitBy(params, isNil), { identifier: this.identifier })

    return maxBy(response.messages, 'sentAt')
  }

  async fetchPictures(userUid) {
    const params = { userUid }
    const options = {
      identifier: this.identifier,
      safeMode: Config.get('safeMode'),
      expiry: 60 * 60 * 1000,
    }

    const [response] = await this.requestHandler(() => ccpProvider.api.ccp.getPictures(params, options))
    return Array.isArray(response) ? response : []
  }

  async getClassifiers(userUid) {
    const params = { userUid: userUid, conversationUid: this.conversationUid }
    const options = {
      identifier: this.identifier,
      safeMode: Config.get('safeMode'),
    }

    const [response] = await this.requestHandler(() => ccpProvider.api.chat.getClassifiers(params, options))
    return (response instanceof Object) ? response : {}
  }

  async getCredits() {
    this.requestHandler(() => ccpProvider.api.chat.getCredits({ conversationUid: this.conversationUid }, { identifier: this.identifier }))
      .then(([response, error]) => {
        if(response) {
          runInAction(() => {
            this.otherUserProfile.credits.remainingCredits = response.remainingCredits
            this.otherUserProfile.credits.remainingMessages = response.remainingMessages
            this.otherUserProfile.credits.expiryDate = response.expiryDate
            this.remainingMessages = response.remainingMessages
            this.otherUserProfile.credits.creditsExpired = response.isExpired
            this.otherUserProfile.credits.isRebillQueued = response.isRebillQueued
          })
        }
        if(error?.statusCode === 404) {
          runInAction(() => {
            this.otherUserProfile.credits.remainingCredits = 0
            this.otherUserProfile.credits.expiryDate = null
            this.remainingMessages = 0
            this.otherUserProfile.credits.creditsExpired = true
            this.otherUserProfile.credits.isRebillQueued = false
          })
        }
      })
  }

  setInteractions(
    {
      user0Liked = false,
      user1Liked = false,
      match = false,
      user0Viewed = 0,
      user1Viewed = 0,
      user0RequestedPhoto = false,
      user1RequestedPhoto = false,
      error = false,
      loading = false
    }
  ) {
    this.interactions.meLiked = user0Liked
    this.interactions.otherUserLiked = user1Liked
    this.interactions.matched = match
    this.interactions.meViewed = user0Viewed
    this.interactions.otherUserViewed = user1Viewed
    this.interactions.meRequestedPhoto = user0RequestedPhoto
    this.interactions.otherUserRequestedPhoto = user1RequestedPhoto
    this.interactions.error = error
    this.interactions.loading = loading
  }

  async send(message, specialType = false) {
    const messageType = specialType || MESSAGE_TYPE_DEFAULT

    this.sendingMessage = true
    const params = {
      senderUid: this.meUid,
      receiverUid: this.otherUserUid,
      message,
    }
    params.type = messageType

    /*add directly a message to speed up the conversation
    and remove the last message if a contact gets blocked*/
    const newMessage = this.addMessage({
      message,
      id: 9999999999, //pending message id
      sentAt: parseInt(Date.now()/1000),
      sentBy: this.meIndex,
      type: messageType
    })

    const [response, error] = await ccpProvider.api.ccp.sendMessage(params, { identifier: this.identifier })

    this.sendingMessage = false
    if(error) {
      this.removeMessage(newMessage)
      this.addMessage({...newMessage, id: generateNotSentId()})
      return
    }

    newMessage.id = response.id
    newMessage.sentAt = response.sentAt
    this.lastMessageSentAt = response.sentAt
    this.meLastSentAt = response.sentAt

    if (response.type === MESSAGE_TYPE_CONTACT_INFO) {
      this.removeMessage(newMessage)
      this.addMessage(response)
    }

    if (!this.otherUserActive) {
      this.meSentAfterOffline = true
    }

    this.meMessageCount++
  }

  async sendTypingIndicator(characterCount) {
    const params = {
      senderUid: this.meUid,
      receiverUid: this.otherUserUid,
      conversationUid: this.conversationUid,
      characterCount,
    }
    await ccpProvider.api.ccp.typing(params, { identifier: this.identifier })
  }

  addMessage(data) {
    if (data.id === -1) {
      data.id = --this.interfaceNotificationIndex
    }
    const exists = this.messages.some(m => (m.id === data.id))
    if (!exists || data.type === MESSAGE_TYPE_UPGRADE) {
      const newMessage = new Message(data)
      this.messages.push(newMessage)

      //increase counters when message is not a replay message or the message is a replay event and the user hasn't read the conversation
      const counterIncreaseConditions = [
        store.isWidget,
        ((newMessage.sentAt >= this.meReadAt) || !this.meReadAt),
        data.sentBy !== this.meIndex,
        data.type === MESSAGE_TYPE_DEFAULT || data.type === MESSAGE_TYPE_CONTACT_INFO, //don't increase counter on system messages
      ]
      if(!counterIncreaseConditions.some(condition => !condition)) {
        this.unreadByMeCount++
      }
      return newMessage
    }
  }

  reorder(data) {
    const exists = this.messages.some(m => (m.id === data.id))
    if (!exists || data.type === MESSAGE_TYPE_UPGRADE) {
      this.addMessage(data)
    }
    else {
      this.messages.filter(m => m.id === data.id ).forEach(m => {
        m.sentAt = data.sentAt
      })
      this.messages = uniqBy(orderBy(this.messages, ['sentAt', 'id'], ['asc', 'asc']), 'id')
    }
  }

  removeMessage(data) {
    const messageToRemove = new Message(data)
    this.messages.replace(this.messages.filter(m => !isEqual(messageToRemove, m)))
  }

  hideConversation() {
    const hiddenArr = storage.get('hidden-conversations', [])
    hiddenArr.push(this.conversationUid)
    storage.set('hidden-conversations', uniq(hiddenArr))
    this.isHidden = true
  }

  removeFromQueue() {
    store.conversations.forEach((c, i) => {
      if (c.conversationUid === this.conversationUid) {
        store.conversations.splice(i, 1)
        return
      }
    })
  }

  detachConversation() {
    this.removeFromQueue()
  }

  setLocations() {
    [this.meProfile, this.otherUserProfile].map((profile) => {
      if (!profile.city) {
        profile.location = 'No location data'
        return
      }

      profile.location = profile.city

      if (profile.state) {
        profile.location += ', ' + profile.state
      }

      if (profile.country) {
        profile.location += ', ' + profile.country
      }
    })
  }
  setTimer(timer) {
    this.timer = timer
  }

  requestHandler = async(fn, target) => {
    if(typeof this[target] === 'object' && 'loading' in this[target] && 'error' in this[target]) {
      runInAction(() => {
        this[target].loading = true
        this[target].error = false
      })
      return fn().then(([resp,e]) => {
        runInAction(() => (this[target].loading = false))
        if(e) {
          runInAction(() => (this[target].error = true))
        }
        return [resp, e]
      })
    }
    return fn()
  }

  replaceMessages(newMessages) {
    this.messages = newMessages
  }
}
