import React from 'react'
import Linkify from 'linkify-react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { size } from 'lodash'
import { Icon } from 'semantic-ui-react'

const Bubble = styled.div`
  position: relative;
  display: inline-block;
  margin: 0 0.2em;

  .extraClass {
    pointer-events: auto !important;
    user-select: none;
    a {
      color: #fff;
      text-decoration: underline;
    }
    &:hover {
      visibility: visible !important;
      opacity: 1 !important;
    }
  }
  
  i.circular.icon{
    box-shadow: none;
    color: #0a82fe;
    border: solid 1px #0a82fe;
    background: #ffffff;
    cursor: pointer;
    top: -40px;
  }
`

export const InfoBubble = ({ id, content }) => {
  if(!content || !size(content)) {
    return null
  }

  return (
    <Bubble>
      <Icon name="info" circular fitted size="small" data-tip data-for={`tooltip-${id}`}/>
      <ReactTooltip id={`tooltip-${id}`} place="top" effect="solid" delayHide={300} className='extraClass'>
        <Linkify tagName="p" options={{ target: "_"}}>{content}</Linkify>
      </ReactTooltip>
    </Bubble>
  )
}
