import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { Tab } from 'semantic-ui-react'
import { Pictures } from './Pictures'
import { Classifiers } from './Classifiers'

const UserContentContainer = styled.div`
  display: flex;
  & > div {
    flex: 1;
    padding: 1em;
    margin: 1em;
    min-width: 0;
    min-height: 400px;
  }
`

export const User = observer(({ conversation, userIndex, history, showLocation = false, }) => {

  let profile = {}
  if (history) {
    profile = history
  } else {
    profile = conversation[`${userIndex === 0 ? 'me' : 'otherUser'}Profile`] || {}
  }

  return (
    <Tab.Pane>
      <p><b>Profile Description</b><br/>{profile.profileText}</p>
      {showLocation && <p><b>Location</b><br/>{profile.city}, {profile.state}, {profile.country}</p>}
      <UserContentContainer>
        <Pictures conversation={conversation} userIndex={userIndex} history={history}/>
        <Classifiers conversation={conversation} userIndex={userIndex} history={history}/>
      </UserContentContainer>
    </Tab.Pane>
  )
})
