import React from 'react'
import DateTimePicker from 'react-datetime'
import moment from 'moment'
import styled from 'styled-components'
import { Table, Form, Pagination, Message } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import { merge } from 'lodash'
import { getQueryStringParams, toQueryString } from '../../../core/request'
import { withRouter } from 'react-router'

const DAY = 24*60*60
const WEEK = DAY * 7
const NOW = Math.floor(Date.now() / 1000)

const DATE_FORMAT = 'MMMM Do YYYY, h:mm:ss a'

const ITEMS_PER_PAGE = 50

const PaginationContainer = styled.div`
  margin: 20px 20px;
  display: flex;
  justify-content: center;
`

const FormContainer = styled.div`

`

@boundary()
@connect()
class AgentSessions extends React.Component {

  state = {
    errors: [],
    isLoading: false,
    agents: [],
    activePage: 1,
    sessions: [],
    amountOfSessions: 0,
    total: {},
    average: {},
    form: {
      agentIds: [],
      period: '1d',
      after: NOW - WEEK,
      before: NOW,
    },
  }

  async componentDidMount() {
    const { actions, store, location } = this.props

    const queryParams = getQueryStringParams(location.search)
    const form = merge(this.state.form, {
      agentIds: queryParams.agentIds && queryParams.agentIds.length > 0 ? queryParams.agentIds.split(',') : [],
      period: queryParams.custom,
      after: queryParams.after,
      before: queryParams.before,
    })
    this.setState({ isLoading: true, form, page: queryParams.page || 1, pageSize: queryParams.pageSize || ITEMS_PER_PAGE })

    if(store.agents.length === 0) {
      await actions.agent.listAgents()
    }
    const agents = this.props.store.agents.map(a => ({ key: a.id, text: a.name, value: a.id }))
    this.setState({ agents })
    this.makeRequest()
  }

  makeRequest = async() => {
    const { actions } = this.props
    const { form, activePage, errors } = this.state
    const params = {}

    if(form.period !== 'custom') {
      params.custom = form.period
    } else {
      if(form.after > form.before) {
        errors.push('Invalid "after" date, needs to be before "before"')
      }
      params.before = form.before
      params.after = form.after
    }

    if(form.agentIds.length > 0) {
      params.agentIds = form.agentIds.join(',')
    }

    params.pageSize = ITEMS_PER_PAGE
    params.page = activePage

    if(errors.length > 0) {
      this.setState({ errors })
      return Promise.reject()
    }

    const [agentSessions, error] = await actions.admin.getAgentSessions(params)
    if(error) {
      toast.error('There was an error processing the request.')
      return Promise.reject()
    }
    this.setState({
      sessions: agentSessions.sessions,
      amountOfSessions: agentSessions.amountOfSessions,
      total: agentSessions.total,
      average: agentSessions.average,
      isLoading: false,
    })

    const { location, history} = this.props
    history.push(location.pathname + toQueryString(params))
  }

  handleChange = name => (e, { value }) => {
    const { form } = this.state
    form[name] = value
    this.setState({ form })
  }

  onTimeChange = (name) => (e) => {
    const { form } = this.state
    form[name] = e.unix()
    this.setState({ form })
  }

  validDate = currentDate => {
    return !currentDate.isAfter(moment())
  }

  onPageChange = (e, { activePage }) => {
    this.setState({ activePage, errors: [] }, () => this.makeRequest())
  }

  onSubmit = async() => {
    this.setState({ activePage: 1, errors: [] }, () => this.makeRequest())
  }

  render() {
    const { sessions, total, average, amountOfSessions, agents, form, errors } = this.state
    return (
      <div>
        <h1>Agent Sessions</h1>
        { errors.length > 0 && <Message
          error
          header='Action Forbidden'
          list={errors}
        />}
        <FormContainer>
          <Form onSubmit={this.onSubmit} className='attached' error={errors.length > 0}>
            <Form.Field width={3}>
              <label>Agents</label>
              <Form.Select placeholder='Agents' multiple selection options={agents} onChange={this.handleChange('agentIds')}/>
            </Form.Field>
            <Form.Group inline>
              <label>Period</label>
              <Form.Field>
                <Form.Radio
                  label='1 day'
                  value='1d'
                  checked={form.period === '1d'}
                  onChange={this.handleChange('period')}
                />
              </Form.Field>
              <Form.Field>
                <Form.Radio
                  label='7 days'
                  value='7d'
                  checked={form.period === '7d'}
                  onChange={this.handleChange('period')}
                />
              </Form.Field>
              <Form.Field>
                <Form.Radio
                  label='2 weeks'
                  value='14d'
                  checked={form.period === '14d'}
                  onChange={this.handleChange('period')}
                />
              </Form.Field>
              <Form.Field>
                <Form.Radio
                  label='custom'
                  value='custom'
                  checked={form.period === 'custom'}
                  onChange={this.handleChange('period')}
                />
              </Form.Field>
            </Form.Group>

            { form.period === 'custom' && <Form.Group>
              <Form.Field>
                <label>After</label>
                <DateTimePicker value={form.after * 1000} onChange={this.onTimeChange('after')} isValidDate={this.validDate}/>
              </Form.Field>
              <Form.Field>
                <label>Before</label>
                <DateTimePicker value={form.before * 1000} onChange={this.onTimeChange('before')} isValidDate={this.validDate}/>
              </Form.Field>
            </Form.Group>}

            <Form.Button>Search</Form.Button>
          </Form>
        </FormContainer>

        <Table definition celled attached='top'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell/>
              <Table.HeaderCell>Sessions</Table.HeaderCell>
              <Table.HeaderCell>Conversations requested</Table.HeaderCell>
              <Table.HeaderCell>Extra conversations received</Table.HeaderCell>
              <Table.HeaderCell>Extra conversations interacted</Table.HeaderCell>
              <Table.HeaderCell>Offline moves</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Total</Table.Cell>
              <Table.Cell>{ amountOfSessions || 0}</Table.Cell>
              <Table.Cell>{ total.conversationsRequested || 0}</Table.Cell>
              <Table.Cell>{ total.extraConversationsReceived || 0}</Table.Cell>
              <Table.Cell>{ total.extraConversationsInteracted || 0}</Table.Cell>
              <Table.Cell>{ total.offlineMoves || 0}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Average</Table.Cell>
              <Table.Cell/>
              <Table.Cell>{ average.conversationsRequested || 0}</Table.Cell>
              <Table.Cell>{ average.extraConversationsReceived || 0}</Table.Cell>
              <Table.Cell>{ average.extraConversationsInteracted || 0}</Table.Cell>
              <Table.Cell>{ average.offlineMoves || 0}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Table attached celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Agent</Table.HeaderCell>
              <Table.HeaderCell>Session started</Table.HeaderCell>
              <Table.HeaderCell>Session ended</Table.HeaderCell>
              <Table.HeaderCell>Conversations requested</Table.HeaderCell>
              <Table.HeaderCell>Extra conversations received</Table.HeaderCell>
              <Table.HeaderCell>Extra conversations interacted</Table.HeaderCell>
              <Table.HeaderCell>Offline Moves</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              sessions.map((session, key) => (
                <Table.Row key={key}>
                  <Table.Cell>{ session.username }</Table.Cell>
                  <Table.Cell>{ session.startedAt ? moment(session.startedAt * 1000).format(DATE_FORMAT) : '...' }</Table.Cell>
                  <Table.Cell>{ session.endedAt ? moment(session.endedAt * 1000).format(DATE_FORMAT) : '...' }</Table.Cell>
                  <Table.Cell>{ session.conversationsRequested }</Table.Cell>
                  <Table.Cell>{ session.extraConversationsReceived }</Table.Cell>
                  <Table.Cell>{ session.extraConversationsInteracted }</Table.Cell>
                  <Table.Cell>{ session.offlineMoves }</Table.Cell>
                </Table.Row>
              ))
            }
          </Table.Body>
        </Table>
        <PaginationContainer>
          <Pagination defaultActivePage={1} totalPages={Math.ceil(amountOfSessions / ITEMS_PER_PAGE)} onPageChange={this.onPageChange}/>
        </PaginationContainer>
      </div>
    )
  }

}

export default withRouter(AgentSessions)
