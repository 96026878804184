import React from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { observer } from 'mobx-react'

const backBtn = {
  height: '60px',
  width: '250px',
  fontSize: 'x-large',
  display: 'block',
  margin: '0 auto',
}


class Break extends React.Component {

  state = {
    loading: false
  }

  leaveHandler = async() => {
    const { actions, store } = this.props

    store.account.online = false
    store.account.leave = false
    store.account.break = true

    if(store.agent && store.agent.extraConversationsActive) {
      actions.agent.cancelMoreConversations()
    }

    await actions.agent.leave()
  }

  backHandler = async() => {
    const { actions, store } = this.props
    this.setState({ loading: true })
    await actions.agent.back()
    this.setState({ loading: false })
    store.account.online = true
    store.account.autoBreak = false
    store.account.break = false
  }

  renderLeaveConfirm = () => {
    const { store } = this.props

    return(
      <Modal size="small" open={store.account.leave} onClose={this.close}>
        <Modal.Content>
          <p>Are you sure you want to take a break?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => { store.account.leave = false }}>
            No
          </Button>
          <Button positive icon='sign out' labelPosition='right' content='Yes' onClick={this.leaveHandler}/>
        </Modal.Actions>
      </Modal>
    )
  }

  renderBack = () => {
    const { store } = this.props
    return(
      <Modal size="small" basic open={store.account.break || store.account.autoBreak} onClose={this.close}>
        <Modal.Content>
          { store.account.autoBreak && <p style={{margin: '15px auto', textAlign: 'center'}}>We have detected that you are away, we changed your status to offline</p>}
          <Button loading={this.state.loading || store.conversations.length > 0} disabled={this.state.loading || store.conversations.length > 0} style={backBtn} positive icon='sign in' labelPosition='right' content="I'm back" onClick={this.backHandler}/>
        </Modal.Content>
      </Modal>
    )
  }

  renderContent = () => {
    const { store } = this.props
    if(store.account.autoBreak || !store.account.break) {
      return this.renderBack()
    }else if(store.account.break && !store.account.online) {
      return this.renderBreak()
    }else {
      return null
    }
  }

  render() {
    const { store } = this.props
    return (
      <div>
        { store.account.leave && this.renderLeaveConfirm() || store.account.autoBreak && this.renderBack() || store.account.break && this.renderBack() }
      </div>
    )
  }
}

export default connect()(observer(Break))
