import React from 'react'

const Section = ({title, children}) => (
  <section className='content-section'>
    <h1 className='content-section-title'>{title}</h1>
    <div className='content-section-body'>
      {children}
    </div>
  </section>
)

export default Section
