import { action, makeObservable } from 'mobx'
import storage from 'core/storage'
import store from '../store'

export default class UI {
  constructor() {
    makeObservable(this, {
      toggleSidebar: action
    })
  }

  toggleSidebar() {
    store.isShrinked = !store.isShrinked
    storage.set('UI.isShrinked', store.isShrinked ? 1 : 0)
  }
}
