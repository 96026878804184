import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ccpProvider } from 'core/CcpProvider'
import Button from './Button'
import { Icon, Input, Loader } from 'semantic-ui-react'
import { toast } from 'react-toastify'

const isInt = str => !isNaN(str) && Number.isInteger(parseFloat(str))

const AddMapping = ({ agentId, campaignAdded = () => {} }) => {
  const [campaign, setCampaign] = useState('')
  const [loading, setLoading] = useState(false)

  const add = e => {
    e.preventDefault()

    if(campaign === '') {
      return
    }

    setLoading(true)
    ccpProvider.api.admin.assignCampaign(campaign, agentId)
      .then(([, error]) => {
        setLoading(false)
        if(error) {
          if(error.code === 'duplicateCampaignMapping') {
            toast.error('This campaign is already assigned to this agent')
            return
          }
          toast.error('There was an issue assigning this campaign to the agent, please try again.')
          return
        }
        setCampaign('')
        campaignAdded(campaign)
      })
  }

  const validate = ({ target: { value } }) => {
    if(value === '0') {
       return
    }
    if(value === '') {
      setCampaign(value)
    }
    if(isInt(value)) {
      setCampaign(parseInt(value))
    }
  }

  return (
    <div>
      <Input style={{width: '100%'}} action onChange={validate} value={campaign}>
        <input placeholder="Enter campaign id"/>
        <Button color="blue" loading={loading} onClick={add} disabled={campaign === ''}>Add</Button>
      </Input>
    </div>
  )
}

const RemoveMapping = ({ campaignId, agentId, campaignRemoved = () => {} }) => {
  const [loading, setLoading] = useState(false)

  const remove = e => {
    e.preventDefault()
    setLoading(true)
    ccpProvider.api.admin.removeCampaign(campaignId, agentId)
      .then(([, error]) => {
        setLoading(false)
        if(error) {
          toast.error('There was an issue deleting the assigned campaign id, please try again.')
        }
        campaignRemoved(campaignId)
      })
  }

  return (
    <Button icon onClick={remove} loading={loading}><Icon className="red" name="remove"/></Button>
  )
}

const CampaignMappingContainer = styled.div`
  position: relative;
  margin: 2em 0;
`
const CampaignList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 1em 0;
  max-width: 15em;
  li {
    border: solid lightgrey;
    border-width: 1px 1px 0 1px;
    padding: 0.4em 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &:first-child {
      border-radius: 5px 5px 0 0;
    }
    &:last-child {
      border-radius: 0 0 5px 5px;
      border-width: 1px 1px 1px 1px;
    }
    &:first-child:last-child {
      border-radius: 5px;
    }
  }
`
const Title = () => <h3>Assigned campaigns</h3>
const CampaignMapping = ({ agentId }) => {
  const [loading, setLoading] = useState(false)
  const [mappings, setMappings] = useState([])

  useEffect(() => {
    setLoading(true)
    ccpProvider.api.admin.getAssignedCampaigns(agentId)
      .then(([result]) => {
        setLoading(false)
        setMappings(result)
      })
  }, [])

  const campaignAdded = (campaignId) => {
    toast.success(`Campaign id ${campaignId} added`)
    setMappings([...mappings, campaignId])
  }

  const campaignRemoved = (campaignId) => {
    toast.success(`Campaign id ${campaignId} removed`)
    setMappings(mappings.filter(m => m !== campaignId))
  }

  if(loading) {
    return (
      <CampaignMappingContainer>
        <Title/>
        <Loader active={true}/>
      </CampaignMappingContainer>
    )
  }

  return (
    <CampaignMappingContainer>
      <Title/>
      <CampaignList>
        {!loading && mappings.length === 0 && <p>No campaigns are assigned to this agent</p>}
        {mappings.map((campaign, index) => <li key={index}>{campaign}<RemoveMapping campaignId={campaign} campaignRemoved={campaignRemoved} agentId={agentId}/></li>) }
      </CampaignList>
      <AddMapping campaignAdded={campaignAdded} agentId={agentId}/>
    </CampaignMappingContainer>
  )
}

export default CampaignMapping
