import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'semantic-ui-react'

class FormRadioGroup extends React.Component {

  render() {
    const { data, label, defaultValue, onChange, required } = this.props
    const id = label.hashCode()
    return (
      <Form.Field>
        <label>{label}</label>
        {data.map((el, i) => {
          const radId = id + "-rad-el-" + i
          return (
            <span style={{display: 'inline-block'}} key={radId}>
              <input
                value={el.inputProps.value}
                id={radId}
                name={el.inputProps.name}
                label={el.label}
                required={required}
                type="radio"
                onChange={(e) => onChange({
                  name: e.target.name,
                  value: e.target.value
                })}
                defaultChecked={defaultValue.toLowerCase() === el.inputProps.value.toLowerCase()}/>
              <label style={{display: 'inline-block', margin: '0 20px 0 5px'}} htmlFor={radId}>{el.label} </label>
            </span>
          )
        })}
      </Form.Field>
    )
  }
}

FormRadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  data: PropTypes.array.isRequired
}

FormRadioGroup.defaultProps = {
  placeholder: '',
  defaultValue: undefined,
  required: false,
}

export default FormRadioGroup
