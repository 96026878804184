import request from 'core/request'
import React, {useState} from "react"
import {CCP_GET_AGENTS_SETTINGS, CCP_GET_STANDARD_SETTINGS} from "../endpoints"

export const useSettings = (store, disabledButton, setDisabledButton) => {
  const [settings, setSettings] = useState(null)
  const objectives = store.account.objectives

  React.useEffect(() => {
    if (settings?.result.minimumRequiredMessages < store.conversation.meMessageCount) {
      setDisabledButton(false)
    }
  }, [store.conversation.meMessageCount, settings])

  React.useEffect(() => {
    if (store.conversation.lastMessageSentAt) {
      let isStandard = objectives.some(item => item.name === "Upgrade to Premium")
      let endpoint = CCP_GET_AGENTS_SETTINGS

      if (isStandard) {
        endpoint = CCP_GET_STANDARD_SETTINGS
      }

      if (!settings) {
        async function fetchData() {
          const result = await request.get(endpoint)
          return result
        }

        fetchData().then((data) => {
          setSettings(data)
        })
      }
    }
  }, [store.conversation.lastMessageSentAt])

  return disabledButton
}
