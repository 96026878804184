import React from "react"
import PropTypes from "prop-types"
import { observer } from "mobx-react"
import { debounce, get, orderBy, uniqBy } from "lodash"
import {
  MESSAGE_TYPE_SYSTEM,
  MESSAGE_TYPE_CONTACT_INFO,
  MESSAGE_TYPE_PRESENCE,
  MESSAGE_TYPE_IMAGE,
} from "core/constants"
import MessageWarning from "./messages/MessageWarning"
import MessageDefault from "./messages/MessageDefault"
import MessageImage from "./messages/MessageImage"
import SystemNotification from "./messages/SystemNotification"
import { Loader } from "semantic-ui-react"
import styled from "styled-components"
import Search from "./Icons/Search"
import ChevronDown from "./Icons/ChevronDown"
import ChevronUp from "./Icons/ChevronUp"
import request from "core/request"
import { MESSAGE_SEARCH, MESSAGE_SEARCH_FRAMES } from "../../../core/endpoints"

const NoMessages = styled.p`
  text-align: center;
  color: grey;
`

const ChatConversation = styled.div`
  flex: 1;
  padding: 1rem 1rem 0 1rem;
  position: relative;
  z-index: 0;
  overflow-y: scroll;
`

class ChatMessageList extends React.Component {
  // Reference to the DOM
  node = null
  oldestSentAt = null
  newestSentAt = null
  loading = false
  state = {
    searchMessage: "",
    searchResults: [],
    currentSearchIndex: -1,
    highlightedMessage: {},
    canLoadMessages: true,
  }

  constructor(props) {
    super(props)
    this.fetchSearchResults = debounce(async(value) => {
      if (value.length < 3) {
        return
      }

      const { conversation } = this.props
      const searchResults = await request.get(MESSAGE_SEARCH, {
        search: value,
        conversationUid: conversation.conversationUid,
      })
      if (searchResults && searchResults.result.messages) {
        this.setState({ searchResults: searchResults.result.messages })
      }
      if (searchResults.error) {
        this.setState({ searchResults: [] })
      }
    }, 1000)

    this.goToNextResult = async() => {
      const nextIndex =
        (this.state.currentSearchIndex + 1) % this.state.searchResults.length
      const { conversation, setStopAutoScroll } = this.props
      setStopAutoScroll(true)
      this.setState({ currentSearchIndex: nextIndex })
      const messageToSearch = this.state.searchResults[nextIndex]
      const date = new Date(messageToSearch.sentAt.date + "Z")
      const timestamp = date.getTime()
      const timestampInSeconds = Math.round(timestamp / 1000)

      this.setState({ highlightedMessage: messageToSearch })

      const result = await request.get(MESSAGE_SEARCH_FRAMES, {
        conversationId: messageToSearch.conversationId,
        sentAt: timestampInSeconds,
      })

      conversation.replaceMessages(result.result.messages)

      this.scrollToMessage(timestampInSeconds.toString())
    }

    this.goToPreviousResult = async() => {
      if (this.state.currentSearchIndex == -1) {
        return
      }
      const prevIndex =
        (this.state.currentSearchIndex - 1 + this.state.searchResults.length) %
        this.state.searchResults.length
      const { conversation, setStopAutoScroll } = this.props
      setStopAutoScroll(true)
      this.setState({ currentSearchIndex: prevIndex })
      const messageToSearch = this.state.searchResults[prevIndex]
      const date = new Date(messageToSearch.sentAt.date + "Z")
      const timestamp = date.getTime()
      const timestampInSeconds = Math.round(timestamp / 1000)

      this.setState({ highlightedMessage: messageToSearch })

      const result = await request.get(MESSAGE_SEARCH_FRAMES, {
        conversationId: messageToSearch.conversationId,
        sentAt: timestampInSeconds,
      })

      conversation.replaceMessages(result.result.messages)

      this.scrollToMessage(timestampInSeconds.toString())
    }
  }

  scrollToMessage = (timestamp) => {
    const existingHighlights = document.querySelectorAll(".highlight-message")
    existingHighlights.forEach((el) => {
      el.classList.remove("highlight-message")
    })

    const messageElement = document.querySelector(
      `.message-wrapper[data-id="${timestamp}"]`
    )
    if (messageElement) {
      this.setState({ canLoadMessages: false })
      setTimeout(() => {
        this.setState({ canLoadMessages: true })
      }, 1000)
      setTimeout(() => {
        messageElement.scrollIntoView({ behavior: "smooth", block: "nearest" })

        const messageContent = messageElement.querySelector(".chat-message")
        if (messageContent) {
          messageContent.classList.add("highlight-message")
          setTimeout(() => {
            messageContent.classList.remove("highlight-message")
          }, 2500)
        }
      }, 500)
    }
  }

  handleSearchMessage(event) {
    this.fetchSearchResults(event.target.value)
    this.setState({ searchMessage: event.target.value })
  }

  get lastMessageDOMElement() {
    return document.querySelector(
      `.message-wrapper[data-id="${this.oldestSentAt}"]`
    )
  }

  componentDidMount() {
    const { conversation } = this.props
    this.autoScroll(conversation)
    setTimeout(() => {
      this.scrollToBottom()
    }, 1000)
  }

  componentDidUpdate(prevProps, prevState) {
    const { stopAutoScroll } = this.props
    if (
      prevProps.messages.length !== this.props.messages.length &&
      !stopAutoScroll
    ) {
      this.scrollToBottom()
    }

    if (this.state.searchMessage === "" && prevState.searchMessage !== "") {
      this.setState({ currentSearchIndex: -1 })
      this.setState({ searchResults: [] })
    }
  }

  autoScroll = (conversation) => {
    if (conversation && conversation.messages && conversation.messages.length) {
      // This check should be inside the "if"
      if (!this.loading) {
        this.scrollToBottom()
      }
    }
  }

  componentWillReceiveProps({ conversation }) {
    const { conversationUid } = this.props.conversation
    if (conversationUid !== conversation.conversationUid) {
      this.scrollToBottom()
    }
  }

  scrollToBottom = (timeout = 50) => {
    setTimeout(() => {
      if (this.node && typeof this.node.scrollHeight !== "undefined") {
        this.node.scrollTop = this.node.scrollHeight
      }
    }, timeout)
  }

  scrollToLastVisibleMessage = () => {
    if (this.props.conversation.oldestSentAt < this.oldestSentAt) {
      if (this.lastMessageDOMElement) {
        this.node.scrollTop = this.lastMessageDOMElement.offsetTop
      }
    }
  }

  handleScroll = (e) => {
    if (!this.state.canLoadMessages) {
      return
    }
    this.setState({ scrolling: true })
    const percentScroll = Math.round(
      (e.target.scrollTop / e.target.scrollHeight) * 100
    )
    if (percentScroll < 10) {
      this.loadMoreMessages()
    }

    const { scrollTop, scrollHeight, clientHeight } = e.target
    const bottom = scrollHeight - scrollTop - clientHeight

    if (bottom < scrollHeight * 0.05) {
      this.loadMoreMessagesBottom()
    }
  }

  loadMoreMessagesBottom = debounce(() => {
    const { conversation } = this.props

    this.oldestSentAt = conversation.oldestSentAt
    this.newestSentAt = conversation.newestSentAt

    this.loading = true
    conversation.fetchMessages(this.newestSentAt, false).then(() => {
      this.loading = false
      this.forceUpdate()
    })
  }, 1000)

  loadMoreMessages = debounce(() => {
    const { conversation } = this.props

    this.oldestSentAt = conversation.oldestSentAt
    this.newestSentAt = conversation.newestSentAt
    this.loading = true
    conversation.fetchMessages(this.oldestSentAt, true).then(() => {
      this.loading = false
      this.scrollToLastVisibleMessage()
      this.forceUpdate()
    })
  }, 500)

  render() {
    const { conversation } = this.props
    const users = {
      me: conversation.meProfile,
      other: conversation.otherUserProfile,
    }
    const messages = uniqBy(
      orderBy(get(conversation, "messages"), ["sentAt", "id"], ["asc", "asc"]),
      "id"
    )

    return (
      <ChatConversation
        ref={(c) => (this.node = c)}
        onScroll={this.handleScroll}
        className="minimal-scrollbar"
      >
        <div className="conversation-search">
          <div className="conversation-search-icon">
            <Search/>
          </div>
          <input
            className="conversation-search-input"
            placeholder="Search the conversation..."
            value={this.state.searchMessage}
            onChange={(event) => this.handleSearchMessage(event)}
          />
          {this.state.searchResults.length > 0 && (
            <div className="search-result-wrapper">
              <div className="search-result-numbers">
                {this.state.currentSearchIndex + 1}/
                {this.state.searchResults.length}
              </div>
              <div className="search-result-arrows">
                <span onClick={this.goToPreviousResult}>
                  <ChevronUp isActive={this.state.currentSearchIndex !== -1}/>
                </span>
                <span onClick={this.goToNextResult}>
                  <ChevronDown isActive={true}/>
                </span>
              </div>
            </div>
          )}
        </div>

        <Loader active={this.loading}>Loading messages</Loader>
        {messages.length === 0 && (
          <NoMessages>
            There are no messages yet in this conversation
          </NoMessages>
        )}
        {messages.map((message, i) => {
          switch (message.type) {
            case MESSAGE_TYPE_PRESENCE:
            case MESSAGE_TYPE_SYSTEM: {
              return <SystemNotification key={i} message={message}/>
            }
            case MESSAGE_TYPE_CONTACT_INFO: {
              return (
                <MessageWarning
                  key={i}
                  message={message}
                  belongsToOtherUser={_belongsToOtherUser(
                    conversation,
                    message
                  )}
                />
              )
            }
            case MESSAGE_TYPE_IMAGE: {
              let props = {
                key: message.id,
                message,
                isCorrespondent: 1,
                conversation: conversation,
              }
              return (
                <MessageImage
                  {...props}
                  users={users}
                  belongsToOtherUser={_belongsToOtherUser(
                    conversation,
                    message
                  )}
                />
              )
            }
            default:
              return (
                <MessageDefault
                  key={i}
                  message={message}
                  belongsToOtherUser={_belongsToOtherUser(
                    conversation,
                    message
                  )}
                  otherUserName={conversation.otherUserProfile.username}
                />
              )
          }
        })}
      </ChatConversation>
    )
  }
}

function _belongsToOtherUser(conversation, message) {
  if (message.senderUid) {
    // in case of default message
    return message.senderUid === conversation.otherUserUid
  } else if (message.receiver) {
    //temporary fix
    return message.receiver === conversation.meIndex
  } else if (message.receiverUid) {
    // in case of contactInfoDetected
    return message.receiverUid === conversation.otherUserUid
  } else if (message.sentBy === 0 || message.sentBy === 1) {
    //fallback
    return message.sentBy !== conversation.meIndex
  }
  return false
}

ChatMessageList.defaultProps = {
  conversation: {},
}

ChatMessageList.propTypes = {
  conversation: PropTypes.object,
}

export default observer(ChatMessageList)
